// Insights/actions.js
import * as t from './actionTypes';

export const searchAdminsReceivedAction = (response) => ({
  type: t.SEARCHADMINSRECEIVED,
  payload: response,
});

export const rolesReceivedAction = (response) => ({
  type: t.ROLESRECEIVED,
  payload: response,
});

export const changeMainRoleReceivedAction = (response) => ({
  type: t.CHANGEMAINROLERECEIVED,
  payload: response,
});

export const updateProfileAction = (profile) => ({
  type: t.UPDATEPROFILEACTION,
  payload: profile,
});

export const setMessage = (message) => ({
  type: t.SET_MESSAGE,
  message,
});

export const requestPasswordAction = (email) => ({
  type: t.REQUEST_PASSWORD,
  email,
});

export const errorFetchingFromService = (error) => ({
  type: t.ERROR_FETCHING_FROM_SERVICE,
  payload: error,
});

export const removeAdmin = (admin) => ({
  type: t.REMOVE_ADMIN,
  payload: admin,
});

export const updateAdmin = (admin) => ({
  type: t.UPDATE_ADMIN,
  payload: admin,
});

export const errorSubmittingFromService = (error) => ({
  type: t.ERROR_SUBMITTING_FROM_SERVICE,
  error,
});

export const clearErrorFromService = (error) => ({
  type: t.CLEAR_ERROR_FROM_SERVICE,
});

export const updateIsWaiting = (isWaiting) => ({
  type: t.UPDATE_IS_WAITING,
  isWaiting,
});

export const toggleSelectedAdminRole = (admin) => ({
  type: t.TOGGLE_ADMIN_ROLE,
  admin,
});

export const setSelectedAdmin = (admin) => ({
  type: t.SET_SELECTED_ADMIN,
  admin,
});

export const setAdminRoles = (roles) => ({
  type: t.SET_SELECTED_ADMIN_ROLES,
  roles,
});

export const setAdminHistory = (history, pagination) => ({
  type: t.SET_ADMIN_HISTORY,
  history,
  pagination,
});

export const addNewAdmin = (admin) => ({
  type: t.ADD_NEW_ADMIN,
  admin,
});

export const updateAdminLogs = (logs, pagination) => ({
  type: t.UPDATE_ADMIN_LOGS,
  logs,
  pagination,
});

export const setLoadingInfo = (loadingInfo) => ({
  type: t.SET_LOADING_INFO,
  loadingInfo,
});

export const setActivity = (activity) => ({
  type: t.SET_ACTIVITY,
  activity,
});
