import React from 'react';
import { Col, Row } from 'react-bootstrap';

export default class Pagination extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: 0,
    };
  }

  selectQuestionsPerPage = (amount) => {
    this.props.questionsPerPage(amount);
    this.setState({ selected: 0 });
    const isAllQuestions = amount === 'ALL';

    this.props.amountOfQuestionsInChapter(this.props.chapter);
    this.props.onClearQuestions();
    this.props.loadQuestionsOfChapter(
      this.props.chapter,
      0,
      isAllQuestions ? 0 : amount,
      this.props.assignment,
      false,
      0,
      this.props.isViewDeletedQuestions,
      isAllQuestions,
    );
  };

  render() {
    let pages = 1;
    const pagesArray = [];
    pages = Math.ceil(
      this.props.amountOfQuestions / this.props.questionsPerPages,
    );
    if (pages > 1) {
      for (let i = 0; i < pages; i++) {
        pagesArray.push([
          `${this.props.questionsPerPages * i + 1}-${
            this.props.questionsPerPages * (i + 1)
          }`,
          this.props.questionsPerPages * i,
          this.props.questionsPerPages * (i + 1),
          i,
        ]);
      }
    }
    return (
      <div>
        <Row className="pt-2 ml-2">
          <Col>Questions per page</Col>
          {pagesArray.length > 0 && <Col>Page</Col>}
        </Row>
        <Row className="pb-3 mt-1 pl-3 line-pagination">
          <Col className="pagination-options">
            <div
              onClick={() => this.selectQuestionsPerPage('ALL')}
              className={
                this.props.questionsPerPages === 'ALL'
                  ? 'button-pagination ml-1 bg-info'
                  : 'button-pagination ml-1'
              }
            >
              All
            </div>
            <div
              onClick={() => this.selectQuestionsPerPage(25)}
              className={
                this.props.questionsPerPages === 25
                  ? 'button-pagination ml-1 bg-info'
                  : 'button-pagination ml-1'
              }
            >
              25
            </div>
            <div
              onClick={() => this.selectQuestionsPerPage(50)}
              className={
                this.props.questionsPerPages === 50
                  ? 'button-pagination ml-1 bg-info'
                  : 'button-pagination ml-1'
              }
            >
              50
            </div>
            <div
              onClick={() => this.selectQuestionsPerPage(100)}
              className={
                this.props.questionsPerPages === 100
                  ? 'button-pagination ml-1 bg-info'
                  : 'button-pagination ml-1'
              }
            >
              100
            </div>
          </Col>
          <Col className="pagination-page-options">
            {pagesArray.map((pages, index) => (
              <div
                className={
                  index == this.state.selected
                    ? 'button-pagination-width-auto ml-1 bg-info'
                    : 'button-pagination-width-auto ml-1'
                }
                onClick={() => {
                  this.props.onClearQuestions();
                  this.props.loadQuestionsOfChapter(
                    this.props.chapter,
                    0,
                    this.props.questionsPerPages,
                    this.props.assignment,
                    false,
                    pages[1],
                    this.props.isViewDeletedQuestions,
                    false,
                  );
                  this.setState({ selected: index });
                }}
              >
                {pages[0]}
              </div>
            ))}
          </Col>
        </Row>
      </div>
    );
  }
}
