import './HistorySubscriptionsStyle.css';

import { Button, Table } from 'react-bootstrap';

function HistorySubscriptions({ onCancel, userSubscriptionHistory }) {
  return (
    <div className="historyContainer">
      {/* iOS Subscription History Table */}
      {userSubscriptionHistory?.subscriptionHistoryIos?.length > 0 && (
        <div className="mb-2">
          <div>
            <h4>iOS Subscription History</h4>
            <div
              className={`${userSubscriptionHistory.subscriptionIosStatus === 'active' ? 'containerStatusActive' : 'containerStatusInactive'}`}
            >
              {userSubscriptionHistory.subscriptionIosStatus}
            </div>
          </div>
          <div
            className={`tableContainer ${
              userSubscriptionHistory.subscriptionHistoryIos.length > 2
                ? 'long'
                : 'short'
            }`}
          >
            <Table striped bordered hover>
              <thead className="headTable">
                <tr>
                  <th>Transaction ID</th>
                  <th>Original Transaction ID</th>
                  <th>Purchase Date</th>
                  <th>Reason</th>
                </tr>
              </thead>
              <tbody>
                {userSubscriptionHistory.subscriptionHistoryIos.map(
                  (item, index) => (
                    <tr key={index}>
                      <td>{item.transactionId}</td>
                      <td>{item.originalTransactionId}</td>
                      <td>{item.purchaseDate}</td>
                      <td>{item.transactionReason}</td>
                    </tr>
                  ),
                )}
              </tbody>
            </Table>
          </div>
        </div>
      )}

      {/* iOStoStripePending Table */}
      {userSubscriptionHistory?.iOStoStripePending &&
        Object.keys(userSubscriptionHistory.iOStoStripePending).length > 0 && (
          <div className="mb-2">
            <h4>Pending Stripe Transactions</h4>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Discount Applied</th>
                  <th>Discount Name</th>
                  <th>Discount Value</th>
                  <th>Total Price</th>
                  <th>Start Date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {userSubscriptionHistory.iOStoStripePending.discount
                      ? 'Yes'
                      : 'No'}
                  </td>
                  <td>
                    {userSubscriptionHistory.iOStoStripePending.discount?.name}
                  </td>
                  <td>
                    {userSubscriptionHistory.iOStoStripePending.discount
                      ?.percent_off
                      ? `${userSubscriptionHistory.iOStoStripePending.discount.percent_off}% off`
                      : userSubscriptionHistory.iOStoStripePending.discount
                            ?.amount_off
                        ? `$${userSubscriptionHistory.iOStoStripePending.discount.amount_off} off`
                        : 'N/A'}
                  </td>
                  <td>
                    {userSubscriptionHistory.iOStoStripePending.discount
                      ?.totalPrice || 'N/A'}
                  </td>
                  <td>
                    {userSubscriptionHistory.iOStoStripePending.start_date ||
                      'N/A'}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        )}

      {/* Subscriptions History Table */}
      {userSubscriptionHistory.subscriptionStripe?.length > 0 && (
        <div className="mb-2">
          <div>
            <h4>Stripe Subscription History</h4>
            <div
              className={`${userSubscriptionHistory.subscriptionStripeStatus === 'active' ? 'containerStatusActive' : 'containerStatusInactive'}`}
            >
              {userSubscriptionHistory.subscriptionStripeStatus}
            </div>
          </div>
          <div
            className={`tableContainer ${
              userSubscriptionHistory.subscriptionStripe.length > 2
                ? 'long'
                : 'short'
            }`}
          >
            <Table striped bordered hover>
              <thead className="headTable">
                <tr>
                  <th>Type</th>
                  <th>Date</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody>
                {userSubscriptionHistory.subscriptionStripe.map(
                  (item, index) => (
                    <tr key={index}>
                      <td>{item.type}</td>
                      <td>{item.date}</td>
                      <td>{item.details}</td>
                    </tr>
                  ),
                )}
              </tbody>
            </Table>
          </div>
        </div>
      )}

      <div className="bottom-buttons">
        <Button className="btn btn-secondary" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
}

export default HistorySubscriptions;
