import './TopicsAndExamSubtopicsReport.css';

import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';

import { SimpleInput } from '../../../../commonComponents/Input/Input';
import Spinner from '../../../../commonComponents/Spinner/Spinner';

export default class TopicsAndExamSubtopicsReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRates: [],
      multiple: false,
    };
  }

  componentDidMount() {
    if (this.props.type === 'rate') {
      this.props.loadRates();
      this.props.getLatest(false);
    } else if (this.props.type === 'exam') {
      this.props.loadExams();
      this.props.getLatest(true);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.type === 'rate' && prevProps.rates !== this.props.rates) {
      if (this.props.rates && this.props.rates.length) {
        this.setState({
          selectedRates: this.props.rates.slice(1),
        });
      }
    } else if (
      this.props.type === 'exam' &&
      prevProps.exams !== this.props.exams
    ) {
      if (this.props.exams && this.props.exams.length) {
        this.setState({
          selectedRates: this.props.exams,
        });
      }
    }
  }

  areAllRatesSelected = () => {
    let areAllRatesSelected = true;
    let areAllRatesDeselected = true;
    this.state.selectedRates.forEach((currentRate) => {
      if (currentRate.isSelected) {
        areAllRatesDeselected = false;
      } else {
        areAllRatesSelected = false;
      }
    });
    return {
      areAllRatesSelected,
      areAllRatesDeselected,
    };
  };

  onChangeRateSelection = (index, newValue) => {
    const { selectedRates } = this.state;
    selectedRates[index].isSelected = newValue;
    this.setState({ selectedRates });
  };

  changeAllValues = (newValue) => {
    const { selectedRates } = this.state;
    this.setState({
      selectedRates: selectedRates.map((rate) => {
        rate.isSelected = newValue;
        return rate;
      }),
    });
  };

  renderSelectAllOrNothing = (areAllRatesSelected, areAllRatesDeselected) => (
    <Row>
      <Col md={12} id="all-or-none">
        <SimpleInput
          type="checkbox"
          description={areAllRatesDeselected ? 'Select All' : 'Deselect All'}
          value={!areAllRatesDeselected}
          onChange={() => {
            this.changeAllValues(!!areAllRatesDeselected);
          }}
        />
      </Col>
    </Row>
  );

  renderRateAndCheckbox = (rate, index) => {
    if (rate.id) {
      return (
        <Row key={rate.id}>
          <Col md={12}>
            <SimpleInput
              description={`(${rate.shortname}) ${rate.name}`}
              type="checkbox"
              value={rate.isSelected}
              onChange={() => {
                this.onChangeRateSelection(index, !rate.isSelected);
              }}
            />
          </Col>
        </Row>
      );
    }
    return null;
  };

  generateReportForSelectedRates = () => {
    const selectedRatesIds = this.state.selectedRates
      .filter((rate) => rate.isSelected)
      .map((rate) => rate.id);
    this.props.generateReportForSelectedRates(
      selectedRatesIds,
      this.state.multiple,
      !(this.props.type === 'rate'),
    );
  };

  // TODO: move this to a utils file to use it in other reports
  getParsedDate = () => {
    const dateCreatedAt = new Date(this.props.report.createdAt);
    return `${dateCreatedAt.getMonth() + 1}/${dateCreatedAt.getDate()}/${dateCreatedAt.getFullYear()} ${dateCreatedAt.getHours()}:${dateCreatedAt.getMinutes().toString().padStart(2, '0')}`;
  };

  render() {
    const { areAllRatesSelected, areAllRatesDeselected } =
      this.areAllRatesSelected();

    return (
      <Row className="topics-report">
        <div className="d-flex w-100">
          <Col className="col-12">
            <div
              className={
                !areAllRatesDeselected && this.props.report?.state !== 'LOADING'
                  ? 'generate-report'
                  : 'generate-report disabled'
              }
              onClick={
                !areAllRatesDeselected && this.props.report?.state !== 'LOADING'
                  ? this.generateReportForSelectedRates
                  : () => {}
              }
            >
              Generate report
            </div>
            <Row>
              <Col className="d-contents">
                <Button
                  className={
                    this.state.multiple
                      ? 'generate-report disabled'
                      : 'generate-report'
                  }
                  onClick={() => this.setState({ multiple: true })}
                >
                  Multiple tabbed
                </Button>
                <Button
                  className={
                    this.state.multiple
                      ? 'generate-report'
                      : 'generate-report disabled'
                  }
                  onClick={() => this.setState({ multiple: false })}
                >
                  Single tabbed
                </Button>
              </Col>
            </Row>
            {this.props.report?.state === 'LOADING' && (
              <div className="background-loading">
                <Spinner />
                <p>
                  There is a report in process. It might take some time, please
                  check back in a few moments.
                </p>
              </div>
            )}
            {this.props.report?.state !== 'LOADING' &&
              this.props.report?.link?.length > 0 && (
                <a href={this.props.report.link} download>
                  <div className="latest-created">
                    Download the latest report ({this.getParsedDate()})
                  </div>
                </a>
              )}
            <div className="report-title">
              Select the rates you want included in the report
            </div>
            {this.renderSelectAllOrNothing(
              areAllRatesSelected,
              areAllRatesDeselected,
            )}
            {this.state.selectedRates.map((rate, index) =>
              this.renderRateAndCheckbox(rate, index),
            )}
          </Col>
        </div>
      </Row>
    );
  }
}
