import React, { useEffect, useMemo, useState } from 'react';

import { SimpleDatePicker } from '../../../commonComponents/Datepicker/Datepicker';
import Spinner from '../../../commonComponents/Spinner/Spinner';
import { formatDate } from '../../../utilities/timeHelper';
/**
 * Render a table component for with the given columns for each report.
 * @param {Array} reports - Reports array.
 * @param {Boolean} loading - It is reports array loading.
 * @param {Array} columns - Columns array
 */
export function HistoricalReportTable({ reports, loading, columns = [] }) {
  const [filterDate, setFilterDate] = useState(null);
  const [latestReport, setLatestReport] = useState(null);

  useEffect(() => {
    if (reports?.length > 0) {
      reports.forEach((report) => {
        report.createdAtParsed = formatDate(report.createdAt, 'mm/dd/yyyy');
      });
      const lastReport = reports.sort((r1, r2) => r2.id - r1.id)[0];
      if (lastReport.isLoading) {
        setLatestReport(null);
      } else {
        setLatestReport(lastReport);
      }
    }
  }, [reports]);

  // filter and sort dates
  const filteredReports = useMemo(() => {
    if (!filterDate) return reports.sort((r1, r2) => r2.id - r1.id);
    return reports
      .filter(
        (report) =>
          report.createdAtParsed ===
          formatDate(new Date(filterDate).getTime(), 'mm/dd/yyyy'),
      )
      .sort((r1, r2) => r2.id - r1.id);
  }, [reports, filterDate]);

  return loading ? (
    <Spinner />
  ) : (
    <div>
      <div className="filterDate">
        {latestReport?.status === 'LOADING' && (
          <div className="background-loading">
            <Spinner />
            <p>
              There is a report in process. It might take some time, please
              check back in a few moments.
            </p>
          </div>
        )}

        {latestReport?.status === 'SUCCESS' && (
          <div className="latest-created">
            <a href={latestReport.link} target="_blank" rel="noreferrer">
              Download the latest report {latestReport.createdAtParsed}
            </a>
          </div>
        )}

        {latestReport?.status === 'ERROR' && (
          <div className="latest-created">
            <p>Last report failed.</p>
          </div>
        )}

        <label>
          Filter:
          <SimpleDatePicker
            date={filterDate}
            onChange={(date) => setFilterDate(date)}
            className="datePicker"
            placeholderText="MM/DD/YYYY"
          />
        </label>
        <button
          className="clearFilterBy"
          onClick={() => {
            setFilterDate(null);
          }}
        >
          <i className="fa fa-close fa-lg" />
        </button>
      </div>

      <div className="tableContainer">
        <table>
          <thead>
            <tr>
              {columns.map((column) => {
                if (column === 'createdAtParsed') {
                  return <th>createdAt</th>;
                }
                return <th>{column}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {filteredReports.map((report) => (
              <tr key={report.id}>
                {columns.map((column) => {
                  if (column === 'link') {
                    return (
                      <td>
                        {report.link && (
                          <a
                            href={report.link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            View Report
                          </a>
                        )}
                        {!report.link && <p>-</p>}
                      </td>
                    );
                  }
                  return <td>{report[column]}</td>;
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
