/**
 * Retrieves a date UTC with the format given.
 * @param {number} timeInMiliseconds - timestamp.
 * @param {number} format - string with the format.
 * @returns {string} - formatted date.
 */
function formatDate(timeInMiliseconds, format) {
  let formattedDate = timeInMiliseconds;
  const date = new Date(Number(timeInMiliseconds));
  const year = date.getUTCFullYear();
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');

  if (format === 'yyyy-mm-dd') {
    // OutputExample: "2024-09-15"
    formattedDate = `${year}-${month}-${day}`;
  } else if (format === 'dd/mm/yyyy') {
    // OutputExample: "15-09-2024"
    formattedDate = `${day}/${month}/${year}`;
  } else if (format === 'mm/dd/yyyy') {
    // OutputExample: "15-09-2024"
    formattedDate = `${month}/${day}/${year}`;
  }
  return formattedDate;
}

module.exports = {
  formatDate,
};
