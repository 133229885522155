import React, { useEffect, useRef, useState } from 'react';

import { SimpleDatePicker } from '../../../../commonComponents/Datepicker/Datepicker';

export function NewUsageStatisticsReport({
  reports,
  generateUsageReport,
  usageStatisticsReportsIsLoading,
}) {
  const [fromDate, setfromDate] = useState(null);
  const [untilDate, setuntilDate] = useState(null);
  const [fromDateComparison, setfromDateComparison] = useState(null);
  const [untilDateComparison, setuntilDateComparison] = useState(null);

  const [selectedPreset, setSelectedPreset] = useState(null);
  const [disabledDatePicker, setDisabledDatePicker] = useState(true);

  const fromDatePickerRef = useRef();

  const resetDates = () => {
    setfromDate(null);
    setuntilDate(null);
    setfromDateComparison(null);
    setuntilDateComparison(null);
  };

  useEffect(() => {
    if (!disabledDatePicker) {
      resetDates();
      fromDatePickerRef.current.click();
    }
  }, [disabledDatePicker]);

  const getEndOfDay = (date) => {
    date.setUTCHours(23, 59, 59, 999);
    return date.getTime();
  };

  const getStartOfDay = (date) => {
    date.setUTCHours(0, 0, 0, 0);
    return date.getTime();
  };

  const handleGenerateReport = () => {
    generateUsageReport(
      getStartOfDay(fromDate),
      getEndOfDay(untilDate),
      getStartOfDay(fromDateComparison),
      getEndOfDay(untilDateComparison),
    );
    resetDates();
  };

  // Defaults dates
  const setPresetDates = (preset) => {
    const today = new Date();
    let start;
    let end;

    switch (preset) {
      case 'today':
        start = today;
        end = today;
        setDisabledDatePicker(true);
        break;
      case 'week':
        start = new Date(today);
        start.setDate(today.getDate() - 7);
        end = today;
        setDisabledDatePicker(true);
        break;
      case 'month':
        start = new Date(today);
        start.setMonth(today.getMonth() - 1);
        end = today;
        setDisabledDatePicker(true);
        break;
      case 'year':
        start = new Date(today);
        start.setFullYear(today.getFullYear() - 1);
        end = today;
        setDisabledDatePicker(true);
        break;
      default:
        setDisabledDatePicker(false);
    }

    if (start && end) {
      setfromDate(start);
      setuntilDate(end);
      setPresetDatesComparison(preset, start, end);
    }
    setSelectedPreset(preset);
  };

  const setPresetDatesComparison = (preset, start, end) => {
    const startComparison = new Date(start);
    const endComparison = new Date(end);

    switch (preset) {
      case 'year':
        startComparison.setFullYear(startComparison.getFullYear() - 1);
        endComparison.setFullYear(endComparison.getFullYear() - 1);
        break;
      case 'month':
        startComparison.setMonth(startComparison.getMonth() - 1);
        endComparison.setMonth(endComparison.getMonth() - 1);
        break;
      case 'week':
        startComparison.setDate(startComparison.getDate() - 7);
        endComparison.setDate(endComparison.getDate() - 7);
        break;
      case 'today':
        startComparison.setDate(startComparison.getDate() - 1);
        endComparison.setDate(endComparison.getDate() - 1);
        break;
      default:
        break;
    }

    if (start && end) {
      setfromDateComparison(startComparison);
      setuntilDateComparison(endComparison);
    }
  };

  const latestReport = reports.sort((r1, r2) => r2.id - r1.id)[0];

  const isDisabledButton =
    !fromDate ||
    !untilDate ||
    !fromDateComparison ||
    !untilDateComparison ||
    usageStatisticsReportsIsLoading ||
    latestReport?.status === 'LOADING';

  return (
    <>
      <div className="preset-options">
        <button
          onClick={() => setPresetDates('today')}
          className={selectedPreset === 'today' ? 'selected' : ''}
        >
          Today
        </button>
        <button
          onClick={() => setPresetDates('week')}
          className={selectedPreset === 'week' ? 'selected' : ''}
        >
          Last Week
        </button>
        <button
          onClick={() => setPresetDates('month')}
          className={selectedPreset === 'month' ? 'selected' : ''}
        >
          Last Month
        </button>
        <button
          onClick={() => setPresetDates('year')}
          className={selectedPreset === 'year' ? 'selected' : ''}
        >
          Last Year
        </button>
        <button
          onClick={() => setPresetDates('custom')}
          className={selectedPreset === 'custom' ? 'selected' : ''}
        >
          Custom
        </button>
      </div>

      <div className="datePickers">
        <div>
          <label ref={fromDatePickerRef}>
            From date:
            <SimpleDatePicker
              date={fromDate}
              onChange={(date) => setfromDate(date)}
              className="datePicker"
              placeholderText="MM/DD/YYYY"
              disabled={disabledDatePicker}
            />
          </label>
        </div>
        <div>
          <label>
            Until date:
            <SimpleDatePicker
              date={untilDate}
              onChange={(date) => setuntilDate(date)}
              className="datePicker"
              placeholderText="MM/DD/YYYY"
              disabled={disabledDatePicker}
            />
          </label>
        </div>
      </div>
      <div className="datePickers">
        <div>
          <label>
            From date comparison:
            <SimpleDatePicker
              date={fromDateComparison}
              onChange={(date) => setfromDateComparison(date)}
              className="datePicker"
              placeholderText="MM/DD/YYYY"
              disabled={disabledDatePicker}
            />
          </label>
        </div>
        <div>
          <label>
            Until date comparison:
            <SimpleDatePicker
              date={untilDateComparison}
              onChange={(date) => setuntilDateComparison(date)}
              className="datePicker"
              placeholderText="MM/DD/YYYY"
              disabled={disabledDatePicker}
            />
          </label>
        </div>
      </div>
      <div
        className={
          isDisabledButton
            ? 'generate-report generate-report-disabled'
            : 'generate-report'
        }
        onClick={handleGenerateReport}
      >
        Generate Report
      </div>
    </>
  );
}
