import * as type from './actionTypesSagas';

export const loadRates = (shouldSort) => ({
  type: type.LOAD_RATES,
  shouldSort,
});
export const loadExams = (shouldSort) => ({
  type: type.LOAD_EXAMS,
  shouldSort,
});
