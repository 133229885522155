import { Map } from 'immutable';
import _ from 'underscore';

import * as t from './actionTypes';

const initialState = Map({
  users: [],
  pagination: {
    total: 1,
  },
  formErrors: false,
  isWaiting: false,
  loading: false,
  isLoadingBlockStatus: false,
  plans: [],
  selectedUser: {},
  statistics: {},
  createResponse: null,
  activityPagination: {},
  message: null,
  subscriptionType: {},
  isNewStudent: false,
  practiceExams: [],
  practiceExamsWaiting: false,
  restartPracticeExamWaiting: false,
  restartPracticeExamSuccess: false,
  newStudentError: false,
  noteDoNotContact: '',
  listNewCycles: {},
  disableCycle: {},
  intercomCancellationReason: '',
  userVerification: {},
  reasonCancellation: '',
  applicableCoupons: [],
  educationalInstitutionData: '',
  coupon: {},
  isLoadingFavoriteBlockStatus: false,
  isBetaUser: false,
  subscriptionHistoryUser: {},
  subscriptionHistoryLoading: false,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case t.SET_MESSAGE:
      return state.set('message', action.message);

    case t.SET_SUBSCTIPTION_TYPE:
      if (action.subscriptionType.id) {
        return state.set('subscriptionType', {
          ...state.get('subscriptionType'),
          ...action.subscriptionType,
        });
      }
      return state.set('subscriptionType', action.subscriptionType);

    case t.SET_SELECTED_USER:
      return state.set('selectedUser', action.user);

    case t.SET_USER_VERIFICATION:
      return state.set('userVerification', action.payload);

    case t.SET_IS_NEW_STUDENT:
      return state.set('isNewStudent', action.isNewStudent);

    case t.SET_RESPONSE:
      return state.set('creationResponse', action.response);

    case t.SET_LOADING:
      return state.set('loading', action.value);

    case t.UPDATEISWAITING:
      return state.set('isWaiting', action.isWaiting);

    case t.UPDATEFORMERRORS:
      return state.set('formErrors', action.payload);

    case t.SEARCHUSERSRECEIVED: {
      const { pagination, data } = action.payload;
      const oldUsers = state.get('users');
      const newUsers = pagination.page === 1 ? data : _.union(oldUsers, data);
      return state.set('users', newUsers).set('pagination', pagination);
    }
    case t.REMOVE_USER:
      return state
        .set(
          'users',
          state.get('users').filter((user) => user.id !== action.userId),
        )
        .set('isNewStudent', true);

    case t.CLEAR_ERROR_FROM_SERVICE:
      return state.set('errorFromService', null);

    case t.UPDATE_USER:
      const stateUserUpdated = state.set(
        'users',
        state
          .get('users')
          .map((user) =>
            user && user.id === action.payload.id ? action.payload : user,
          ),
      );
      if (state.get('selectedUser').id === action.payload.id) {
        return stateUserUpdated.set('selectedUser', action.payload);
      }
      return stateUserUpdated;

    case t.SET_STATISTICS:
      return state
        .set('statistics', action.statistics)
        .set('activityPagination', action.statistics.pagination);

    case t.SET_LIST_CYCLES:
      return state.set('listNewCycles', action.listNewCycles);
    case t.SET_DISABLE_CYCLES:
      return state.set('disableCycle', action.payload);
    case t.SET_LOADING_INFO:
      return state.set('loadingInfo', action.loadingInfo);

    case t.SET_PRACTICE_EXAMS:
      return state.set(
        'practiceExams',
        action.practiceExams ? action.practiceExams : [],
      );

    case t.SET_PRACTICE_EXAMS_WAITING:
      return state.set('practiceExamsWaiting', action.value);

    case t.SET_RESTART_PRACTICE_EXAM_WAITING:
      return state.set('restartPracticeExamWaiting', action.value);

    case t.SET_RESTART_PRACTICE_EXAM_SUCCESS:
      return state.set('restartPracticeExamSuccess', action.value);

    case t.SET_NEW_STUDENT_ERROR:
      return state.set('newStudentError', action.value);

    case t.SET_NOTE_DO_NOT_CONTACT:
      return state.set('noteDoNotContact', action.payload.note);

    case t.SET_CANCELLATION_REASON:
      return state.set('reasonCancellation', action.reason);

    case t.SET_APPLICABLE_COUPONS:
      return state.set('applicableCoupons', action.value);

    case t.SET_INTERCOM_CANCELLATION:
      return state.set(
        'intercomCancellationReason',
        action.payload.intercomCancellationReason,
      );
    case t.SET_EDUCATIONAL_INSTITUTION_DATA:
      return state.set(
        'educationalInstitutionData',
        action.educationalInstitution.nameCurrentEducationalInstitution,
      );
    case t.SET_COUPON_USER:
      return state.set('coupon', action.coupon);

    case t.SET_CHANGE_USER_SUBMISSION_BLOCK_STATUS:
      return state
        .set('selectedUser', {
          ...state.get('selectedUser'),
          blockSubmitQuestion: action.payload.status,
        })
        .set(
          'users',
          state.get('users').map((user) => {
            if (user.id === action.payload.userId) {
              return {
                ...user,
                blockSubmitQuestion: action.payload.status,
              };
            }
            return user;
          }),
        );

    case t.SET_IS_LOADING_BLOCK_STATUS:
      return state.set('isLoadingBlockStatus', action.status);

    case t.SET_IS_LOADING_FAVORITE_BLOCK_STATUS:
      return state.set('isLoadingFavoriteBlockStatus', action.status);

    case t.SET_CHANGE_USER_FAVORITE_BLOCK_STATUS:
      return state
        .set('selectedUser', {
          ...state.get('selectedUser'),
          blockFavoriteQuestion: action.payload.status,
        })
        .set(
          'users',
          state.get('users').map((user) => {
            if (user.id === action.payload.userId) {
              return {
                ...user,
                blockFavoriteQuestion: action.payload.status,
              };
            }
            return user;
          }),
        );
    case t.SET_IS_BETA_USER:
      return state.set('isBetaUser', action.isBetaUser);
    case t.SET_SHOW_CHANGE_EXAM_TYPE_MODAL:
      return state.set('showChangeExamTypeModal', action.value);
    case t.SET_HISTORY_SUBSCRIPTION_USER:
      return state.set('subscriptionHistoryUser', action.subscriptionHistory);
    case t.SET_LOADING_SUBSCRIPTION_HISTORY:
      return state.set('subscriptionHistoryLoading', action.value);
    default:
      return state;
  }
}
