// Insights/actionTypesSagas.js
import * as t from './actionTypesSagas';

export const getUsersActionSagas = (
  search,
  page,
  filterBySubscription,
  filterByRate,
) => ({
  type: t.USERS_GET_SAGAS,
  search,
  page,
  filterBySubscription,
  filterByRate,
});

export const deleteUserActionSagas = (id) => ({
  type: t.DELETE_USER_SAGAS,
  id,
});

export const toggleActiveActionSagas = (id, currentActive) => ({
  type: t.TOGGLE_ACTIVE_SAGAS,
  id,
  currentActive,
});

export const unlockUserActionSagas = (id) => ({
  type: t.UNLOCK_USER_SAGAS,
  id,
});

export const blockUserActionSagas = (id, state) => ({
  type: t.BLOCK_USER_SAGAS,
  id,
  state,
});

export const resetPasswordUserActionSagas = (id, fields) => ({
  type: t.RESET_PASSWORD_USER_SAGAS,
  id,
  fields,
});

export const updateUser = (user) => ({
  type: t.UPDATE_USER_SAGAS,
  user,
});

export const createStudent = (student) => ({
  type: t.CREATE_STUDENT,
  student,
});

export const loadUserStatistics = (user, page, filter) => ({
  type: t.LOAD_USER_STATISTICS,
  user,
  page,
  filter,
});

export const loginAs = (student, platformType, adminUserId) => ({
  type: t.LOGIN_AS_STUDENT,
  student,
  platformType,
  adminUserId,
});

export const getCylesInformation = (userId) => ({
  type: t.GET_CYCLE_INFORMATION,
  userId,
});

export const loadUserSubscriptionType = (student) => ({
  type: t.LOAD_USER_SUBSCRIPTION_TYPE,
  student,
});

export const userVerificationAction = (userId) => ({
  type: t.GET_USER_VERIFICATION,
  userId,
});

export const setUserVerificationAction = (userId, customer) => ({
  type: t.UPDATE_USER_VERIFICATION,
  userId,
  customer,
});

export const updateUserSubscription = (subscription) => ({
  type: t.UPDATE_USER_SUBSCRIPTION_SAGAS,
  subscription,
});

export const resetUserQuestion = (user) => ({
  type: t.RESET_USER_QUESTIONS,
  user,
});

export const loadSelectedUser = (id) => ({
  type: t.LOAD_SELECTED_USER,
  id,
});

export const loadPracticeExams = (userId) => ({
  type: t.LOAD_PRACTICE_EXAMS,
  userId,
});

export const restartPracticeExam = (practiceExamId, userId) => ({
  type: t.RESTART_PRACTICE_EXAM,
  practiceExamId,
  userId,
});
