import * as Sentry from '@sentry/browser';
import Notifications from 'react-notification-system-redux';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import Cookies from 'universal-cookie';

import { closeModal } from '../../containers/Full/actions';
import { redirect } from '../../lib/check-auth';
import getCookieTokenDomain from '../../utilities/getCookieTokenDomain';
import { getRequestErrorMessage } from '../../utilities/SagasHelper';
import * as api from '../../utilities/ServiceManager';
import setAuthorizationToken from '../../utilities/setAuthorizationToken';
import * as userToken from '../../utilities/userToken';
import { setFilters } from '../Books/actions';
import { setPermissionsLoginAs } from '../Login/actions';
import {
  LOGIN_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_EXPIRED_PASSWORD,
  UPDATE_USER_VALID_PASSWORD,
} from '../Login/constants';
import { setUser } from '../User/actions';
import {
  addNewAdmin,
  changeMainRoleReceivedAction,
  errorFetchingFromService,
  errorSubmittingFromService,
  removeAdmin,
  rolesReceivedAction,
  searchAdminsReceivedAction,
  setActivity,
  setAdminHistory,
  setAdminRoles,
  setLoadingInfo,
  setSelectedAdmin,
  toggleSelectedAdminRole,
  updateAdmin,
  updateAdminLogs,
  updateIsWaiting,
  updateProfileAction,
} from './actions';
import { getAdminRolesSaga } from './actionsSagas';
import {
  ADMINS_GET_SAGAS,
  CHANGE_MAIN_ROLE_SAGAS,
  CREATE_ADMIN_USER_SAGAS,
  DELETE_ADMIN_SAGAS,
  FETCH_ADMIN_LOGS_SAGA,
  FORCE_PASSWORD_CHANGE,
  GET_ACTIVITY,
  GET_ADMIN_HISTORY_SAGAS,
  GET_ADMIN_ROLES_SAGAS,
  LOGIN_AS_ADMIN,
  RESET_PASSWORD,
  RESET_PASSWORD_ADMIN_SAGAS,
  ROLES_GET_SAGAS,
  TOGGLE_ACTIVE_SAGAS,
  TOGGLE_ADMIN_ROLE_SAGAS,
  UPDATE_ADMIN_FILES_SAGAS,
  UPDATE_ADMIN_GROUP_SAGA,
  UPDATE_ADMIN_SAGAS,
  UPDATE_PHONE_NUMBER_SAGAS,
  UPDATE_SUPER_ADMIN,
} from './actionTypesSagas';

const cookies = new Cookies();

export function* fetchAdmins(action) {
  try {
    const adminsResponse = yield call(
      api.getAdmins,
      action.search,
      action.page,
    );
    adminsResponse.userId = action.userId;

    if (adminsResponse.data.length)
      yield put(getAdminRolesSaga(adminsResponse.data[0].id));

    yield put(searchAdminsReceivedAction(adminsResponse));
  } catch (err) {
    yield put(errorFetchingFromService(err));
  }
}

export function* fetchRoles() {
  try {
    const roles = yield call(api.getRoles);
    yield put(rolesReceivedAction(roles));
  } catch (err) {
    yield put(errorFetchingFromService(err));
  }
}

export function* changeMainRoleSagas(action) {
  let parameter;
  if (action.change === 'role') {
    parameter = { mainRole: action.payload.mainRole, isSuperAdmin: false };
  } else if (action.change === 'superAdmin') {
    parameter = { isSuperAdmin: true, mainRole: null };
  } else {
    parameter = { isSuperAdmin: false, mainRole: null };
  }
  try {
    const changeRoleResponse = yield call(
      api.changeMainRole,
      action.payload.id,
      parameter,
    );
    yield put(changeMainRoleReceivedAction(changeRoleResponse));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Role changed successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (err) {
    yield put(errorFetchingFromService(err));
  }
}

export function* watchGetAdmins() {
  yield takeLatest(ADMINS_GET_SAGAS, fetchAdmins);
}

export function* watchGetRoles() {
  yield takeLatest(ROLES_GET_SAGAS, fetchRoles);
}

export function* watchUpdateAdmin() {
  yield takeLatest(UPDATE_ADMIN_SAGAS, updateUser);
}

export function* updateUser(action) {
  const admin = action.admin;
  try {
    const updatedAdmin = yield call(api.updateAdmin, admin.id, admin);
    yield put(updateAdmin(updatedAdmin));
    yield put({ type: UPDATE_USER_VALID_PASSWORD, payload: true });
    yield put({ type: UPDATE_USER_EXPIRED_PASSWORD, payload: false });
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Admin edited successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (err) {
    yield put(showNotificationErrorFromException(err));
  }
}

export function* watchResetPassword() {
  yield takeLatest(RESET_PASSWORD, resetPassword);
}

export function* watchUpdateAdminGroup() {
  yield takeLatest(UPDATE_ADMIN_GROUP_SAGA, updateAdminGroup);
}

export function* updateAdminGroup(action) {
  try {
    yield call(api.updateAdminGroup, action.admin.id, action.admin);
    yield put(getAdminRolesSaga(action.admin.id));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Admin edited successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (error) {
    yield put(
      Notifications.show(
        { title: 'Error', message: error.response.data },
        'error',
      ),
    );
  }
}

export function* watchGetAdminRoles() {
  yield takeLatest(GET_ADMIN_ROLES_SAGAS, getAdminRoles);
}

export function* getAdminRoles(action) {
  try {
    const adminHistory = yield call(api.getAdminRoles, action.admin);
    yield put(setAdminRoles(adminHistory));
  } catch (err) {}
}

export function* watchGetAdminHistory() {
  yield takeLatest(GET_ADMIN_HISTORY_SAGAS, getAdminHistory);
}

export function* getAdminHistory(action) {
  try {
    yield put(setLoadingInfo(true));
    const adminHistory = yield call(
      api.getAdminHistory,
      action.admin,
      action.page,
      action.filter,
    );
    yield put(setAdminHistory(adminHistory.data, adminHistory.pagination));
  } catch (err) {
  } finally {
    yield put(setLoadingInfo(false));
  }
}

export function* watchUpdateAdminFiles() {
  yield takeLatest(UPDATE_ADMIN_FILES_SAGAS, updateAdminFiles);
}

export function* updateAdminFiles(action) {
  try {
    const updatedAdmin = yield call(
      api.updateAdminFiles,
      action.admin.id,
      action.admin,
    );
    yield put(updateAdmin(updatedAdmin));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Admin edited successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (err) {
    yield put(
      Notifications.show(
        { title: 'Error', message: 'There was an error updating the admin' },
        'error',
      ),
    );
  }
}

export function* watchChangeMainRole() {
  yield takeLatest(CHANGE_MAIN_ROLE_SAGAS, changeMainRoleSagas);
}

function* deleteFlow(action) {
  try {
    const admin = yield call(api.deleteAdmin, action.id);

    yield put(removeAdmin(admin));
    yield put(closeModal());
    yield put(setSelectedAdmin(null));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'User deleted successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (error) {
    yield put(
      Notifications.show(
        { title: 'Error', message: error.response.data },
        'error',
      ),
    );
  }
}

function* deletAdminWatcher() {
  yield takeEvery(DELETE_ADMIN_SAGAS, deleteFlow);
}

function* toggleActiveWatcher() {
  yield takeEvery(TOGGLE_ACTIVE_SAGAS, toggleActiveAdmin);
}

function* toggleActiveAdmin(action) {
  try {
    const admin = yield call(api.updateAdmin, action.id, {
      active: !action.currentActive,
    });

    yield put(updateAdmin(admin));
    yield put(closeModal());
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'User updated successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (error) {
    yield put(
      Notifications.show({ title: 'Error', message: error.message }, 'error'),
    );
  }
}

function* updatePhoneNumberWatcher() {
  yield takeEvery(UPDATE_PHONE_NUMBER_SAGAS, updatePhoneNumber);
}

function* updatePhoneNumber(action) {
  let response;
  try {
    yield put(updateIsWaiting(true));
    response = yield call(api.updatePhoneNumber, action.id, action.fields);
    yield put(closeModal());
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Admin updated successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
    yield put(updateIsWaiting(false));
    yield put({ type: UPDATE_USER, payload: response.data });
  } catch (error) {
    yield put(updateIsWaiting(false));
    yield put(
      Notifications.show(
        { title: 'Error', message: error.response.data },
        'error',
      ),
    );
  }
}

function* resetPasswordWatcher() {
  yield takeEvery(RESET_PASSWORD_ADMIN_SAGAS, resetPassword);
}

function* resetPassword(action) {
  try {
    yield put(updateIsWaiting(true));
    if (action.fields.currentPassword) {
      yield call(api.resetPasswordWithCurrent, action.id, action.fields);
    } else {
      yield call(api.resetPasswordAdminUser, action.id, action.fields);
    }

    yield put(closeModal());
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Admin updated successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
    yield put(updateIsWaiting(false));
  } catch (error) {
    yield put(updateIsWaiting(false));
    yield put(errorSubmittingFromService(getRequestErrorMessage(error)));
    yield put(
      Notifications.show(
        { title: 'Error', message: error.response.data },
        'error',
      ),
    );
  }
}

function* createAdminWatcher() {
  yield takeEvery(CREATE_ADMIN_USER_SAGAS, createAdmin);
}

function* createAdmin(action) {
  try {
    yield put(updateIsWaiting(true));
    const admin = yield call(api.newAdminUser, action.fields);
    admin.newUser = true;
    yield put(addNewAdmin(admin));
    yield put(setSelectedAdmin(admin));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Admin created successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
    yield put(updateIsWaiting(false));
  } catch (error) {
    yield put(updateIsWaiting(false));
    yield put(
      Notifications.show(
        { title: 'Error', message: error.response.data },
        'error',
      ),
    );
  }
}

function* toggleAdminRoleWatcher() {
  yield takeEvery(TOGGLE_ADMIN_ROLE_SAGAS, toggleAdminRole);
}

function* toggleAdminRole(action) {
  try {
    let admin;
    let role;
    if (action.role !== '') {
      role = { role: action.role };
      admin = yield call(api.toggleAdminRole, action.admin.id, role);
    } else {
      role = { isSuperAdmin: !action.admin.isSuperAdmin };
      admin = yield call(api.updateAdmin, action.admin.id, role);
    }
    yield put(toggleSelectedAdminRole(admin));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Admin role updated successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (error) {
    yield put(errorSubmittingFromService(getRequestErrorMessage(error)));
  }
}

function* loginAsWatcher() {
  yield takeEvery(LOGIN_AS_ADMIN, loginAs);
}

function* loginAs(action) {
  const cookieTokenDomain = getCookieTokenDomain(window.location);
  try {
    const response = yield call(api.loginAsAdmin, action.admin);
    /// ***
    yield put(setUser(response.token));
    Sentry.setUser({
      email: response.user.email,
      id: response.user.id,
    });
    yield put({ type: LOGIN_SUCCESS, payload: response });
    yield put(setFilters(response.user.filters));
    /// ***
    yield put(setPermissionsLoginAs(response.roles));
    userToken.setUserToken(response.token);
    /* cookies.set('token', response.token, {
      path: '/',
      domain: cookieTokenDomain,
    }); */
    cookies.set('isSuperAdmin', false, { path: '/' });
    cookies.set('userRoles', response.roles.generalRolesPermissions, {
      path: '/',
    });
    setAuthorizationToken(response.token);
    redirect(
      response.roles.generalRolesPermissions,
      response.roles.rolesPermissions,
    );
  } catch (error) {
    yield put(errorSubmittingFromService(getRequestErrorMessage(error)));
  }
}

function* updateSuperAdminSagas() {
  yield takeLatest(UPDATE_SUPER_ADMIN, updateSuperAdmin);
}

function* updateSuperAdmin(action) {
  try {
    const response = yield call(
      api.updateSuperAdmin,
      action.admin.id,
      !action.admin.isSuperAdmin,
    );

    const profile = action.admin;
    profile.isSuperAdmin = !action.admin.isSuperAdmin;
    updateProfileAction(profile);

    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Admin SuperAdmin updated successfully',
          autoDismiss: 2,
        },
        'success',
      ),
    );
  } catch (err) {
    yield put(
      Notifications.show(
        {
          title: 'Error',
          message: 'There was an error setting SuperAdmin',
        },
        'error',
      ),
    );
  }
}

export function* watchGetActivity() {
  yield takeLatest(GET_ACTIVITY, getActivity);
}

export function* getActivity(action) {
  try {
    const response = yield call(api.getActivity, action.id);
    yield put(setActivity(response));
  } catch (err) {
    // console.log(err);
  }
}

export function* watchFetchAdminLogs() {
  yield takeLatest(FETCH_ADMIN_LOGS_SAGA, fetchAdminLogs);
}

export function* fetchAdminLogs(action) {
  try {
    const adminLogs = yield call(api.fetchAdminLogs, action.admin, action.page);
    yield put(
      updateAdminLogs(adminLogs.logs, {
        total: adminLogs.total,
        page: action.page,
      }),
    );
  } catch (err) {
    yield put(
      Notifications.show(
        {
          title: 'Error',
          message: 'There was an error fetching the admin logs',
        },
        'error',
      ),
    );
  }
}

function* forcePasswordChangeWatcher() {
  yield takeLatest(FORCE_PASSWORD_CHANGE, forcePasswordChange);
}

function* forcePasswordChange(action) {
  try {
    yield call(api.forcePasswordChange, action.id);
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Admin updated successfully',
          autoDismiss: 2,
        },
        'success',
      ),
    );
  } catch (err) {
    yield put(
      Notifications.show(
        {
          title: 'Error',
          message: 'There was an error updating the admin.',
        },
        'error',
      ),
    );
  }
}

export default function* sagas() {
  yield all([
    watchGetAdmins(),
    watchGetRoles(),
    watchChangeMainRole(),
    deletAdminWatcher(),
    toggleActiveWatcher(),
    resetPasswordWatcher(),
    createAdminWatcher(),
    toggleAdminRoleWatcher(),
    watchUpdateAdmin(),
    watchUpdateAdminFiles(),
    watchGetAdminHistory(),
    watchGetAdminRoles(),
    loginAsWatcher(),
    watchUpdateAdminGroup(),
    watchFetchAdminLogs(),
    updatePhoneNumberWatcher(),
    watchGetActivity(),
    updateSuperAdminSagas(),
    forcePasswordChangeWatcher(),
  ]);
}

function showNotificationErrorFromException(exception) {
  const message = getRequestErrorMessage(exception);

  return Notifications.show({ title: 'Ops!', message }, 'error');
}
