import './QuestionPDFView.css';

import { Resizable } from 're-resizable';
import React from 'react';
import { Row } from 'react-bootstrap';

import QuestionDetailsContainer from '../../commonComponents/QuestionDetails/QuestionDetailsContainer';
import QuestionInfiniteListContainer from '../../commonComponents/QuestionInfiniteList/QuestionInfiniteListContainer';
import UploadInput from '../../commonComponents/UploadInput/UploadInput';

export default class QuestionsPDFView extends React.Component {
  state = {
    page: this.props.currentPage && window.chrome ? this.props.currentPage : 0,
  };

  componentDidMount() {
    if (
      this.props.chapter &&
      this.props.chapter.pdfUrl &&
      this.props.chapter.pdfUrl !== ''
    ) {
      this.props.getUrlPdf({ url: this.props.chapter.pdfUrl });
    }
  }

  render() {
    const pageNumber = this.state.page ? `#page=${this.state.page}` : '';
    return (
      <div className="ContentRow">
        <Resizable
          defaultSize={{
            width: '50%',
            height: '100%',
          }}
          className="ResizableContainer"
        >
          <Row style={{ height: '100%' }}>
            <QuestionInfiniteListContainer isSmallList />
            <QuestionDetailsContainer
              isSmallList
              goToPdfPage={(page) => this.setState({ page })}
              setCurrentPage={this.props.setCurrentPage}
            />
          </Row>
        </Resizable>
        {this.props.chapter &&
        this.props.chapter.pdfUrl &&
        this.props.chapter.pdfUrl !== '' ? (
          <iframe
            onMouseOver={(e) => e.target.focus()}
            id="pdf-viewer-iframe"
            src={this.props.urlPdfPrivate + pageNumber}
            style={{ display: 'flex', flex: 1 }}
            key={`pdf-viewer-iframe-key-${pageNumber}`}
          />
        ) : (
          <div className="NoPdfText">
            No PDF file found.
            <UploadInput
              editPermission={this.props.editPermission}
              chapter={this.props.chapter}
              deletePdfChapter={this.props.deletePdfChapter}
              updateQuestionPdfProcessing={
                this.props.updateQuestionPdfProcessing
              }
              questionPdfProcessing={this.props.questionPdfProcessing}
              updateChapter={this.props.updateChapter}
            />
          </div>
        )}
      </div>
    );
  }
}
