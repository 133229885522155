import { connect } from 'react-redux';

import { setRateChapterQuestionsReport } from '../../Bibliographies/actions';
import { loadReportSaga } from '../../Bibliographies/actionsSagas';
import {
  getRateChapterQuestionsReport,
  loadingGrid,
} from '../../Bibliographies/selectors';
import { loadExams } from '../../ChangeExam/actionsSagas';
import { getExams } from '../../ChangeExam/selectors';
import { loadCycles } from '../../Cycles/actionsSagas';
import { getCycles } from '../../Cycles/selectors';
import { loadRates } from '../../Rates/actionsSagas';
import { getRates, getWaiting } from '../../Rates/selectors';
import {
  generateFavoriteQuestionsReport,
  generateRateQuestionsReport,
  generateUsageReport,
  getBibliographyReportForGivenRates,
  getLastFavoriteQuestionsReports as getLastFavoriteQuestionsReportsAction,
  getLastRateQuestionsReports as getLastRateQuestionsReportsAction,
  getProfileSheetReport,
  getUsageReports,
  getUsersCount,
  getUserSubmittedQuestionsReportForGivenRates,
  setCompletionStatus,
  setGraphSagas,
  setUserSubmittedQuestionsReport,
} from '../actions';
import { getCompletionStatusSagas } from '../actionsSagas';
import {
  getBibliographyCSVJsonReport,
  getBibliographyReportError,
  getBibliographyReportIsLoading,
  getCompletionStatus,
  getGraph,
  getIsUserSubmittedQuestionsReportLoading,
  getLastFavoriteQuestionsReports,
  getLastRateQuestionsReports,
  getLoadingFavoriteQuestionsReport,
  getProfileSheetCsv,
  getProfileSheetIsLoading,
  getUsageStatisticsReports,
  getUsageStatisticsReportsIsLoading,
  getUsersCountData,
  getUsersReportIsLoading,
  getUserSubmittedQuestionsReportCSVJson,
  getUserSubmittedQuestionsReportError,
} from '../selectors';
import Reports from './Reports';

const mapStateToProps = (state) => ({
  report: getRateChapterQuestionsReport(state),
  isLoading: loadingGrid(state),
  ratesIsLoading: getWaiting(state),
  rates: getRates(state),
  exams: getExams(state),
  /* All CSV Bibliography Data Report */
  bibliographyReport: getBibliographyCSVJsonReport(state),
  bibliographyReportIsLoading: getBibliographyReportIsLoading(state),
  bibliographyReportError: getBibliographyReportError(state),
  /* Users Report */
  usersReportIsLoading: getUsersReportIsLoading(state),
  usersCount: getUsersCountData(state),
  graph: getGraph(state),
  /* Completion Status Report */
  completionStatus: getCompletionStatus(state),
  /* Profile Sheet Data Report */
  cycles: getCycles(state),
  profileSheetCsv: getProfileSheetCsv(state),
  profileSheetIsLoading: getProfileSheetIsLoading(state),
  /* User Submitted Questions Report */
  userSubmittedQuestionsReport: getUserSubmittedQuestionsReportCSVJson(state),
  isUserSubmittedQuestionsReportLoading:
    getIsUserSubmittedQuestionsReportLoading(state),
  userSubmittedQuestionsReportError:
    getUserSubmittedQuestionsReportError(state),
  /* Favorite questions Report */
  lastFavoriteQuestionsReports: getLastFavoriteQuestionsReports(state),
  loadingFavoriteQuestionsReport: getLoadingFavoriteQuestionsReport(state),
  /* Rate questions Report */
  lastRateQuestionsReports: getLastRateQuestionsReports(state),
  // rateQuestionsReportIsLoading={this.props.favoriteQuestionsReportIsLoading}

  /* Usage Statistics Report */
  usageStatisticsReports: getUsageStatisticsReports(state),
  usageStatisticsReportsIsLoading: getUsageStatisticsReportsIsLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadRates: () => {
    dispatch(loadRates());
  },
  loadExams: () => {
    dispatch(loadExams());
  },
  downloadReport: (report) => {
    dispatch(loadReportSaga(report));
  },
  onClearReport: () => {
    dispatch(setRateChapterQuestionsReport());
  },

  /* All CSV Bibliography Data Report */
  loadCycles: () => {
    dispatch(loadCycles());
  },
  getBibliographyReportForGivenRates: (rates, ratingExams, multiple) => {
    dispatch(getBibliographyReportForGivenRates(rates, ratingExams, multiple));
  },
  /* Users Report */
  getUsersCount: (query) => {
    dispatch(getUsersCount(query));
  },
  setGraph: (graph) => {
    dispatch(setGraphSagas(graph));
  },
  /* Profile Sheet Data Report */
  getProfileSheetReport: (cycleId) => {
    dispatch(getProfileSheetReport(cycleId));
  },
  /* Completion Status Report */
  setCompletionStatus: (data) => {
    dispatch(setCompletionStatus(data));
  },
  getCompletionStatusSagas: () => {
    dispatch(getCompletionStatusSagas());
  },
  /* User Submitted Questions Report */
  getUserSubmittedQuestionsReportForGivenRates: (rates) => {
    dispatch(getUserSubmittedQuestionsReportForGivenRates(rates));
  },
  setUserSubmittedQuestionsReport: (data) => {
    dispatch(setUserSubmittedQuestionsReport(data));
  },
  /* Favorite questions Report */
  generateFavoriteQuestionsReport: (ratesIds, ratingExams) => {
    dispatch(generateFavoriteQuestionsReport(ratesIds, ratingExams));
  },
  getLastFavoriteQuestionsReports: () => {
    dispatch(getLastFavoriteQuestionsReportsAction());
  },
  /* Rate questions Report */
  generateRateQuestionsReport: (ratesIds) => {
    dispatch(generateRateQuestionsReport(ratesIds));
  },
  getLastRateQuestionsReports: () => {
    dispatch(getLastRateQuestionsReportsAction());
  },
  /* Usage Statistics Report */
  generateUsageReport: (
    fromDate,
    untilDate,
    fromDateComparison,
    untilDateComparison,
  ) => {
    dispatch(
      generateUsageReport(
        fromDate,
        untilDate,
        fromDateComparison,
        untilDateComparison,
      ),
    );
  },
  getUsageReports: () => {
    dispatch(getUsageReports());
  },
});

const ReportsContainer = connect(mapStateToProps, mapDispatchToProps)(Reports);

export default ReportsContainer;
