import * as t from './actionTypes';

export const setRates = (rates) => ({
  type: t.SET_RATES,
  payload: rates,
});

export const setExams = (exams) => ({
  type: t.SET_EXAMS,
  payload: exams,
});

export const setWaiting = (value) => ({
  type: t.SET_WAITING,
  value,
});
