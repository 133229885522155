import { connect } from 'react-redux';

import {
  clearAutomaticMasterReportProps,
  getAllMasterUserReport,
} from '../../actions';
import {
  getAllMasterUserReportLoading,
  getAutomaticMasterReportData,
  getCsvAvailability,
} from '../../selectors';
import MasterUserReport from './MasterUserReport';

const mapStateToProps = (state) => ({
  isCsvAvailable: getCsvAvailability(state),
  automaticMasterReportData: getAutomaticMasterReportData(state),
  allMasterUserReportLoading: getAllMasterUserReportLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  clearAutomaticReportProps: () => {
    dispatch(clearAutomaticMasterReportProps());
  },
  getAllMasterUserReport: () => {
    dispatch(getAllMasterUserReport());
  },
});

const HistoricalUsersReportContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MasterUserReport);

export default HistoricalUsersReportContainer;
