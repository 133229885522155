import { connect } from 'react-redux';

import ChangeExam from '../../../commonComponents/Modals/ChangeExam/ChangeExam';
import { getIsWaiting } from '../../Users/selectors';
import { loadExams, loadRates } from '../actionsSagas';
import { getExams, getRates } from '../selectors';

const mapStateToProps = (state, ownProps) => ({
  rates: getRates(state),
  exams: getExams(state),
  user: ownProps.user,
  onSubmit: ownProps.onSubmit,
  waiting: getIsWaiting(state),
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: () => {
    dispatch(loadRates());
    dispatch(loadExams());
  },
});

const ChangeExamContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChangeExam);

export default ChangeExamContainer;
