import './UsersList.css';

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroller';
import Select from 'react-select';
import pure from 'recompose/pure';

import NumberUtils from '../../lib/NumberUtils';
import Spinner from '../Spinner/Spinner';
import UserRow from './UserRow/UserRow';

function renderUsers(
  users,
  updateProfile,
  profile,
  toggleNew,
  newStudent,
  setSelectedUser,
  loadUserStatistics,
) {
  if (users?.length > 0) {
    return users.map((user) => (
      <UserRow
        key={NumberUtils.obtainUniqueKey()}
        user={user}
        updateProfile={updateProfile}
        selected={profile && user ? profile.id === user.id : false}
        toggleNew={toggleNew}
        newStudent={newStudent}
        setSelectedUser={setSelectedUser}
        loadUserStatistics={loadUserStatistics || (() => {})}
      />
    ));
  }
  return (
    <div className="text-center mt-4 text-light" style={{ fontSize: '25px ' }}>
      No users found.
    </div>
  );
}

function renderLoading() {
  return <Spinner />;
}

function renderTable(
  loading,
  users,
  updateProfile,
  profile,
  toggleNew,
  newStudent,
  setSelectedUser,
  loadUserStatistics,
) {
  return (
    <div className="table">
      {loading ? renderLoading() : ''}
      <div>
        {renderUsers(
          users,
          updateProfile,
          profile,
          toggleNew,
          newStudent,
          setSelectedUser,
          loadUserStatistics,
        )}
      </div>
    </div>
  );
}

const areMoreUser = (totalPagination, users, loading) => {
  if (totalPagination && users && users.length && !loading) {
    return totalPagination > users.length;
  }
};

function UsersListStateless({
  users,
  forAdmin,
  updateProfile,
  search,
  filterBySubscription,
  filterByRate,
  setSearch,
  loading,
  rates,
  changePage,
  page,
  totalPagination,
  profile,
  toggleNew,
  newStudent,
  setSelectedUser,
  loadUserStatistics,
  generalPermissions,
  loggedUser,
}) {
  return (
    <div>
      <div className="usersListItems">
        <Row className="usersListItemsRow">
          <Col className="noPadRight" xs={9} md={9}>
            <div className="searchInput">
              <input
                id="input"
                className="form-control"
                value={search}
                onChange={(typedSearch) =>
                  setSearch(
                    typedSearch.target.value,
                    filterBySubscription,
                    filterByRate,
                  )
                }
                onPaste={(typedSearch) =>
                  setSearch(
                    typedSearch.target.value,
                    filterBySubscription,
                    filterByRate,
                  )
                }
              />
              {search ? (
                <i
                  onClick={() =>
                    setSearch(
                      '', // Clear specific field filter
                      filterBySubscription,
                      filterByRate,
                    )
                  }
                  className="fa fa-close fa-lg"
                />
              ) : (
                <i className="fa fa-search fa-lg" />
              )}
            </div>
          </Col>
          <Col xs={2} md={2} className="plusContainer">
            {((!forAdmin &&
              generalPermissions.find(
                (permission) => permission === 'Students.Create',
              )) ||
              (loggedUser && loggedUser.isSuperAdmin) ||
              (forAdmin &&
                generalPermissions.find(
                  (permission) => permission === 'Admins.Create',
                ))) && (
              <i onClick={() => toggleNew()} className="fa fa-plus fa-lg" />
            )}
          </Col>
        </Row>
        {!forAdmin && (
          <Row className="mx-1 mt-2">
            <Col>
              <Select
                value={null}
                options={rates
                  .filter((rate) => rate.name !== 'PMK')
                  .map((option) => {
                    option.value = option.id;
                    option.label = option.shortname
                      ? option.shortname
                      : option.name;
                    return option;
                  })}
                placeholder="Filter by rate"
                onChange={(selectedOption) =>
                  setSearch(
                    search,
                    filterBySubscription,
                    selectedOption ? selectedOption.value : null,
                  )
                }
              />
            </Col>
            <Col>
              <Select
                value={null}
                options={[
                  { value: 'Apple', label: 'Apple' },
                  { value: 'Stripe', label: 'Stripe' },
                ]}
                placeholder="Filter by subscription"
                onChange={(selectedOption) =>
                  setSearch(
                    search,
                    selectedOption ? selectedOption.value : null,
                    filterByRate,
                  )
                }
              />
            </Col>
          </Row>
        )}
        <Row className="mr-2">
          <Col>
            {filterByRate && (
              <div className="filteredByContainer">
                <p>
                  <span style={{ color: '#366fde' }}>
                    {rates.find((rate) => rate.id == filterByRate)?.shortname}
                  </span>
                </p>
                <p
                  className="clearFilterBy"
                  onClick={() => {
                    setSearch(search, filterBySubscription, '');
                  }}
                >
                  <i className="fa fa-close fa-lg" />
                </p>
              </div>
            )}
          </Col>
          <Col>
            {filterBySubscription && (
              <div className="filteredByContainer">
                <p>
                  <span style={{ color: '#366fde' }}>
                    {filterBySubscription}
                  </span>
                </p>
                <p
                  className="clearFilterBy"
                  onClick={() => {
                    setSearch(search, '', filterByRate);
                  }}
                >
                  <i className="fa fa-close fa-lg" />
                </p>
              </div>
            )}
          </Col>
        </Row>
      </div>

      <div className="animated fadeIn usersTable">
        <div style={{ height: '100%', overflow: 'auto' }}>
          <InfiniteScroll
            pageStart={0}
            loadMore={() => changePage(page + 1)}
            hasMore={!loading && areMoreUser(totalPagination, users, loading)}
            useWindow={false}
          >
            {renderTable(
              loading,
              users,
              updateProfile,
              profile,
              toggleNew,
              newStudent,
              setSelectedUser,
              loadUserStatistics,
            )}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
}

export default pure(UsersListStateless);
