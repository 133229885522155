import './Admins.css';

import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Cookies from 'universal-cookie';

import AdminProfile from '../../../commonComponents/AdminProfile/AdminProfile';
import Spinner from '../../../commonComponents/Spinner/Spinner';
import UsersListStateful from '../../../commonComponents/UsersList/UsersListStateful';
import { redirect } from '../../../lib/check-auth';
import * as api from '../../../utilities/ServiceManager';
import Activity from './Activity/Activity';
import NewAdmin from './NewAdmin';
import Roles from './Roles/Roles';

class Admins extends React.Component {
  static propTypes = {
    admins: PropTypes.array,
    pagination: PropTypes.object.isRequired,
    getAdminsFunction: PropTypes.func,
  };

  state = {
    admins: [],
    profile: {},
    roles: [],
    showNewAdmin: false,
    search: '',
    page: 0,
  };

  componentWillUnmount() {
    this.props.cleanAdmins();
  }

  componentWillMount() {
    // Don't load new admins if they have been loaded already
    if (this.props.admins.length === 0 || this.state.search === '') {
      this.getAdmins();
    }
    this.props.resetForm();
    this.props.getAllRoles();
  }

  componentWillReceiveProps({
    admins,
    profile,
    generalPermissions,
    rolesPermissions,
  }) {
    this.setState({
      admins,
      loading: false,
    });
    const cookies = new Cookies();
    if (!profile && admins && admins.length) {
      this.props.updateProfile(admins[0]);
    }
    if (this.props.profile && profile && profile.id !== this.props.profile.id) {
      this.props.getAdminHistory(profile.id, 1);
      this.props.getAdminRoles(profile.id);
      this.setState({ showNewAdmin: false });
      if (profile.newUser) {
        // this.setSearch(profile.email);
        this.setSelectedAdmin(profile);
      }
    }
    if (profile === null) {
      this.setState({ showNewAdmin: true });
    }
    if (this.props.profile === null && profile) {
      this.setState({ showNewAdmin: false });
    }
    if (cookies.get('loginAs') === 'true') {
      if (
        this.props.generalPermissions !== generalPermissions &&
        rolesPermissions !== this.props.rolesPermissions
      ) {
        this.props.setLoading(false);
        redirect(generalPermissions, rolesPermissions);
      }
    }
    /* const userObject = {
      userId: profile.id,
      isStudent: false,
    }; */
    // this.getUserStatus(userObject);
  }

  getAdmins = (search = this.state.search, page = 0) => {
    this.setState({ loading: true });
    this.props.getAdminsFunction(search, page);
  };

  updateProfile = (newProfile) => {
    this.props.updateProfile(newProfile);
  };

  setSelectedAdmin = (admin) => {
    this.setState({ showNewAdmin: false });
    this.props.setSelectedAdmin(admin);
  };

  setSearch = (search) => {
    this.props.cleanAdmins();
    this.setState({ loading: true, search, page: 0 });
    this.debounce(() => this.getAdmins(search), 500);
  };

  debounce = (func, wait = 500, immediate = false, ...theArgs) => {
    const context = this;
    const args = theArgs;
    const later = () => {
      this.timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !this.timeout;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };

  toggleNew = () => {
    this.setState({ showNewAdmin: !this.state.showNewAdmin });
  };

  clearSearchInput = () => {
    this.setState({ search: '' }, () =>
      this.usersListStateful.props.reloadUsers(this.state.search),
    );
  };

  getUserStatus = async (info) => {
    const queryStatus = `id=${info.userId}&isStudent=${info.isStudent}`;
    try {
      await api.getUserStatus(queryStatus).then((data) => {
        if (data.data != null) {
          const time = new Date();
          const activity = data.data.path;
          const when = data.data.time;
          const diff = time - when;
          const isNowActive = diff < 120000;
          const mobile = data.data.isMobile;
          this.setState({
            path: activity,
            isNowActive,
            mobile,
          });
        } else {
          this.setState({ isNowActive: false });
        }
      });
    } catch (error) {}
  };

  changePage = (page) => {
    this.setState({ page });
    this.debounce(() => this.getAdmins(this.state.search, page), 500, true);
  };

  render() {
    const { user, generalPermissions } = this.props;
    const roles = this.props.roles || [];
    const loading = this.props.admins.length === 0;
    return (
      <div className="usersSectionContainer">
        <Row className="containerRow">
          <Col className="usersListContainer" xs={3} md={3}>
            <div>
              <UsersListStateful
                ref={(ref) => (this.usersListStateful = ref)}
                search={this.state.search}
                users={this.state.admins}
                onCreateAdmin={this.props.createAdmin}
                forAdmin
                updateProfile={this.updateProfile}
                profile={this.props.profile || { name: '', lastName: '' }}
                loading={this.state.loading}
                pagination={this.props.pagination}
                page={this.state.page}
                setSelectedUser={this.setSelectedAdmin}
                toggleNew={this.toggleNew}
                clearSearchInput={this.clearSearchInput}
                loggedUser={this.props.user}
                generalPermissions={this.props.generalPermissions}
                setSearch={this.setSearch}
                changePage={this.changePage}
              />
            </div>
          </Col>
          <Col className={!loading ? 'hidden' : 'adminSpinner'}>
            <div>
              <Spinner text="Loading Admin Profile" />
            </div>
          </Col>
          <Col xs={3} md={3} className={loading ? 'hidden' : 'AdminCol'}>
            <div className="userProfileContainer">
              {!this.state.showNewAdmin ? (
                <AdminProfile
                  updateSuperAdmin={this.props.updateSuperAdmin}
                  forAdmin
                  profile={this.props.profile || { name: '', lastName: '' }}
                  roles={roles.filter((role) => !role.isStudyProgramRole)}
                  changeRole={this.props.changeMainRole}
                  onResetPassword={this.props.resetPassword}
                  onDelete={this.props.deleteUser}
                  onToggleActive={this.props.toggleActive}
                  rates={[]}
                  updateUser={this.props.updateAdmin}
                  updateAdminFile={this.props.updateAdminFile}
                  loggedUser={this.props.user}
                  generalPermissions={this.props.generalPermissions}
                  loginAs={this.props.loginAs}
                  isNowActive={this.state.isNowActive}
                  mobile={this.state.mobile}
                  path={this.state.path}
                  forcePasswordChange={this.props.forcePasswordChange}
                />
              ) : (
                <NewAdmin
                  admins={this.props.admins}
                  ref={(ref) => (this.newAdmin = ref)}
                  handleSubmit={this.props.handleSubmit}
                  onCreateAdmin={this.props.onCreateAdmin}
                  loading={this.props.loading}
                  creationResponse={this.props.creationResponse}
                  resetForm={this.props.resetForm}
                  onCancel={() => {
                    this.setState({ showNewAdmin: false });
                  }}
                  roles={this.props.allRoles}
                  getAllroles={this.props.getAllRoles}
                  loadRates={this.props.loadRates}
                  rates={this.props.rates}
                  loadQuals={this.props.loadQuals}
                  quals={this.props.quals}
                  setSelectedUser={this.setSelectedAdmin}
                  onSetSearch={this.setSearch}
                />
              )}
            </div>
          </Col>
          {!this.state.showNewAdmin && (
            <Col
              className={loading ? 'hidden' : ''}
              xs={3}
              md={3}
              lg={3}
              style={{
                paddingRight: '0px',
                paddingLeft: '0px',
                maxWidth: '480px',
                minWidth: '320px',
                padding: '15px',
              }}
            >
              {((user && user.isSuperAdmin) ||
                generalPermissions.find(
                  (permission) => permission === 'Admins.ViewPermissions&Roles',
                )) && (
                <Roles
                  user={this.props.profile}
                  roles={this.props.adminRoles}
                  allRoles={this.props.allRoles}
                  updateAdmin={this.props.updateAdmin}
                  loadRates={this.props.loadRates}
                  rates={this.props.rates}
                  loadQuals={this.props.loadQuals}
                  quals={this.props.quals}
                  onUpdateAdminGroups={this.props.onUpdateAdminGroups}
                />
              )}
            </Col>
          )}
          {!this.state.showNewAdmin && (
            <Col
              className={loading ? 'hidden' : ''}
              xs={3}
              md={3}
              lg={3}
              style={{
                paddingRight: '0px',
                paddingLeft: '0px',
                maxWidth: '480px',
                minWidth: '320px',
                padding: '15px 30px 15px 15px',
              }}
            >
              {((user && user.isSuperAdmin) ||
                generalPermissions.find(
                  (permission) => permission === 'Admins.ViewPermissions&Roles',
                )) && (
                <Activity
                  activity={this.props.activity}
                  getActivity={this.props.getActivity}
                  closeModal={this.props.closeModal}
                  openModal={this.props.openModal}
                  user={this.props.profile}
                  histoy={this.props.adminHistory}
                  loading={this.props.loadingInfo}
                  onPageChange={(user, page) => {
                    this.props.getAdminHistory(user, page);
                  }}
                  getAdminHistory={this.props.getAdminHistory}
                  pagination={this.props.historyPagination}
                />
              )}
            </Col>
          )}
        </Row>
      </div>
    );
  }
}

export default Admins;
