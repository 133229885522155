import axios from 'axios';
import { browserHistory } from 'react-router';
import UniversalCookies from 'universal-cookie';

import { setFilters } from '../components/Books/actions';
import { setAdmins } from '../components/Groups/actions';
import { setLoading, setPermissions } from '../components/Login/actions';
import * as endpoints from '../constants/endpoints';
import { infinitePageSize, pageSize } from '../constants/pagination';
import { store } from '../index';
import redirectTo401IfForbidden from './redirectTo401IfForbidden';
import redirectToLoginIfTokenExpiredInterceptor from './redirectToLoginIfTokenExpired';
import redirectToMaintenancePage from './redirectToMaintenancePage';
import renewCookieExpirationTime from './renewCookieExpirationTime';
import setAuthorizationToken from './setAuthorizationToken';
import * as userToken from './userToken';

const cookies = new UniversalCookies();

const baseUrl = endpoints.baseURL;

redirectToLoginIfTokenExpiredInterceptor(() => cookies.remove('token'));
redirectTo401IfForbidden();
redirectToMaintenancePage();

// Observer to renew auth cookie expiration time
renewCookieExpirationTime();

const token = userToken.getUserToken(); // cookies.get('token');
if (token) {
  const currentLocation = browserHistory.getCurrentLocation();
  setAuthorizationToken(token);
  cookies.set('loading', true, { path: '/' });
  getMe().then(() => {
    browserHistory.push(currentLocation.pathname);
  });
}

export function getMe() {
  // if (store) store.dispatch(setLoading(true));
  return axios
    .get(`${baseUrl}/adminuser/me`)
    .then(({ data }) => {
      store.dispatch(setPermissions(data));
      store.dispatch(setFilters(data.user.filters));

      if (
        data.user.validPasswordCriteria === false ||
        data.user.isPasswordExpired === true
      ) {
        // delete token cookie for redirections to login
        // cookies.remove('token', { path: '/' });
        userToken.removeUserToken();
      }
    })
    .catch((err) => {
      /* if (!window.location.href.includes('/maintenance')) {
        getMe();
      } */
    });
}

export function getLoggedAdmin() {
  return axios.get(`${baseUrl}/adminuser/me`).then(({ data, status }) => {
    if (status === 503) {
      // server is in maintenance
      return { isMaintenance: true };
    }

    if (
      window.location.href &&
      window.location.href.includes('token') &&
      !window.location.href.includes('unsubscribe')
    ) {
      history.push('/adminuser/dashboard');
    }
    store.dispatch(setLoading(false));
    store.dispatch(setAdmins(data));
    if (data.isForceUpdatePassword && data.resetPasswordToken) {
      history.push(`/password-reset/${data.resetPasswordToken}`);
    }
    return { isMaintenance: false };
  });
}

export function forcePasswordChange(id) {
  return axios
    .patch(`${baseUrl}/adminuser/forcePasswordChange`, { id })
    .then(({ data }) => data);
}

export function getActivity(id) {
  return axios
    .get(`${baseUrl}/adminuser/getActivity/?id=${id}`)
    .then(({ data }) => data);
}

export function logout() {
  return axios.post(`${baseUrl}/adminuser/logout`).then(({ data }) => data);
}

export function verificateAuthyCode(values) {
  return axios
    .post(`${baseUrl}/adminuser/verificateAuthyCode`, values)
    .then(({ data }) => data);
}

export function resendCode(email) {
  return axios
    .post(`${baseUrl}/adminuser/resendCode`, { email })
    .then(({ data }) => data);
}

export function login(email, password, userCookie) {
  return axios
    .post(`${baseUrl}/adminuser/login`, {
      email,
      password,
      userCookie,
    })
    .then(({ data }) => data);
}

function setFormData(fields) {
  const formData = new FormData();
  for (const field in fields) {
    if (
      field !== 'pdf' &&
      field !== 'image' &&
      field !== 'notesImage' &&
      field !== 'files' &&
      field !== 'photo' &&
      field !== 'rateRoles' &&
      field !== 'qualRoles' &&
      field !== 'fileImageUrl' &&
      field !== 'newPdfFile'
    ) {
      if (!fields.hasOwnProperty(field)) continue;
      formData.append(field, fields[field]);
    }
  }
  if (fields.files) {
    if (fields.files.length > 1) {
      for (const file of fields.files) {
        formData.append('files', file);
      }
    } else {
      fields.files && formData.append('files', fields.files[0]);
    }
  }
  fields.rateRoles &&
    formData.append('rateRoles', JSON.stringify(fields.rateRoles));
  fields.qualRoles &&
    formData.append('qualRoles', JSON.stringify(fields.qualRoles));
  fields.photo && formData.append('photo', fields.photo);
  fields.pdf && formData.append('pdf', fields.pdf);
  fields.image && formData.append('image', fields.image);
  fields.notesImage && formData.append('notesImage', fields.notesImage);
  fields.fileImageUrl && formData.append('imageUrl', fields.fileImageUrl);
  fields.newPdfFile && formData.append('newPdfFile', fields.newPdfFile);
  return formData;
}

export function getUsers(
  search,
  page,
  filterBySubscription = '',
  filterByRate = '',
) {
  let queryParam = `&skip=${page * infinitePageSize}&limit=${infinitePageSize * (page + 1)}`;
  if (search || filterBySubscription || filterByRate) {
    queryParam += `&search=${search}&subscription=${filterBySubscription}&rateId=${filterByRate}`;
  }
  return axios
    .get(`${endpoints.getUsers}?${queryParam}`)
    .then(({ data }) => data);
}

export function getAdminHistory(id, page = 1, filter) {
  const queryString = getQueryString(page);

  return axios
    .post(`${baseUrl}/adminuser/${id}/findActivity/?${queryString}`, filter)
    .then(({ data }) => data);
}

export function getAdminRoles(id) {
  return axios
    .get(`${baseUrl}/adminuser/${id}/getAdminRoles/`)
    .then(({ data }) => data);
}

export function getAdmins(search, page = 0) {
  let csearch = '';
  let queryString = `skip=${page * infinitePageSize}&limit=${infinitePageSize * (page + 1)}&sort=name ASC`;
  if (search) {
    csearch = capitalizeFirstLetter(search);
    queryString += `&where={"or": [ {"name": {"contains":"${search}"}}, {"name": {"contains":"${csearch}"}} , {"lastName": {"contains":"${search}"}} ,{"lastName": {"contains":"${csearch}"}} , {"email": {"contains":"${search.toLowerCase()}"}}]}`;
  }
  return axios
    .get(`${endpoints.admins}?${queryString}`)
    .then(({ data }) => data);
}

export function loadAllAdmins() {
  return axios
    .get(`${baseUrl}/adminuser?limit=9999&sort=name ASC`)
    .then(({ data }) => data);
}

export function getChapterLogs(id) {
  return axios.get(`${baseUrl}/chapter/${id}/logs`).then(({ data }) => data);
}

export function getReferenceLogs(id) {
  return axios.get(`${baseUrl}/reference/${id}/logs`).then(({ data }) => data);
}

export function newAdminUser(fields) {
  return axios
    .post(`${baseUrl}/adminuser`, setFormData(fields))
    .then(({ data }) => data);
}

export function resetPasswordAdminUser(id, passwords) {
  return axios.patch(`${baseUrl}/adminuser/${id}`, passwords);
}

export function resetPasswordWithCurrent(id, passwords) {
  return axios.patch(`${baseUrl}/adminuser/${id}/resetPassword`, passwords);
}

export function resetPassword(newPwd) {
  return axios.patch(`${baseUrl}/adminuser/resetPassword`, newPwd);
}

export function updatePhoneNumber(id, fields) {
  return axios.patch(`${baseUrl}/adminuser/${id}/updatePhoneNumber`, fields);
}

export function resetPasswordUser(id, passwords) {
  return axios.patch(`${baseUrl}/user/${id}`, passwords);
}

export function changeStateUser(payload, type) {
  return axios
    .patch(`${endpoints.admins}/${payload.id}`, type)
    .then(({ data }) => data);
}

export function deleteAdmin(id) {
  return axios.delete(`${baseUrl}/adminuser/${id}`).then(({ data }) => data);
}

export function deleteUser(id) {
  return axios.delete(`${baseUrl}/user/${id}`).then(({ data }) => data);
}

export function unlockUser(id) {
  return axios
    .post(`${baseUrl}/adminuser/unlockUser?userId=${id}`)
    .then(({ data }) => data)
    .catch((error) => {
      if (error) {
        throw new Error(error.response.data.err);
      }
      throw new Error('Server error');
    });
}

export function blockUser(id, state) {
  return axios
    .put(`${baseUrl}/adminuser/updateUserBlockStatus/${id}`, {
      blockStatus: state,
    })
    .then(({ data }) => data)
    .catch((error) => {
      if (error) {
        throw new Error(error.response.data.err);
      }
      throw new Error('Server error');
    });
}

export function updateUser(id, fields) {
  return axios
    .patch(`${baseUrl}/user/${id}`, fields)
    .then(({ data }) => data)
    .catch((error) => {
      if (error.response) {
        throw new Error(error.response.data.err || error.response.data);
      }
      throw new Error('Server error');
    });
}

export function updateAdmin(id, fields) {
  return axios
    .patch(`${baseUrl}/adminuser/${id}`, fields)
    .then(({ data }) => data);
}

export function updateAdminGroup(admin, groups) {
  return axios
    .patch(`${baseUrl}/adminuser/${admin}/adminGroups`, groups)
    .then(({ data }) => data);
}

export function updateAdminFiles(id, fields) {
  return axios
    .patch(`${baseUrl}/adminuser/updateFiles/${id}`, setFormData(fields))
    .then(({ data }) => data);
}

export function loadRatingExamQuestions(rate, cycle) {
  return axios
    .get(`${baseUrl}/rate/${rate.id}/getRatingExamQuestions?cycle=active`)
    .then(({ data }) => data);
}

export function getCompletionStatus() {
  return axios
    .get(`${baseUrl}/rate/getCompletionStatus`)
    .then(({ data }) => data);
}

export function checkQuestionSpelling(id) {
  return axios
    .get(`${baseUrl}/question/${id}/checkSpelling`)
    .then(({ data }) => data);
}

export function getRates(page, isBibRates) {
  if (isBibRates) {
    return axios
      .get(`${baseUrl}/rate/findBibliographyRates`)
      .then(({ data }) => data);
  }

  if (page) {
    const queryString = getQueryString(page);

    return axios.get(
      `${baseUrl}/rate?sort=isPmkOnly DESC&sort=name ASC&${queryString}`,
    );
  }
  return axios
    .get(`${baseUrl}/rate?sort=isPmkOnly DESC&sort=name ASC`)
    .then(({ data }) => data);
}

export function requestPassword(email) {
  return axios
    .patch(`${baseUrl}/adminuser/requestResetPassword/`, {
      email,
    })
    .then(({ data }) => data);
}

export function getQuals(page = 1) {
  const queryString = getQueryString(page);

  return axios
    .get(`${baseUrl}/qual?sort=name ASC&${queryString}`)
    .then(({ data }) => data);
}

export function getPageRoles(page = 1) {
  const queryString = getQueryString(page);

  return axios
    .get(
      `${baseUrl}/role?isStudyProgramRole=false&&populate=pagePermissions&${queryString}`,
    )
    .then(({ data }) => data);
}

export function getAllAdminsByRate(showInactive, isRateStudyProgram) {
  const spType = isRateStudyProgram ? 'rate' : 'qual';
  return axios
    .get(
      `${baseUrl}/rate/adminUsers/?showInactiveUsers=${showInactive}&type=${spType}`,
    )
    .then(({ data }) => data);
}

export function createGPTquestionsService(
  fields,
  referenceId,
  chapterId,
  assignmentId,
) {
  if (referenceId && chapterId && assignmentId) {
    fields.referenceId = referenceId;
    fields.chapterId = chapterId;
    fields.assignmentId = assignmentId;
  }
  let body = fields;
  if (fields.newPdfFile) {
    body = setFormData(fields);
  }
  return axios
    .post(`${baseUrl}/question/createGPTquestions`, body, { timeout: 180000 })
    .then(({ data }) => data);
}

export function submitSummaryService(
  fields,
  referenceId,
  chapterId,
  assignmentId,
) {
  fields.referenceId = referenceId;
  fields.chapterId = chapterId;
  fields.assignmentId = assignmentId;
  let body = fields;
  if (fields.newPdfFile) {
    body = setFormData(fields);
  }
  return axios.post(`${baseUrl}/submitSummary`, body).then(({ data }) => data);
}

export function getBibliograpgyLogs(ratingExam, dutyState) {
  return axios
    .get(
      `${baseUrl}/bibliographyhistory?ratingExam=${ratingExam}&dutyState=${dutyState} `,
    )
    .then(({ data }) => data);
}

export function fetchAdminLogs(admin, page = 0) {
  return axios
    .get(`${baseUrl}/adminuser/${admin}/getLogs?${getQueryString(page)}`)
    .then(({ data }) => data);
}

export function getAssigments(admin, status, studyprogram) {
  return axios
    .get(
      `${baseUrl}/assignment/?adminuser=${admin}&status=${status}&typeId=${studyprogram.typeId}&type=${studyprogram.type}`,
    )
    .then(({ data }) => data);
}

export function getTopicsForRate(id, page) {
  // return axios.get(`${baseUrl}/rate/${id}/topics?populate=subtopics`);
  if (!id) {
    return;
  }
  const parameters =
    page === 'Bibliographies' ? `rate=${id}&questionQty=true` : `rate=${id}`;
  return axios.get(`${baseUrl}/topic?${parameters}`);
}

export function getTopicsByReference(id) {
  return axios.get(`${baseUrl}/topic?reference=${id}`);
}

export function getTopicsBySP(id) {
  return axios.get(`${baseUrl}/topic?sp=${id}`);
}

export function getSubtopicsByRate(rate) {
  return axios
    .get(`${baseUrl}/rate/${rate}/subtopics`)
    .then(({ data }) => data);
}

export function updateChapterSubtopics(reference, chapter, checkedSubtopics) {
  return axios
    .put(`${baseUrl}/reference/${reference}/chapter/${chapter}/subtopics`, {
      subtopics: checkedSubtopics,
    })
    .then(({ data }) => data);
}

export function createTopic(rateId, name) {
  return axios
    .post(`${baseUrl}/topic`, { rate: rateId, name })
    .then(({ data }) => data);
}

export function createSubtopic(topicId, name) {
  return axios
    .post(`${baseUrl}/topic/${topicId}/subtopics`, { name })
    .then(({ data }) => data);
}

export function createRate(fields) {
  const route = fields.isQual ? `${baseUrl}/qual/` : `${baseUrl}/rate/`;
  return axios.post(route, fields).then(({ data }) => data);
}

export function deleteSP(sp) {
  const route =
    sp.type === 'qual'
      ? `${baseUrl}/qual/${sp.id}`
      : `${baseUrl}/rate/${sp.id}`;
  return axios.delete(route).then(({ data }) => data);
}

export function updateSP(fields) {
  const route = fields.isQual
    ? `${baseUrl}/qual/${fields.id}`
    : `${baseUrl}/rate/${fields.id}`;
  return axios.patch(route, fields).then(({ data }) => data);
}

export function updateTopic(id, name) {
  return axios
    .patch(`${baseUrl}/topic/${id}`, { name })
    .then(({ data }) => data);
}

export function updateSubtopic(id, name) {
  return axios
    .patch(`${baseUrl}/subtopic/${id}`, { name })
    .then(({ data }) => data);
}

export function updateSubtopicChapters(id, chapters) {
  return axios
    .patch(`${baseUrl}/subtopic/${id}`, { chapters })
    .then(({ data }) => data);
}

export function onEditAssignment(assignment) {
  return axios
    .patch(`${baseUrl}/assignment/${assignment.id}`, assignment)
    .then(({ data }) => data);
}

export function deleteTopic(id) {
  return axios.delete(`${baseUrl}/topic/${id}`).then(({ data }) => data);
}

export function deleteAllChapterQuestions(chapter, assignment) {
  const assignmentId = assignment ? assignment.id : null;
  return axios
    .delete(`${baseUrl}/question/chapter/${chapter.id}`, {
      data: { assignmentId },
    })
    .then(({ data }) => data);
}

export function deleteSubtopic(id) {
  return axios.delete(`${baseUrl}/subtopic/${id}`).then(({ data }) => data);
}

export function getDefinitionsByReference(reference) {
  return axios
    .get(`${baseUrl}/definition?reference=${reference}`)
    .then(({ data }) => data);
}

export function getAllChaptersByRate(rate) {
  return axios
    .get(`${baseUrl}/chapter/findByRate/${rate}`)
    .then(({ data }) => data);
}

export function resetUserQuestions(userId) {
  return axios
    .post(`${baseUrl}/user/${userId}/resetQuestions`)
    .then(({ data }) => data);
}

export function loadUserPracticeExams(userId) {
  return axios
    .get(`${baseUrl}/adminuser/getUserPracticeExams/${userId}`)
    .then(({ data }) => data);
}

export function resetPracticeExam(practiceExamId, userId) {
  return axios
    .post(`${baseUrl}/adminuser/resetPracticeExam`, {
      practiceExamId,
      userId,
    })
    .then(({ data }) => data);
}

export function loadCoupons() {
  return axios.get(`${baseUrl}/coupon`).then(({ data }) => data);
}

export function loadCodeGroups(id) {
  return axios
    .get(`${baseUrl}/coupon/${id}/findGroups`)
    .then(({ data }) => data);
}

export function loadNewCoupons() {
  return axios.get(`${baseUrl}/coupon/getNewCoupons`).then(({ data }) => data);
}

export function getCodesFromCodeGroup(id, fromValue, toValue) {
  return axios
    .get(
      `${baseUrl}/coupon/couponCode/${id}?skip=${fromValue}&limit=${toValue}`,
    )
    .then(({ data }) => data);
}

export function getRedeemedCodes(id) {
  return axios
    .get(`${baseUrl}/codeGroup/${id}/redeemed`)
    .then(({ data }) => data);
}

export function createNewCouponService(coupon) {
  return axios
    .post(`${baseUrl}/coupon/createNewCoupons`, coupon)
    .then(({ data }) => data);
}

export function disableNewCouponCouponService(coupon, value) {
  return axios
    .post(`${baseUrl}/coupon/disableNewCoupon`, {
      coupon,
      value,
    })
    .then(({ data }) => data);
}

export function deleteNewCouponCouponService(id, value) {
  return axios
    .delete(`${baseUrl}/coupon/deleteNewCoupon/${id}`)
    .then(({ data }) => data);
}

export function editCoupon(coupon) {
  return axios
    .patch(`${baseUrl}/coupon/${coupon.id}/`, coupon)
    .then(({ data }) => data);
}

export function getCouponsReferral() {
  return axios
    .get(`${baseUrl}/coupon/getCouponsReferral`)
    .then(({ data }) => data);
}

export function createCouponReferral(couponData) {
  return axios
    .patch(`${baseUrl}/coupon/createCouponReferral`, couponData)
    .then(({ data }) => data);
}

export function insertCouponReferral(couponData) {
  return axios
    .patch(`${baseUrl}/coupon/insertCouponReferral`, couponData)
    .then(({ data }) => data);
}

export function createCodeGroup(codeGroup) {
  return axios
    .post(`${baseUrl}/coupon/${codeGroup.coupon}/createGroup`, codeGroup)
    .then(({ data }) => data);
}

export function cancelAllCodeGroupCodes(codeGroup) {
  return axios
    .patch(`${baseUrl}/coupon/codeGroup/${codeGroup}/cancelAll`)
    .then(({ data }) => data);
}

export function cancelCode(code) {
  return axios
    .patch(`${baseUrl}/coupon/couponCode/${code}/cancel`)
    .then(({ data }) => data);
}

export function editCodeGroup(codeGroup) {
  return axios
    .patch(`${baseUrl}/coupon/codeGroup/${codeGroup.id}`, codeGroup)
    .then(({ data }) => data);
}

export function getMyAssignments() {
  return axios
    .get(`${baseUrl}/rate/findWithMyAssignments`)
    .then(({ data }) => data);
}

export function loadSubscriptionType(student) {
  return axios
    .get(`${baseUrl}/student/getSubscriptionType/${student.id}`)
    .then(({ data }) => data);
}

export function getDefinitionsByQuestion(question) {
  return axios
    .get(`${baseUrl}/definition?question=${question}`)
    .then(({ data }) => data);
}

export function getAllQuals(page) {
  if (page) {
    const queryString = getQueryString(page);
    return axios.get(`${baseUrl}/qual?sort=name ASC&${queryString}`);
  }
  return axios.get(`${baseUrl}/qual?sort=name ASC`).then(({ data }) => data);
}

export function removeDefinitions(id, assignment) {
  return axios.delete(`${baseUrl}/definition/${id}`).then(({ data }) => data);
}

export function editDefinitions(item, assignment) {
  return axios
    .patch(`${baseUrl}/definition/${item.id}`, item)
    .then(({ data }) => data);
}

export function createDefinitions(fields, assignment) {
  return axios.post(`${baseUrl}/definition`, fields).then(({ data }) => data);
}

export function getRoles() {
  return axios.get(endpoints.roles).then(({ data }) => data);
}

export function getAllRoles() {
  return axios.get(endpoints.roles).then(({ data }) => data);
}
export function changeMainRole(id, role) {
  return axios
    .patch(`${endpoints.admins}/${id}`, role)
    .then(({ data }) => data)
    .catch((error) => {
      if (error.response) {
        throw new Error(error.response.data.err);
      }
      throw new Error('Server error');
    });
}

export function getRolesForRates() {
  return axios
    .get(`${baseUrl}/role?isStudyProgramRole=true`)
    .then(({ data }) => data);
}

export function getGroupsForStudyProgram(type, typeId) {
  return axios
    .get(`${baseUrl}/admingroup?type=${type}&typeId=${typeId}`)
    .then(({ data }) => data);
}

export function deleteGroup(groupId) {
  return axios
    .delete(`${baseUrl}/admingroup/${groupId}`)
    .then(({ data }) => data);
}

export function createGroup(adminId, studyProgramId, roleId, type) {
  return axios
    .post(`${baseUrl}/admingroup`, {
      role: roleId,
      typeId: studyProgramId,
      type,
      adminUser: adminId,
    })
    .then(({ data }) => data);
}

export function getPages() {
  return axios
    .get(`${endpoints.getPages}?sort=name ASC&skip=${0}&limit=${1000}`)
    .then(({ data }) => data)
    .then((resp) => resp.data);
}

export function getWorkflowActions() {
  return axios
    .get(`${endpoints.getWorkflowActions}?sort=name DESC`)
    .then(({ data }) => data)
    .then((resp) => resp.data);
}

export function createRole(role) {
  return axios.post(endpoints.roles, role).then(({ data }) => data);
}

export function createQuestion(question, assignment) {
  const assignmentQuery = assignment ? `?assignmentId=${assignment}` : '';
  const test = setFormData(question);
  return axios
    .post(`${baseUrl}/question${assignmentQuery}`, test)
    .then(({ data }) => data);
}

export async function batchCreateQuestions(questions, assignment) {
  const assignmentQuery = assignment ? `?assignmentId=${assignment}` : '';
  return axios
    .post(`${baseUrl}/question/batchCreate${assignmentQuery}`, { questions })
    .then(({ data }) => data);
}

export function editRole(
  id,
  name,
  pagePermissions,
  workflowActions,
  showGroup,
) {
  return axios
    .patch(`${endpoints.roles}/${id}`, {
      name,
      pagePermissions,
      workflowActions,
      showGroup,
    })
    .then(({ data }) => data);
}

export function deleteRole(id) {
  return axios.delete(`${endpoints.roles}/${id}`).then(({ data }) => data);
}

export function getReferencesForStudyProgram(id, type) {
  return axios
    .get(`${baseUrl}/reference?${type}=${id}&archived=false`)
    .then(({ data }) => data);
}

export function getAllReferences(
  search,
  page = 0,
  rate,
  amountOfReferences,
  barFilter,
  duplicateChapter,
) {
  const queryString = getInfiniteQueryStringRefs(
    page,
    search,
    pageSize,
    0,
    rate,
    amountOfReferences,
    barFilter,
    duplicateChapter,
  );
  // }
  return axios
    .get(`${baseUrl}/reference?${queryString}`)
    .then(({ data }) => data);
}

export function getReferencePdfPrivate(payload) {
  return axios
    .get(`${baseUrl}/reference/getReferencePdfPrivate`, {
      params: {
        url: payload.url,
      },
    })
    .then(({ data }) => data);
}

export function getQualReferences(id, archived) {
  const query = archived ? '&archived=true' : '';
  return axios
    .get(`${baseUrl}/reference?qual=${id}${query}`)
    .then(({ data }) => data);
}

export function getChapter(chapter) {
  return axios.get(`${baseUrl}/chapter/${chapter}/`).then(({ data }) => data);
}

export function createReference(fields) {
  return axios
    .post(`${baseUrl}/reference`, setFormData(fields))
    .then(({ data }) => {
      // If this is a qual, update the studyPrograms for the selected qual
      if (fields.selectedQual) {
        return updateStudyProgramsReferenceByQual(
          { qualId: fields.selectedQual.id },
          data.id,
        ).then((updatedData) => updatedData);
      }
      return data;
    });
}

export function createChapter(fields) {
  return axios
    .post(
      `${baseUrl}/reference/${fields.reference}/chapters`,
      setFormData(fields),
    )
    .then(({ data }) => data);
}

export function submitBibliography(bibliography) {
  return axios
    .patch(`${baseUrl}/exam/updateBibliography`, bibliography)
    .then(({ data }) => data);
}

export function getCycles() {
  return axios.get(`${baseUrl}/cycle`);
}

export function findCyclesByUser(queryCycles) {
  return axios.get(`${baseUrl}/student/cycle/findCyclesByUser?${queryCycles}`);
}

export function getUserStatus(info) {
  return axios.get(`${baseUrl}/student/getUserStatus?${info}`);
}

export function getArchivedCycles() {
  return axios.get(`${baseUrl}/cycle/archived`);
}

export function createCycle(cycle) {
  return axios.post(`${baseUrl}/cycle`, cycle).then(({ data }) => data);
}

export function archiveCycle(cycle) {
  return axios
    .put(`${baseUrl}/cycle/${cycle.id}/archive/`)
    .then(({ data }) => data);
}

export function updateCycle(cycle) {
  return axios
    .patch(`${baseUrl}/cycle/${cycle.id}`, cycle)
    .then(({ data }) => data);
}

export function deleteCycle(id) {
  return axios.delete(`${baseUrl}/cycle/${id}`).then(({ data }) => data);
}

export function activateCycle(id) {
  return axios.put(`${baseUrl}/cycle/${id}/activate`).then(({ data }) => data);
}

export function moveSubstitutes(id) {
  return axios
    .put(`${baseUrl}/cycle/${id}/updateCycleForSubstitutes`)
    .then(({ data }) => data);
}

export async function moveSelectedRates(id, rates) {
  return axios
    .put(`${baseUrl}/cycle/${id}/updateCycleForRates`, {
      cycleId: id,
      rates,
    })
    .then(({ data }) => data);
}

export function toggleAdminRole(id, role) {
  return axios
    .patch(`${baseUrl}/adminuser/${id}/toggleRole`, role)
    .then(({ data }) => data);
}

export function loadExam(exam) {
  return axios
    .get(
      `${baseUrl}/exam/findone/${exam.cycle}/${exam.rank}/${exam.dutyState}/${exam.rate}`,
    )
    .then(({ data }) => data);
}

export function updateReference(fields, activeFilter) {
  if (!fields.pdf && !fields.pdfUrl) {
    fields.pdfUrl = '';
  }

  if (activeFilter) {
    fields.rate = activeFilter.rate;
    fields.rank = activeFilter.rank;
    fields.dutystate = activeFilter.dutystate;
    fields.book_status = activeFilter.book_status;
  }
  const body = setFormData(fields);

  return axios
    .patch(`${baseUrl}/reference/${fields.id}`, body)
    .then(({ data }) => data);
}

export function deleteReference(reference) {
  return axios
    .delete(`${baseUrl}/reference/${reference.id}`)
    .then(({ data }) => data);
}

export function loadUnredeemedCodes(codeGroup) {
  return axios
    .get(`${baseUrl}/codeGroup/${codeGroup}/unredeemed`)
    .then(({ data }) => data);
}

export function deletePdfReference(id) {
  return axios
    .delete(`${baseUrl}/reference/${id}/pdf`)
    .then(({ data }) => data);
}

export function deletePdfChapter(id) {
  return axios.delete(`${baseUrl}/chapter/${id}/pdf`).then(({ data }) => data);
}

export function copyReference(fields) {
  return axios
    .post(
      `${baseUrl}/reference/?sourceId=${fields.sourceId}&copyPdf=${fields.copyPdf}&typeId=${fields.studyProgramId}&type=${fields.type}`,
    )
    .then(({ data }) => data);
}

export function getWriters(type, typeId) {
  return axios
    .get(`${baseUrl}/studyprogram/writers?type=${type}&typeId=${typeId}`)
    .then(({ data }) => data);
}

export function getAllWriters() {
  return axios
    .get(`${baseUrl}/studyprogram/allWriters`)
    .then(({ data }) => data);
}

// Notifications Message
export function getAllRatesNotifications() {
  return axios
    .get(`${baseUrl}/adminuser/getRatesNotifications`)
    .then(({ data }) => data);
}

export function getNotificationByRate(rateId) {
  return axios
    .get(`${baseUrl}/adminuser/getNotificationByRate?rateId=${rateId}`)
    .then(({ data }) => data);
}

export function createNotificationService(notification) {
  return axios
    .post(`${baseUrl}/adminuser/createNotification`, {
      notification,
    })
    .then(({ data }) => data);
}

export function deleteNotification(notificationId) {
  return axios
    .delete(
      `${baseUrl}/adminuser/deleteNotification?notificationId=${notificationId}`,
    )
    .then(({ data }) => data);
}

export function editNotification(notificationId, notificationValue) {
  return axios
    .post(
      `${baseUrl}/adminuser/editNotification?notificationId=${notificationId}`,
      notificationValue,
    )
    .then(({ data }) => data);
}
// Filtered from here
// ToDo: JR - add search to filtered queries
export function getAllForRate(filter) {
  const viewDeleted = filter.isViewDeleted ? '&isViewDeleted=true' : '';
  return axios
    .get(
      `${baseUrl}/reference?rate=${filter.rate}&allForRate=true${viewDeleted}`,
    )
    .then(({ data }) => data);
}

export function getAssignedToBibReferences(filter) {
  return axios
    .get(
      `${baseUrl}/reference?rate=${filter.rate}&rank=${filter.rank}&dutyState=${
        filter.dutyState
      }&assigned=true${filter.search ? `&search=${filter.search}` : ''}`,
    )
    .then(({ data }) => data);
}

export function getAllAssignedToBib(filter) {
  return axios
    .get(`${baseUrl}/reference?rate=${filter.rate}&assigned=true`)
    .then(({ data }) => data);
}

export function getChapterQuestionsCSV(
  chapter,
  isAssignment,
  skip = 0,
  limit = 10,
) {
  return axios
    .get(`${baseUrl}/chapter/${chapter}/getQuestionsCSV`, {
      params: { isAssignment, skip, limit },
    })
    .then(({ data }) => data);
}

export function getNamesToBuildSecondBar(letter) {
  return axios
    .get(`${baseUrl}/reference/${letter}/getNamesToBuildSecondBar`)
    .then(({ data }) => data);
}

export function loadRateBibliographyJson(rate) {
  return axios
    .get(`${baseUrl}/exam/${rate}/getBibliographyCSVJson`)
    .then(({ data }) => data);
}

export function getBibliographyReportForGivenRates(
  rates,
  ratingExams,
  multiple,
) {
  return axios
    .post(
      `${baseUrl}/exam/${JSON.stringify(
        rates,
      )}/getBibliographyReportForGivenRates?multiple=${multiple}`,
      ratingExams,
    )
    .then(({ data }) => data);
}

export function getLatestTopicsOrExamSubtopicsReport(isExamReport) {
  return axios
    .get(
      `${baseUrl}/report/${isExamReport ? 'examSubtopics' : 'topics'}/latest`,
    )
    .then(({ data }) => data);
}

export function generateTopicsOrExamSubtopicsReport(
  rates,
  multiple,
  isExamReport,
) {
  return axios
    .post(`${baseUrl}/report/${isExamReport ? 'examSubtopics' : 'topics'}`, {
      multiple,
      [isExamReport ? 'examIds' : 'rateIds']: rates,
    })
    .then(({ data }) => data);
}

export function generateFavoriteQuestionsReport(ratesIds, ratingExams) {
  return axios
    .post(`${baseUrl}/report/generateFavoriteQuestionsReport`, {
      ratesIds,
      ratingExams,
    })
    .then(({ data }) => data);
}

export function getFavoriteQuestionsReports() {
  return axios
    .get(`${baseUrl}/report/getFavoriteQuestionsReports`)
    .then(({ data }) => data);
}

export function getProfileSheetReport(cyclesId) {
  return axios
    .post(`${baseUrl}/adminuser/getProfileSheetReport`, {
      cyclesId,
    })
    .then(({ data }) => data);
}

export function loadRateChapterQuestionsReport(rate) {
  return axios
    .get(`${baseUrl}/rate/${rate}/getChapterQuestionsReport`)
    .then(({ data }) => data);
}

export function loadManualOrderChapterQuestionsReport() {
  return axios
    .get(`${baseUrl}/report/getManualOrderReport`)
    .then(({ data }) => data);
}

export function loadBooleanAnswerQuestionsReport() {
  return axios
    .get(`${baseUrl}/report/getBooleanAnswersReport`)
    .then(({ data }) => data);
}

export function pubHuntSelectionsReport() {
  return axios
    .get(`${baseUrl}/report/getPubHuntSelectionsReport`)
    .then(({ data }) => data);
}

export function couponsReport() {
  return axios
    .get(`${baseUrl}/report/getCouponsReport`)
    .then(({ data }) => data);
}

export function userListReport() {
  return axios.get(`${baseUrl}/report/userListReport`).then(({ data }) => data);
}

export function getNotAssignedToBibReferences(filter) {
  return axios
    .get(
      `${baseUrl}/reference?rate=${filter.rate}&assigned=false${
        filter.search ? `&search=${filter.search}` : ''
      }`,
    )
    .then(({ data }) => data);
}

export function getArchivedReferences(filter) {
  return axios
    .get(
      `${baseUrl}/reference?rate=${filter.rate}&archived=true${
        filter.search ? `&search=${filter.search}` : ''
      }`,
    )
    .then(({ data }) => data);
}
// Filtered to here

export function deleteChapter(action) {
  return axios
    .delete(
      `${baseUrl}/reference/${action.reference}/chapter/${action.chapter}`,
    )
    .then(({ data }) => data);
}

export function deleteQuestions(action) {
  return axios
    .delete(`${baseUrl}/chapter/${action.chapter}/question`)
    .then(({ data }) => data);
}

export function updateChapter(fields) {
  let body = fields;
  if (fields.pdf) {
    body = setFormData(fields);
  } else {
    // for pdf file deletion
    if (fields.pdf === '') {
      body.pdfUrl = '';
    }
  }
  return axios
    .patch(
      `${baseUrl}/reference/${fields.reference}/chapters/${fields.id}`,
      body,
    )
    .then(({ data }) => data);
}

export function swapPosition(reference, chapterA, chapterB) {
  return axios
    .patch(`${baseUrl}/reference/${reference}/chapters/swapPosition`, {
      chapterA,
      chapterB,
    })
    .then(({ data }) => data);
}

export function assignWritersByChapter(
  chapter,
  primaryWriter,
  secondaryWriters,
) {
  return axios
    .patch(
      `${baseUrl}/reference/${chapter.reference}/chapters/${chapter.id}/writers`,
      {
        writers: secondaryWriters,
        primaryWriter,
        chapterId: chapter.id,
      },
    )
    .then(({ data }) => data);
}

export function assignWritersByReference(
  reference,
  primaryWriter,
  secondaryWriters,
) {
  return axios
    .patch(`${baseUrl}/reference/${reference.id}/writers`, {
      writers: secondaryWriters,
      primaryWriter,
    })
    .then(({ data }) => data);
}

export function getReference(id) {
  return axios.get(`${baseUrl}/reference/${id}`).then(({ data }) => data);
}

/* FOR search function, new gerReferenceById */

export function getReferenceById(id) {
  return axios
    .get(`${baseUrl}/reference/getReferenceById/${id}`)
    .then(({ data }) => data);
}

export function amountOfQuestionsInChapter(chapter) {
  return axios
    .get(`${baseUrl}/question/amountOfQuestions?chapter=${chapter}`)
    .then(({ data }) => data);
}

export function duplicateChapterService(referenceId, chapterId, rateId) {
  return axios
    .post(`${baseUrl}/chapter/duplicateChapter`, {
      reference: referenceId,
      chapter: chapterId,
      rate: rateId,
    })
    .then(({ data }) => data);
}

export function getQuestionsOfChapter(
  id,
  page,
  rows,
  assignment,
  skip,
  isViewDeletedQuestions,
  loadAll,
  deletedByAdmin,
) {
  let params = assignment
    ? `assignment=${assignment}&chapter=${id}`
    : `chapter=${id}`;
  const viewDeleted = isViewDeletedQuestions ? '&isViewDeleted=true' : '';

  params += `${viewDeleted}&sort=id ASC`;
  if (deletedByAdmin) {
    const deletedByAdminQuery = `&${deletedByAdmin}=true`;
    params += `${deletedByAdminQuery}`;
  }

  if (loadAll) {
    params += `&loadAll=${loadAll}`;
  }
  if (skip !== null) {
    return axios
      .get(
        `${baseUrl}/question?${getQueryString(
          page,
          null,
          rows,
          skip,
        )}&${params}`,
      )
      .then(({ data }) => data);
  }
  return axios
    .get(
      `${baseUrl}/question?${getQueryString(
        page,
        null,
        rows,
        skip,
        '',
        50,
        deletedByAdmin,
      )}&${params}`,
    )
    .then(({ data }) => data);
}

export function getAllQuestionsOfChapter(id) {
  return axios
    .get(`${baseUrl}/chapter/${id}?populateQuestions=true`)
    .then(({ data }) => data);
}

export function loadRateQuestionsPercentage(rate) {
  return axios
    .get(`${baseUrl}/rate/${rate}/findRatePercentage`)
    .then(({ data }) => data);
}

export function updateQuestion(question, assignment) {
  const assignmentQuery = assignment ? `?assignmentId=${assignment}` : '';
  return axios
    .patch(
      `${baseUrl}/question/${question.id}${assignmentQuery}`,
      setFormData(question),
    )
    .then(({ data }) => data);
}

export function getQuestionHistory(questionId) {
  return axios
    .get(`${baseUrl}/getHistoryQuestion/${questionId}`)
    .then(({ data }) => data);
}

export function deleteQuestion(questionsIds, assignment) {
  const assignmentQuery = assignment ? `?assignmentId=${assignment}` : '';
  const requestBody = {
    questionsIds,
  };
  return axios
    .delete(`${baseUrl}/question${assignmentQuery}`, { data: requestBody })
    .then(({ data }) => data);
}

export function deletePdfQuestion(id, assignment) {
  const assignmentQuery = assignment ? `?assignmentId=${assignment}` : '';
  return axios
    .delete(`${baseUrl}/question/${id}/pdf${assignmentQuery}`)
    .then(({ data }) => data);
}

export function uploadFile(fileFormData) {
  return axios
    .post(`${baseUrl}/file/upload`, fileFormData)
    .then(({ data }) => data);
}

export function createAssigment(params) {
  return axios.post(`${baseUrl}/assignment`, params).then(({ data }) => data);
}

export function getChangeHistory(assignment, question) {
  return axios
    .get(`${baseUrl}/question/${question}/history`)
    .then(({ data }) => data);
}

export function getAssignment(assignment) {
  return axios
    .get(`${baseUrl}/assignment/${assignment}`)
    .then(({ data }) => data);
}

export function updateAssignment(params) {
  return axios
    .patch(
      `${baseUrl}/assignment/${params.assignment}/assignAdminUsers`,
      params,
    )
    .then(({ data }) => data);
}

export function deleteAssignment(assignment) {
  return axios
    .delete(`${baseUrl}/assignment/${assignment}`)
    .then(({ data }) => data);
}

export function getQuestion(id) {
  return axios.get(`${baseUrl}/question/${id}/`).then(({ data }) => data);
}

export function restoreQuestion(questionsIds, assignment) {
  const assignmentQuery = assignment ? `?assignmentId=${assignment}` : '';
  const requestBody = {
    questionsIds,
  };
  return axios
    .patch(`${baseUrl}/question/restore${assignmentQuery}`, requestBody)
    .then(({ data }) => data);
}

export function consultDelete(id) {
  return axios
    .get(`${baseUrl}/admingroup/${id}/canRemoveAdmin`)
    .then(({ data }) => data);
}

export function updatePrimaryWriterForAssignment(groupId, writer) {
  return axios.delete(`${baseUrl}/admingroup/${groupId}?newAdmin=${writer}`);
}

export function getAssigmentsCounts(admin, typeId, type) {
  return axios
    .get(
      `${baseUrl}/assignment/${admin}/getCountByStatus?typeId=${typeId}&type=${type}`,
    )
    .then(({ data }) => data);
}

export function getAwaitingApproval(user) {
  return axios
    .get(`${baseUrl}/assignment/findAwaitingApproval/`)
    .then(({ data }) => data);
}

export function getSettings() {
  return axios.get(`${baseUrl}/systemsettings/`).then(({ data }) => data);
}

export function updateReferralDates(dates) {
  return axios
    .post(`${baseUrl}/updateReferralDates`, dates)
    .then(({ data }) => data);
}

export function updateKey(key) {
  return axios
    .patch(`${baseUrl}/systemsettings/${key.id}`, key)
    .then(({ data }) => data);
}

export function getRatesForStudents() {
  return axios
    .get(`${baseUrl}/student/rate?includePMK=true`)
    .then(({ data }) => data);
}

export function getExamsForStudents() {
  return axios.get(`${baseUrl}/student/exam`).then(({ data }) => data);
}

export function createStudent(student) {
  return axios.post(`${baseUrl}/student/`, student).then(({ data }) => data);
}

export function loadPlans() {
  return axios
    .get(`${baseUrl}/student/subscription/plans`)
    .then(({ data }) => data);
}

export function getUser(id) {
  return axios.get(`${baseUrl}/user/${id}`).then(({ data }) => data);
}

export function getUsersCount(query) {
  return axios
    .get(`${baseUrl}/user/usersCount`, {
      params: query,
    })
    .then(({ data }) => data);
}

export function updateStudentSubscription(sub) {
  return axios
    .patch(`${baseUrl}/subscription/${sub.id}`, sub)
    .then(({ data }) => data);
}

export function getUserVerificationService(userId) {
  return axios
    .get(`${baseUrl}/adminuser/getUserVerification`, {
      params: { userId },
    })
    .then(({ data }) => data);
}

export function setUserVerificationService(userId, customer) {
  return axios
    .post(`${baseUrl}/student/userVerification`, {
      userId,
      customer,
    })
    .then(({ data }) => data);
}

export function loadStatistics(user, page, filter) {
  const queryString = getQueryString(page);
  return axios
    .post(`${baseUrl}/userstatistics/${user}?${queryString}`, filter)
    .then(({ data }) => data);
}

export function loginAsAdmin(admin) {
  return axios
    .post(`${baseUrl}/adminuser/${admin}/loginAsAdmin`)
    .then(({ data }) => data);
}

export function loginAsStudent(student, platformType, adminUserId) {
  return axios
    .post(`${baseUrl}/student/${student}/loginAsStudent`, {
      platformType,
      adminUserId,
    })
    .then(({ data }) => data);
}

export function getUserNotes(userId) {
  return axios
    .get(`${baseUrl}/student/getUserNotes`, {
      params: { userId },
    })
    .then(({ data }) => data);
}

export function getLastCouponUser(userId) {
  return axios
    .get(`${baseUrl}/adminuser/getLastCouponUser`, {
      params: { userId },
    })
    .then(({ data }) => data);
}

export function getUserCancellationReason(userId) {
  return axios
    .get(`${baseUrl}/student/cancellationReason?userId=${userId}`)
    .then(({ data }) => data);
}

export function updateCancellationReason(userId, cancelledReason) {
  return axios
    .patch(`${baseUrl}/student/cancellationReason?userId=${userId}`, {
      cancelledReason,
    })
    .then(({ data }) => data);
}

export function updateDoNotContact(payload) {
  return axios
    .post(`${baseUrl}/student/updateDoNotContact`, payload)
    .then(({ data }) => data);
}

export function disableCycleAdmin(cycleId, value) {
  return axios
    .post(`${baseUrl}/student/disableCycleAdmin`, {
      cycleId,
      value,
    })
    .then(({ data }) => data);
}

export function getIntercomCancellationReason(userId) {
  return axios
    .get(`${baseUrl}/student/getIntercomCancellationReason`, {
      params: { userId },
    })
    .then(({ data }) => data);
}

export function updateIntercomCancellation(payload) {
  return axios
    .post(`${baseUrl}/student/updateIntercomCancellation`, payload)
    .then(({ data }) => data);
}

export function updateEducationalInstitutionData(
  userId,
  educationalInstitution,
) {
  return axios
    .post(`${baseUrl}/student/updateEducationalInstitutionData`, {
      userId,
      educationInstitutionDate: educationalInstitution,
    })
    .then(({ data }) => data);
}

export function getUserEducationalInstitutionData(userId) {
  return axios
    .get(`${baseUrl}/student/getEducationalInstitutionData`, {
      params: { userId },
    })
    .then(({ data }) => data);
}

export function getAllStudyPrograms() {
  return axios.get(`${baseUrl}/studyProgram`).then(({ data }) => data);
}

export function updateStudyProgramsReference(studyPrograms, reference) {
  return axios
    .patch(
      `${baseUrl}/reference/${reference}/updateStudyProgrmas`,
      studyPrograms,
    )
    .then(({ data }) => data);
}

export function updateStudyProgramsReferenceByQual(studyPrograms, reference) {
  return axios
    .patch(
      `${baseUrl}/reference/${reference}/updateStudyProgramByQual`,
      studyPrograms,
    )
    .then(({ data }) => data);
}

export function getUserFilters(user) {
  return axios.get(`${baseUrl}/adminuser/${user}`).then(({ data }) => data);
}

export function createFilter(filter) {
  return axios
    .post(`${baseUrl}/adminuserfilter`, filter)
    .then(({ data }) => data);
}

export function updateFilter(filter) {
  return axios
    .patch(`${baseUrl}/adminuserfilter/${filter.id}`, filter)
    .then(({ data }) => data);
}

export function setActiveFilter(filter) {
  return axios
    .patch(`${baseUrl}/adminuserfilter/setActiveFilter/${filter.id}`, filter)
    .then(({ data }) => data);
}

export function deleteFilter(id) {
  return axios
    .delete(`${baseUrl}/adminuserfilter/${id}`)
    .then(({ data }) => data);
}

export function getRateById(rateId) {
  return axios.get(`${baseUrl}/rate/${rateId}`).then(({ data }) => data);
}

export function loadStudyPlans() {
  return axios.get(`${baseUrl}/studyPlan/`).then(({ data }) => data);
}

export function createStudyPlan(studyPlan) {
  return axios.post(`${baseUrl}/studyPlan`, studyPlan).then(({ data }) => data);
}

export function editStudyPlan(studyPlan, title, isActive) {
  return axios
    .patch(`${baseUrl}/studyPlan/${studyPlan}`, {
      title,
      isActive,
    })
    .then(({ data }) => data);
}

export function updateStartDate(
  studyPlan,
  rank,
  dutyState,
  date,
  isApplicable,
) {
  return axios
    .post(`${baseUrl}/studyPlanDate`, {
      studyPlan,
      rank,
      dutyState,
      date,
      isApplicable,
    })
    .then(({ data }) => data);
}

export function updateStudyPlanEmail(subject, content, emailId) {
  return axios
    .patch(`${baseUrl}/studyPlanEmail/${emailId}`, {
      subject,
      body: content,
    })
    .then(({ data }) => data);
}

export function loadStudyPlansUsers(search, page = 0) {
  let queryString;
  if (search) {
    queryString = getQueryString(page, search);
  } else {
    queryString = getInfiniteQueryString(page, search);
  }
  return axios
    .get(`${baseUrl}/studyPlanUser/?${queryString}`)
    .then(({ data }) => data);
}

export function updateStudyPlanUser(
  rate,
  dutyState,
  rank,
  isSubscribed,
  userId,
) {
  return axios
    .patch(`${baseUrl}/studyPlanUser/${userId}`, {
      rate,
      dutyState,
      rank,
      isSubscribed,
    })
    .then(({ data }) => data);
}

export function deleteStudyPlanUser(userId) {
  return axios
    .delete(`${baseUrl}/studyPlanUser/${userId}`)
    .then(({ data }) => data);
}

export function sendPlanPreview(
  studyPlan,
  rate,
  rank,
  dutyState,
  week,
  sendTo,
) {
  return axios
    .post(`${baseUrl}/studyPlanEmail/sendTestEmail`, {
      sendTo,
      dutyState,
      rank,
      rate,
      week,
      studyPlan,
    })
    .then(({ data }) => data);
}

export function loadPracticeExamsData(rate, rank, dutyState) {
  return axios
    .post(`${baseUrl}/adminuser/loadPracticeExamsData`, {
      rate,
      rank,
      dutyState,
    })
    .then(({ data }) => data);
}

export function buildPracticeExam(practiceExamId) {
  return axios
    .post(`${baseUrl}/adminuser/buildPracticeExam`, {
      practiceExamId,
    })
    .then(({ data }) => data);
}

export function buildAllPracticeExams(ratingExamList) {
  axios.post(`${baseUrl}/adminuser/buildAllPracticeExams`, {
    ratingExamList,
  });
  return 'ok';
}
export function checkBuildPracticeExamsProgress() {
  return axios
    .get(`${baseUrl}/adminuser/checkBuildPracticeExamsProgress`)
    .then(({ data }) => data);
}

export function removePracticeExamQuestion(practiceExamId, questionId) {
  return axios
    .post(`${baseUrl}/adminuser/removePracticeExamQuestion`, {
      practiceExamId,
      questionId,
    })
    .then(({ data }) => data);
}

export function activatePracticeExam(practiceExamId) {
  return axios
    .post(`${baseUrl}/adminuser/activatePracticeExam`, {
      practiceExamId,
    })
    .then(({ data }) => data);
}

export function makeAdvancedBooksSearch(query, separatedTags, count) {
  return axios
    .post(`${baseUrl}/searchBooks/`, {
      query,
      count,
      tags: separatedTags,
    })
    .then(({ data }) => data);
}

export function makeAdvancedChaptersSearch(query, separatedTags, count) {
  return axios
    .post(`${baseUrl}/searchChapters/`, {
      query,
      count,
      tags: separatedTags,
    })
    .then(({ data }) => data);
}

export function makeAdvancedQuestionsSearch(query, separatedTags, count) {
  return axios
    .post(`${baseUrl}/searchQuestions/`, {
      query,
      count,
      tags: separatedTags,
    })
    .then(({ data }) => data);
}

export function getHistoricalUsersReport(
  lastCustomer,
  startingDate,
  endingDate,
  lessCustomers,
) {
  return axios
    .post(`${baseUrl}/subscription/generateHistoricalUserReportStripe`, {
      lastCustomer,
      startingDate,
      endingDate,
      lessCustomers,
    })
    .then(({ data }) => data);
}

export function getIntercomActiveUsersReportService(lastCustomerSubscription) {
  return axios
    .post(`${baseUrl}/subscription/getIntercomActiveSubscriptions`, {
      lastCustomerSubscription,
    })
    .then(({ data }) => data);
}

export function updateSuperAdmin(admin, value) {
  return axios
    .post(`${baseUrl}/adminuser/${admin}/updateSuperAdmin`, {
      value,
    })
    .then(({ data }) => data);
}

export function getQuestionsToDownload(chapter) {
  return axios
    .post(`${baseUrl}/adminuser/getQuestionsToDownload`, {
      chapter,
    })
    .then(({ data }) => data);
}

export function getNumberOfQuestionsEnabledPerRatingExamReport(lastRate) {
  return axios
    .get(
      `${baseUrl}/report/getNumberOfQuestionPerRatingExamReport?lastRate=${lastRate}`,
    )
    .then(({ data }) => data);
}

export function getAllMasterUserReport() {
  return axios
    .post(`${baseUrl}/adminuser/getAllMasterUserReport`)
    .then(({ data }) => data);
}

export function getAllActiveSubscriptionsReport() {
  return axios
    .post(`${baseUrl}/adminuser/getAllActiveSubscriptionsReport`)
    .then(({ data }) => data);
}

export function getCycleInformation(userId) {
  return axios
    .get(`${baseUrl}/adminuser/getCycleInformation`, {
      params: { userId },
    })
    .then(({ data }) => data);
}

export async function loadApplicableCoupons(userId) {
  return axios
    .get(`${baseUrl}/coupons/applicable?userId=${userId}`)
    .then(({ data }) => data);
}

export async function getAllBannersService() {
  return axios
    .get(`${baseUrl}/adminuser/getAllBanners`)
    .then(({ data }) => data);
}
export async function getBannerService(bannerId) {
  return axios
    .get(`${baseUrl}/adminuser/getBanner?bannerId=${bannerId}`)
    .then(({ data }) => data);
}

export async function setBannerService(banner) {
  return axios
    .post(`${baseUrl}/adminuser/setBanner`, { banner })
    .then(({ data }) => data);
}

export async function deleteBannerService(bannerId) {
  return axios
    .delete(`${baseUrl}/adminuser/deleteBanner?bannerId=${bannerId}`)
    .then(({ data }) => data);
}

export async function setBannerActiveService(bannerId, value) {
  return axios
    .post(`${baseUrl}/adminuser/setBannerActive`, {
      bannerId,
      value,
    })
    .then(({ data }) => data);
}

export async function createBannerService(banner) {
  return axios
    .post(`${baseUrl}/adminuser/createBanner`, { banner })
    .then(({ data }) => data);
}

export async function editNewCoupon(updatedCoupon, couponName) {
  return axios
    .post(`${baseUrl}/coupons/edit?couponName=${couponName}`, updatedCoupon)
    .then(({ data }) => data);
}

export async function loadSubcodes(couponName) {
  return axios
    .get(`${baseUrl}/coupons/subcodes?couponName=${couponName}`)
    .then(({ data }) => data);
}

export async function createSubcodes(couponName) {
  return axios
    .post(`${baseUrl}/coupons/subcodes?couponName=${couponName}`)
    .then(({ data }) => data);
}

export async function getAllPromptsService() {
  return axios
    .get(`${baseUrl}/adminuser/getAllPrompts`)
    .then(({ data }) => data);
}
export async function getPromptService(promptId) {
  return axios
    .get(`${baseUrl}/adminuser/getPrompt?promptId=${promptId}`)
    .then(({ data }) => data);
}

export async function setPromptService(prompt) {
  return axios
    .post(`${baseUrl}/adminuser/setPrompt`, { prompt })
    .then(({ data }) => data);
}

export async function deletePromptService(promptId) {
  return axios
    .delete(`${baseUrl}/adminuser/deletePrompt?promptId=${promptId}`)
    .then(({ data }) => data);
}

export async function setPromptActiveService(promptId, value) {
  return axios
    .post(`${baseUrl}/adminuser/setPromptActive`, {
      promptId,
      value,
    })
    .then(({ data }) => data);
}

export async function createPromptService(prompt) {
  return axios
    .post(`${baseUrl}/adminuser/createPrompt`, { prompt })
    .then(({ data }) => data);
}

export async function getAiTaskByChapterService(chapterId) {
  return axios
    .get(`${baseUrl}/aiTask/getLastTask/${chapterId}`)
    .then(({ data }) => data);
}

export function createCustomPrompt(prompt, chapterId) {
  return axios
    .post(`${baseUrl}/aiPrompts/create`, { prompt, chapterId })
    .then(({ data }) => data);
}

export function getUserCustomPrompt(chapterId) {
  return axios
    .get(`${baseUrl}/aiPrompts/getUserCustomPrompt/${chapterId}`)
    .then(({ data }) => data);
}
export async function deleteAiTaskService(taskId) {
  return axios
    .delete(`${baseUrl}/aiTask/deleteTask/${taskId}`)
    .then(({ data }) => data);
}

export function improveQuestionWithAi(questionId, chapterId) {
  return axios
    .post(`${baseUrl}/question/getAiSuggestion`, { questionId, chapterId })
    .then(({ data }) => data);
}

export function updateIncludeNotesInSuggestion(questionId, value) {
  return axios
    .patch(`${baseUrl}/question/updateIncludeNotesInSuggestion/${questionId}`, {
      value,
    })
    .then(({ data }) => data);
}

export async function setUserSubmissionBlockStatusService(payload) {
  const { userId, status } = payload;
  return axios
    .post(`${baseUrl}/student/updateBlockSubmitQuestion`, {
      userId: Number(userId),
      block: status,
    })
    .then(({ data }) => data);
}

export async function setCheckBetaUserService(userId, status) {
  return axios
    .post(`${baseUrl}/student/updateBetaUser`, {
      userId: Number(userId),
      status,
    })
    .then(({ data }) => data);
}

export async function getBetaUserService(userId) {
  return axios
    .get(`${baseUrl}/student/getBetaUser/${userId}`)
    .then(({ data }) => data);
}

export async function getUserSubscriptionsHistory(userId) {
  return axios
    .get(`${baseUrl}/admin/userSubscriptionsHistory/${userId}`)
    .then(({ data }) => data);
}

export async function setUserFavoritesBlockStatusService(payload) {
  const { userId, status } = payload;
  return axios.post(`${baseUrl}/student/blockUserFavoriteQuestion`, {
    userId,
    block: status,
  });
}

export async function getUserSubmittedQuestions(rateId) {
  return axios
    .get(`${baseUrl}/questionSubmit/getQuestionsSubmittedForAdmin/${rateId}`)
    .then(({ data }) => data);
}

export async function getUserDeletedSubmittedQuestions(rateId) {
  return axios
    .get(`${baseUrl}/questionSubmit/getDeletedQuestionsByAdmin/${rateId}`)
    .then(({ data }) => data);
}

export async function getFavoriteQuestions(rateId, limit = 50, skip = 0) {
  return axios
    .get(
      `${baseUrl}/favoriteQuestion/getFavoriteQuestionForAdmin/${rateId}?limit=${limit}&skip=${skip}`,
    )
    .then(({ data }) => data);
}

export async function deleteFavoriteQuestion(questionId, questionRate) {
  return axios
    .post(
      `${baseUrl}/favoriteQuestion/removeFavoriteQuestionsForAdmin/${questionId}`,
      {
        rateId: questionRate,
      },
    )
    .then(({ data }) => data);
}

export async function approveSubmittedQuestion(questionId, rateId, timestamp) {
  return axios
    .patch(`${baseUrl}/questionSubmit/approveQuestion/${questionId}`, {
      rateId,
      updatedAt: timestamp,
    })
    .then(({ data }) => data);
}

export async function deleteSubmittedQuestion(
  questionId,
  questionRate,
  timestamp,
) {
  return axios
    .post(
      `${baseUrl}/questionSubmit/deleteSubmitQuestionForAdmin/${questionId}`,
      { rateId: questionRate, updatedAt: timestamp },
    )
    .then(({ data }) => data);
}

export async function undeleteSubmittedQuestion(
  questionId,
  userId,
  updatedAt,
  rateId,
) {
  return axios
    .patch(`${baseUrl}/questionSubmit/undeleteQuestionsByAdmin/${rateId}`, {
      questionId,
      updatedAt,
      userId,
    })
    .then(({ data }) => data);
}

export async function rejectSubmittedQuestion(questionId, rateId, timestamp) {
  return axios
    .patch(`${baseUrl}/questionSubmit/rejectQuestion/${questionId}`, {
      rateId,
      updatedAt: timestamp,
    })
    .then(({ data }) => data);
}

export async function editSubmittedQuestion(
  questionId,
  question,
  rateId,
  timestamp,
) {
  return axios
    .patch(`${baseUrl}/questionSubmit/editSubmitQuestion/${questionId}`, {
      rateId,
      ...question,
      updatedAt: timestamp,
    })
    .then(({ data }) => data);
}

export async function removeFavoriteQuestion(rateId, questions) {
  return axios
    .delete(
      `${baseUrl}/favoriteQuestion/removeFavoriteQuestionsForAdmin/${rateId}`,
      {
        data: questions,
      },
    )
    .then(({ data }) => data);
}

export async function getUserSubmittedQuestionsReportService(rates) {
  return axios
    .get(
      `${baseUrl}/questionSubmit/getSubmitQuestionReportForGivenRates/${
        Array.isArray(rates) ? `[${rates}]` : rates
      }`,
    )
    .then(({ data }) => data);
}

export async function getAllDailyQuizForTodayService() {
  return axios
    .get(`${baseUrl}/adminuser/getAllDailyQuizForToday`)
    .then(({ data }) => data);
}

export async function generateDailyQuizForTodayService() {
  return axios
    .post(`${baseUrl}/adminuser/generateDailyQuizForToday`)
    .then(({ data }) => data);
}

export async function generateRateQuestionsReportService(ratesIds) {
  return axios
    .post(`${baseUrl}/report/generateRateQuestionsReport`, { ratesIds })
    .then(({ data }) => data);
}
export async function getRateQuestionsReportsService() {
  return axios
    .get(`${baseUrl}/report/getRateQuestionsReports`)
    .then(({ data }) => data);
}
export async function generateUsageReportService(
  fromDate,
  untilDate,
  fromDateComparison,
  untilDateComparison,
) {
  return axios
    .post(`${baseUrl}/report/generateUsageStatisticsReport`, {
      fromDate,
      untilDate,
      fromDateComparison,
      untilDateComparison,
    })
    .then(({ data }) => data);
}
export async function getUsageReportsService() {
  return axios
    .get(`${baseUrl}/report/getUsageStatisticsReports`)
    .then(({ data }) => data);
}

export async function getLatestBooksMissingAnswersReport() {
  return axios
    .get(`${baseUrl}/report/booksMissingAnswers`)
    .then(({ data }) => data);
}
export async function generateBooksMissingAnswersReport() {
  return axios
    .post(`${baseUrl}/report/booksMissingAnswers`)
    .then(({ data }) => data);
}

/** *******************
 * PRIVATE FUNCTIONS *
 ******************** */

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getQueryString(
  page = 1,
  search,
  customPageSize,
  skip = 0,
  sort = '',
  limit,
) {
  let csearch = '';
  const rows = customPageSize || infinitePageSize;

  const subscriptionId = '';

  if (search) {
    search = search.trim();
    csearch = capitalizeFirstLetter(search);
  }
  page = Math.max(page - 1, 0);
  return `skip=${skip + page * rows}&limit=${
    limit || rows * (page + 1)
  }${sort}${
    search
      ? `&where={"or": [ {"name": {"contains":"${search}"}}, {"name": {"contains":"${csearch}"}} , {"lastName": {"contains":"${search}"}} ,{"lastName": {"contains":"${csearch}"}} , {"email": {"startsWith":"${search.toLowerCase()}"}}], "and": [${subscriptionId}]}`
      : `&where={"and": [${subscriptionId}]}`
  }`;
}

function getInfiniteQueryString(page = 0, search, customPageSize, skip = 0) {
  const rows = customPageSize || infinitePageSize;
  const skipCount = skip + page * rows;
  let csearch = '';
  if (search) {
    csearch = capitalizeFirstLetter(search);
  }
  return `skip=${skipCount}&limit=${rows}&sort=name ASC${
    search
      ? `&where={"or": [ {"name": {"contains":"${search}"}}, {"name": {"contains":"${csearch}"}} , {"lastName": {"contains":"${search}"}}, {"lastName": {"contains":"${csearch}"}} , {"email": {"startsWith":"${search.toLowerCase()}"}} ]}`
      : ''
  }`;
}

function getInfiniteQueryStringRefs(
  page = 0,
  search,
  customPageSize,
  skip = 0,
  rate,
  amountOfReferences,
  barFilter,
  duplicateChapter,
) {
  if (amountOfReferences && page === 0) {
    return `skip=0&limit=${amountOfReferences}${
      barFilter ? `&barfilter=${barFilter}` : ''
    }`;
  }
  let pageReindex = page;
  if (search !== '' && page) {
    pageReindex = page - 1;
  }
  const rows = customPageSize || pageSize;
  const skipCount = skip + pageReindex * rows;
  const filterRate = rate ? `&searchRate=${rate}` : '';
  return `skip=${skipCount}&limit=${rows}${
    search ? `&search=${search}${filterRate}` : ''
  }${barFilter ? `&barfilter=${barFilter}` : ''}${
    duplicateChapter ? `&duplicateChapter=${duplicateChapter}` : ''
  }`;
}
