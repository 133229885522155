import Notifications from 'react-notification-system-redux';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import * as links from '../../constants/links';
import { closeModal } from '../../containers/Full/actions';
import * as api from '../../utilities/ServiceManager';
import {
  errorFetchingFromService,
  listNewCyclesAction,
  removeUser,
  searchUsersReceivedAction,
  setApplicableCoupons,
  setCancellationReasonNote,
  setChangeUserFavoriteBlockStatus,
  setChangeUserSubmissionBlockStatus,
  setCouponUserAction,
  setDisableCycle,
  setHistorySubscriptionUser,
  setIsBetaUser,
  setIsLoadingBlockStatus,
  setIsLoadingFavoriteBlockStatus,
  setLoading,
  setLoadingHistory,
  setLoadingInfo,
  setMessage,
  setNewStudentError,
  setPracticeExams,
  setPracticeExamsWaiting,
  setResponse,
  setRestartPracticeExamSuccess,
  setRestartPracticeExamWaiting,
  setSelectedUser,
  setShowChangeExamTypeModal,
  setStatistics,
  setSubscriptionType,
  setUserEducationalInstitutionData,
  setUserIntercomInformation,
  setUserNote,
  setUserVerificationAction,
  updateFormErrors,
  updateIsWaiting,
  updateUser,
} from './actions';
import {
  DISABLE_CYCLE_INFORMATION,
  GET_BETA_USER,
  GET_COUPON_USER,
  GET_DO_NOT_CONTACT,
  GET_EDUCATIONAL_INSTITUTION_DATA,
  GET_INTERCOM_CANCELLATION,
  GET_INTERCOM_CANCELLATION_REASON,
  GET_NOTE_CANCELLATION_REASON,
  GET_NOTE_DO_NOT_CONTACT,
  GET_USER_SUBSCRIPTION_HISTORY,
  LOAD_APPLICABLE_COUPONS,
  SET_CHECK_BETA_USER,
  SET_USER_FAVORITE_BLOCK_STATUS,
  SET_USER_SUBMISSION_BLOCK_STATUS,
  UPDATE_CANCELLATION_REASON,
  UPDATE_EDUCATIONAL_INSTITUTION_DATA,
} from './actionTypes';
import {
  BLOCK_USER_SAGAS,
  CREATE_STUDENT,
  DELETE_USER_SAGAS,
  GET_CYCLE_INFORMATION,
  GET_USER_VERIFICATION,
  LOAD_PRACTICE_EXAMS,
  LOAD_SELECTED_USER,
  LOAD_USER_STATISTICS,
  LOAD_USER_SUBSCRIPTION_TYPE,
  LOGIN_AS_STUDENT,
  RESET_PASSWORD_USER_SAGAS,
  RESET_USER_QUESTIONS,
  RESTART_PRACTICE_EXAM,
  TOGGLE_ACTIVE_SAGAS,
  UNLOCK_USER_SAGAS,
  UPDATE_USER_SAGAS,
  UPDATE_USER_SUBSCRIPTION_SAGAS,
  UPDATE_USER_VERIFICATION,
  USERS_GET_SAGAS,
} from './actionTypesSagas';

function* fetchUsers(action) {
  try {
    const usersResponse = yield call(
      api.getUsers,
      action.search,
      action.page,
      action.filterBySubscription,
      action.filterByRate,
    );
    yield put(searchUsersReceivedAction(usersResponse));
  } catch (err) {
    yield put(errorFetchingFromService(err));
  }
}

function* watchGetUsers() {
  yield takeLatest(USERS_GET_SAGAS, fetchUsers);
}

function* loadSelectedUser(action) {
  try {
    const usersResponse = yield call(api.getUser, action.id);
    yield put(updateUser(usersResponse));
    yield put(setSelectedUser(usersResponse));
  } catch (err) {
    yield put(errorFetchingFromService(err));
  }
}

function* watchSelectedUser() {
  yield takeLatest(LOAD_SELECTED_USER, loadSelectedUser);
}

function* deleteFlow(action) {
  try {
    yield call(api.deleteUser, action.id);
    yield put(removeUser(action.id));
    yield put(closeModal());
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'User deleted successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (error) {
    yield put(
      Notifications.show({ title: 'Error', message: error.message }, 'error'),
    );
  }
}

function* deletUserWatcher() {
  yield takeEvery(DELETE_USER_SAGAS, deleteFlow);
}

function* toggleActiveUserWatcher() {
  yield takeEvery(TOGGLE_ACTIVE_SAGAS, toggleActiveUser);
}

function* toggleActiveUser(action) {
  try {
    const user = yield call(api.updateUser, action.id, {
      active: !action.currentActive,
    });

    yield put(updateUser(user));
    yield put(closeModal());
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'User updated successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (error) {
    yield put(
      Notifications.show({ title: 'Error', message: error.message }, 'error'),
    );
  }
}

function* unlockUserWatcher() {
  yield takeEvery(UNLOCK_USER_SAGAS, unlockUser);
}

function* unlockUser(action) {
  try {
    yield call(api.unlockUser, action.id);
    yield put(closeModal());
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'User successfully unlocked',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (error) {
    yield put(closeModal());
    yield put(
      Notifications.show(
        { title: 'Error', message: 'This user is not locked' },
        'error',
      ),
    );
  }
}

function* blockUserWatcher() {
  yield takeLatest(BLOCK_USER_SAGAS, blockUser);
}

function* blockUser(action) {
  try {
    const user = yield call(api.blockUser, action.id, action.state);
    yield put(closeModal());
    yield put(updateUser(user));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: `User successfully ${action.state ? 'blocked' : 'unblocked'}`,
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (error) {
    yield put(closeModal());
    yield put(
      Notifications.show({ title: 'Error', message: error.message }, 'error'),
    );
  }
}

function* resetPasswordWatcher() {
  yield takeEvery(RESET_PASSWORD_USER_SAGAS, resetPassword);
}

function* resetPassword(action) {
  try {
    yield put(updateIsWaiting(true));
    yield call(api.resetPasswordUser, action.id, action.fields);

    yield put(closeModal());
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'User updated successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (error) {
    yield put(updateFormErrors(error.message));
    // yield put(
    // Notifications.show({ title: 'Error', message: error.message }, 'error')
    // );
  } finally {
    yield put(updateIsWaiting(false));
  }
}

// CHANGE STRIPE ID
function* updateUserWatcher() {
  yield takeEvery(UPDATE_USER_SAGAS, updateUserInfo);
}

function* updateUserInfo(action) {
  try {
    yield put(updateIsWaiting(true));
    const changeExamTypeModal = !!action.user.mustChangeCycle;
    delete action.user.mustChangeCycle;
    const user = yield call(api.updateUser, action.user.id, action.user);

    if (typeof user === 'string' && user.includes('No such customer:')) {
      // This part of the code is to show a correct message if someone try to update the Stripe ID with an incorrect information
      yield put(Notifications.show({ title: 'Error', message: user }, 'error'));
      yield put(updateFormErrors(user));
    } else {
      yield put(closeModal());
      yield put(updateUser(user));
      yield put(
        Notifications.show(
          {
            title: 'Done!',
            message: 'User updated successfully',
            autoDismiss: 1,
          },
          'success',
        ),
      );
      if (changeExamTypeModal) {
        yield put(setShowChangeExamTypeModal(true));
      }
    }
  } catch (error) {
    yield put(
      Notifications.show({ title: 'Error', message: error.message }, 'error'),
    );
    yield put(updateFormErrors(error.message));
  } finally {
    yield put(updateIsWaiting(false));
  }
}

// CREATE STUDENT
function* createStudentWatcher() {
  yield takeEvery(CREATE_STUDENT, createStudent);
}

function* createStudent(action) {
  try {
    yield put(setLoading(true));
    const response = yield call(api.createStudent, action.student);
    if (response) {
      yield put(setResponse(response));
      yield put(
        Notifications.show(
          {
            title: 'Done!',
            message: 'Student created successfully',
            autoDismiss: 1,
          },
          'success',
        ),
      );
    } else {
      yield put(setNewStudentError(true));
      yield put(
        Notifications.show(
          {
            title: 'Error',
            message:
              'The email is already in use or there is no subscription for that Stripe Id',
          },
          'error',
        ),
      );
    }
  } catch (error) {
    // if (error.response.data === 'There is no subscription for that stripeId') {
    //   yield put(setMessage(error.response.data));
    // } else {
    yield put(setNewStudentError(true));
    yield put(
      Notifications.show(
        {
          title: 'Error',
          message:
            'The email is already in use or there is no subscription for that Stripe Id',
        },
        'error',
      ),
    );
    // }
  } finally {
    yield put(setLoading(false));
  }
}

// LOAD SUBSCRIPTION TYPE
function* loadSubscriptionTypeWatcher() {
  yield takeLatest(LOAD_USER_SUBSCRIPTION_TYPE, loadSubscriptionType);
}

function* loadSubscriptionType(action) {
  try {
    const type = yield call(api.loadSubscriptionType, action.student);
    yield put(setSubscriptionType(type));
  } catch (error) {
    yield put(
      Notifications.show(
        { title: 'Error', message: 'Could not load user subscription' },
        'error',
      ),
    );
  } finally {
  }
}

// update user subscriptionType
function* updateUserSubscriptionWatcher() {
  yield takeEvery(UPDATE_USER_SUBSCRIPTION_SAGAS, updateUserSubscription);
}

function* updateUserSubscription(action) {
  try {
    yield call(api.updateStudentSubscription, action.subscription);
    yield put(setSubscriptionType(action.subscription));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Subscription updated successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (error) {
    yield put(
      Notifications.show({ title: 'Error', message: error.message }, 'error'),
    );
  }
}

// User Verification
function* getUserVerificationWatcher() {
  yield takeEvery(GET_USER_VERIFICATION, getUserVerificationSaga);
}

function* getUserVerificationSaga(action) {
  try {
    if (action && action.userId) {
      const response = yield call(
        api.getUserVerificationService,
        action.userId,
      );
      yield put(setUserVerificationAction(response));
    }
  } catch (error) {
    yield put(
      Notifications.show({ title: 'Error', message: error.message }, 'error'),
    );
  }
}

function* setUserVerificationWatcher() {
  yield takeEvery(UPDATE_USER_VERIFICATION, setUserVerificationSaga);
}

function* setUserVerificationSaga(action) {
  try {
    const response = yield call(
      api.setUserVerificationService,
      action.userId,
      action.customer,
    );
    yield put(setUserVerificationAction(response));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'User Verification updated successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (error) {
    yield put(
      Notifications.show({ title: 'Error', message: error.message }, 'error'),
    );
  }
}

// LOAD STATISTICS
function* loadStatisticsWatcher() {
  yield takeEvery(LOAD_USER_STATISTICS, loadStatistics);
}

function* loadStatistics(action) {
  try {
    const statistics = yield call(
      api.loadStatistics,
      action.user,
      action.page,
      action.filter,
    );
    yield put(setStatistics(statistics));
  } catch (error) {
    yield put(
      Notifications.show(
        { title: 'Error', message: 'Could not load user statistics' },
        'error',
      ),
    );
  }
}

// PROFILE SHEET
function* cyclesInformationWatcher() {
  yield takeEvery(GET_CYCLE_INFORMATION, getCyclesInformationSaga);
}

function* getCyclesInformationSaga(action) {
  try {
    const response = yield call(api.getCycleInformation, action.userId);
    yield put(listNewCyclesAction(response.cyclesInformationByAdmin));
  } catch (error) {
    yield put(
      Notifications.show({ title: 'Error', message: error.message }, 'error'),
    );
  }
}

function* disableCycleInformationWatcher() {
  yield takeEvery(DISABLE_CYCLE_INFORMATION, disableCyleInformationSagas);
}

function* disableCyleInformationSagas(action) {
  try {
    const response = yield call(
      api.disableCycleAdmin,
      action.cycleId,
      action.value,
    );
    yield put(setDisableCycle(response));
  } catch (error) {
    yield put(
      Notifications.show({ title: 'Error', message: error.message }, 'error'),
    );
  }
}

// LOGIN AS STUDENT
function* loginAsStudentWatcher() {
  yield takeEvery(LOGIN_AS_STUDENT, loginAsStudent);
}

function* watchGetNoteUser() {
  yield takeLatest(GET_NOTE_DO_NOT_CONTACT, getUserNoteSagas);
}
function* getUserNoteSagas(action) {
  try {
    const response = yield call(api.getUserNotes, action.userId);
    yield put(setUserNote(response));
  } catch (error) {}
}

function* watchGetCouponUser() {
  yield takeLatest(GET_COUPON_USER, getCouponUserSaga);
}
function* getCouponUserSaga(action) {
  try {
    const response = yield call(api.getLastCouponUser, action.userId);

    yield put(setCouponUserAction(response));
  } catch (error) {}
}
function* watchGetUserCancellationReason() {
  yield takeLatest(GET_NOTE_CANCELLATION_REASON, getUserCancellationReasonSaga);
}
function* getUserCancellationReasonSaga(action) {
  try {
    const response = yield call(api.getUserCancellationReason, action.userId);

    yield put(setCancellationReasonNote(response.cancelledReason));
  } catch (error) {}
}

function* watchUpdateCancellationReason() {
  yield takeLatest(UPDATE_CANCELLATION_REASON, updateCancellationSagas);
}

function* updateCancellationSagas(action) {
  try {
    const response = yield call(
      api.updateCancellationReason,
      action.userId,
      action.cancelledReason,
    );
    yield put(setCancellationReasonNote(action.cancelledReason));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'User updated successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (error) {
    yield put(
      Notifications.show({ title: 'Error', message: error.message }, 'error'),
    );
  }
}

function* watchGetIntercomCancellationReason() {
  yield takeLatest(
    GET_INTERCOM_CANCELLATION_REASON,
    getIntercomCancellationReasonSagas,
  );
}

function* getIntercomCancellationReasonSagas(action) {
  try {
    const response = yield call(
      api.getIntercomCancellationReason,
      action.userId,
    );
    yield put(setUserIntercomInformation(response));
  } catch (error) {}
}

function* watchGetEducationalInstitutionData() {
  yield takeLatest(
    GET_EDUCATIONAL_INSTITUTION_DATA,
    getEducationalInstitutionDataSagas,
  );
}

function* getEducationalInstitutionDataSagas(action) {
  try {
    const response = yield call(
      api.getUserEducationalInstitutionData,
      action.userId,
    );
    yield put(setUserEducationalInstitutionData(response));
  } catch (error) {}
}

function* watchUpdateDoNotContact() {
  yield takeLatest(GET_DO_NOT_CONTACT, updateDoNotContactSagas);
}

function* updateDoNotContactSagas(action) {
  try {
    yield call(api.updateDoNotContact, action.payload);
    const usersResponse = yield call(api.getUser, action.payload.userId);
    yield put(updateUser(usersResponse));
    yield put(setSelectedUser(usersResponse));
    yield put(setUserNote(action.payload));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'User note updated successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (error) {
    yield put(
      Notifications.show({ title: 'Error', message: error.message }, 'error'),
    );
  }
}

function* watchUpdateIntercomCancellation() {
  yield takeLatest(GET_INTERCOM_CANCELLATION, updateIntercomCancellationSagas);
}

function* updateIntercomCancellationSagas(action) {
  try {
    yield call(api.updateIntercomCancellation, action.payload);
    const usersResponse = yield call(api.getUser, action.payload.userId);
    yield put(updateUser(usersResponse));
    yield put(setSelectedUser(usersResponse));
    yield put(setUserIntercomInformation(action.payload));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'User updated successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (error) {
    yield put(
      Notifications.show({ title: 'Error', message: error.message }, 'error'),
    );
  }
}

function* watchUpdateEducationalInstitutionData() {
  yield takeLatest(
    UPDATE_EDUCATIONAL_INSTITUTION_DATA,
    updateEducationalInstitutionDataSagas,
  );
}

function* updateEducationalInstitutionDataSagas(action) {
  try {
    yield call(
      api.updateEducationalInstitutionData,
      action.userId,
      action.educationalInstitution,
    );
    const response = yield call(
      api.getUserEducationalInstitutionData,
      action.userId,
    );
    yield put(setUserEducationalInstitutionData(response));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'User updated successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (error) {
    yield put(
      Notifications.show({ title: 'Error', message: error.message }, 'error'),
    );
  }
}

function* loginAsStudent(action) {
  try {
    const response = yield call(
      api.loginAsStudent,
      action.student,
      action.platformType,
      action.adminUserId,
    );
    if (action.platformType === 'web') {
      window.open(
        `${links.BLUEJACKETEER_LINK}?token=${response.token}`,
        '_blank',
      );
    }

    if (action.platformType === 'app') {
      yield put(
        Notifications.show(
          {
            title: 'Done!',
            message: 'An email has been sent to access the app',
            autoDismiss: 1,
          },
          'success',
        ),
      );
    }
  } catch (error) {
  } finally {
  }
}

function* watchResetUserQuestions() {
  yield takeLatest(RESET_USER_QUESTIONS, resetUserQuestions);
}

function* resetUserQuestions(action) {
  try {
    yield call(api.resetUserQuestions, action.user);
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'User questions deleted successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (err) {
    yield put(
      Notifications.show(
        {
          title: 'Error',
          message: 'There was an error deleting the user questions',
        },
        'error',
      ),
    );
  } finally {
    yield put(closeModal());
  }
}

export function* watchLoadUserPracticeExams() {
  yield takeLatest(LOAD_PRACTICE_EXAMS, loadUserPracticeExamsSagas);
}

function* loadUserPracticeExamsSagas(action) {
  try {
    yield put(setPracticeExamsWaiting(true));
    const response = yield call(api.loadUserPracticeExams, action.userId);
    if (response.error) {
      yield put(
        Notifications.show(
          {
            title: 'Error',
            message: 'There was an error loading practice exams',
          },
          'error',
        ),
      );
      yield put(setPracticeExams([]));
    } else {
      yield put(setPracticeExams(response));
    }

    yield put(setPracticeExamsWaiting(false));
  } catch (err) {
    yield put(setPracticeExams([]));
    yield put(setPracticeExamsWaiting(false));
    yield put(
      Notifications.show(
        {
          title: 'Error',
          message: 'There was an error loading practice exams',
        },
        'error',
      ),
    );
  }
}

export function* watchRestartPracticeExam() {
  yield takeLatest(RESTART_PRACTICE_EXAM, restartPracticeExamSaga);
}

function* restartPracticeExamSaga(action) {
  try {
    yield put(setRestartPracticeExamWaiting(true));

    const response = yield call(
      api.resetPracticeExam,
      action.practiceExamId,
      action.userId,
    );

    if (response.error) {
      yield put(setRestartPracticeExamSuccess(false));
      yield put(
        Notifications.show(
          {
            title: 'Error',
            message: response.error,
          },
          'error',
        ),
      );
    } else {
      yield put(setRestartPracticeExamSuccess(true));
      yield put(
        Notifications.show(
          {
            title: 'Done',
            message: 'Practice exam was reset successfully!',
          },
          'error',
        ),
      );
    }

    yield put(setRestartPracticeExamWaiting(false));
  } catch (err) {
    yield put(setRestartPracticeExamSuccess(false));
    yield put(setRestartPracticeExamWaiting(false));
    yield put(
      Notifications.show(
        {
          title: 'Error',
          message: 'There was an error loading practice exams',
        },
        'error',
      ),
    );
  }
}

function* watchGetApplicableCoupons() {
  yield takeLatest(LOAD_APPLICABLE_COUPONS, loadApplicableCouponsSaga);
}

function* loadApplicableCouponsSaga(action) {
  try {
    const response = yield call(api.loadApplicableCoupons, action.userId);

    yield put(setApplicableCoupons(response));
  } catch (error) {}
}

function* setUserSubmissionBlockStatusWatcher() {
  yield takeLatest(
    SET_USER_SUBMISSION_BLOCK_STATUS,
    setUserSubmissionBlockStatusSaga,
  );
}

function* setUserSubmissionBlockStatusSaga(action) {
  try {
    yield put(setIsLoadingBlockStatus(true));
    yield call(api.setUserSubmissionBlockStatusService, action.payload);

    yield put(setChangeUserSubmissionBlockStatus(action.payload));

    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'User question submission status updated successfully!',
        },
        'success',
      ),
    );
  } catch (error) {
    yield put(
      Notifications.show(
        {
          title: 'Error',
          message:
            'There was an error updating user question submission status',
        },
        'error',
      ),
    );
  } finally {
    yield put(setIsLoadingBlockStatus(false));
  }
}

function* setUserFavoriteBlockStatusWatcher() {
  yield takeLatest(
    SET_USER_FAVORITE_BLOCK_STATUS,
    setUserFavoriteBlockStatusSaga,
  );
}

function* subscriptionHistoryWatcher() {
  yield takeEvery(
    GET_USER_SUBSCRIPTION_HISTORY,
    getUserSubscriptionHistorySaga,
  );
}

function* setCheckBetaUserSagaWatcher() {
  yield takeLatest(SET_CHECK_BETA_USER, setCheckBetaUserSaga);
}

function* setCheckBetaUserSaga(action) {
  try {
    const isBetaUser = yield call(
      api.setCheckBetaUserService,
      action.userId,
      action.status,
    );
    yield put(setIsBetaUser(isBetaUser));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Beta user status updated successfully!',
        },
        'success',
      ),
    );
  } catch (error) {
    yield put(
      Notifications.show(
        {
          title: 'Error',
          message: 'An error occurred while updating the beta user status',
        },
        'error',
      ),
    );
  }
}

function* getBetaUserSagaWatcher() {
  yield takeLatest(GET_BETA_USER, getBetaUserSaga);
}

function* getBetaUserSaga(action) {
  try {
    const isBetaUser = yield call(api.getBetaUserService, action.userId);
    yield put(setIsBetaUser(isBetaUser));
  } catch (error) {
    yield put(
      Notifications.show(
        {
          title: 'Error',
          message: 'An error occurred while updating the beta user status',
        },
        'error',
      ),
    );
  }
}

function* getUserSubscriptionHistorySaga(action) {
  try {
    yield put(setLoadingHistory(true));
    yield put(
      Notifications.show(
        {
          title: 'Loading History',
          message: 'Wait, getting subscription history',
        },
        'info',
      ),
    );
    const response = yield call(api.getUserSubscriptionsHistory, action.userId);

    yield put(setHistorySubscriptionUser(response));
    yield put(
      Notifications.show(
        {
          title: 'Subscription History',
          message: 'Subscription history was obtained correctly.',
        },
        'success',
      ),
    );
  } catch (error) {
    let message = 'An error occurred while updating the beta user status';
    if (error.response && error.response.data) {
      message = error.response.data;
    }
    yield put(
      Notifications.show(
        {
          title: 'Error',
          message,
        },
        'error',
      ),
    );
  } finally {
    yield put(setLoadingHistory(false));
  }
}

function* setUserFavoriteBlockStatusSaga(action) {
  try {
    yield put(setIsLoadingFavoriteBlockStatus(true));

    yield call(api.setUserFavoritesBlockStatusService, action.payload);
    yield put(setChangeUserFavoriteBlockStatus(action.payload));

    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'User favorite block status updated successfully!',
        },
        'success',
      ),
    );
  } catch (error) {
    yield put(
      Notifications.show(
        {
          title: 'Error',
          message: 'There was an error updating user question favorite status',
        },
        'error',
      ),
    );
  } finally {
    yield put(setIsLoadingFavoriteBlockStatus(false));
  }
}

export default function* sagas() {
  yield all([
    watchGetUsers(),
    deletUserWatcher(),
    toggleActiveUserWatcher(),
    unlockUserWatcher(),
    blockUserWatcher(),
    resetPasswordWatcher(),
    updateUserWatcher(),
    createStudentWatcher(),
    loadStatisticsWatcher(),
    loginAsStudentWatcher(),
    loadSubscriptionTypeWatcher(),
    updateUserSubscriptionWatcher(),
    getUserVerificationWatcher(),
    setUserVerificationWatcher(),
    watchResetUserQuestions(),
    watchSelectedUser(),
    watchLoadUserPracticeExams(),
    watchRestartPracticeExam(),
    watchGetNoteUser(),
    watchGetCouponUser(),
    watchGetUserCancellationReason(),
    watchUpdateCancellationReason(),
    watchUpdateDoNotContact(),
    cyclesInformationWatcher(),
    disableCycleInformationWatcher(),
    watchUpdateIntercomCancellation(),
    watchGetIntercomCancellationReason(),
    watchGetApplicableCoupons(),
    watchUpdateEducationalInstitutionData(),
    watchGetEducationalInstitutionData(),
    setUserSubmissionBlockStatusWatcher(),
    setUserFavoriteBlockStatusWatcher(),
    setCheckBetaUserSagaWatcher(),
    getBetaUserSagaWatcher(),
    subscriptionHistoryWatcher(),
  ]);
}
