import { Map } from 'immutable';

import * as type from './actionTypes';
import {
  BOOKS_MISSING_ANSWERS_REPORT_LOADING,
  BOOKS_MISSING_ANSWERS_REPORT_SUCCESS,
} from './components/BooksMissingAnswersReport/reportStates';

const initialState = Map({
  bibliographyCSVJsonReport: [],
  topicsOrExamSubtopicsReport: {},
  userSubmittedQuestionsReportCSVJson: [],
  isUserSubmittedQuestionsReportLoading: false,
  userSubmittedQuestionsReportError: '',
  profileSheetCsv: [],
  isLoading: false,

  errorMessage: '',
  usersCount: [],
  setGraph: { labels: '', datasets: [] },
  values: [],
  finish: false,
  lastCustomer: '',
  count: 0,
  historicalReportError: false,
  allRates: [],
  completionStatus: [],
  activeSubscriptionError: false,
  csvDataAvailably: false,
  automaticMasterReportData: [],
  automaticSubscriptionsReport: [],
  csvDataSubsActive: false,
  numberOfQuestionsPerRatingExamReportData: [],
  numberOfQuestionsPerRatingExamReportHasMore: true,
  nextRateToUse: '',
  numberOfQuestionsPerRatingExamReportOverallUnique: 0,
  numberOfQuestionsPerRatingExamReportApiError: false,
  intercomActiveUsersData: [],
  lastCustomerSubscriptionIntercom: '',
  intercomActiveUsersHasMore: true,
  intercomActiveUsersApiError: false,

  /* Favorite Questions Report */
  loadingFavoriteQuestionsReports: false,
  lastFavoriteQuestionsReports: null,
  /* Rate Questions Report */
  lastRateQuestionsReports: null,
  /* Usage Statistics Report */
  usageStatisticsReports: [],
  usageStatisticsReportsIsLoading: false,

  /* Books missing answers report */
  booksMissingAnswersReportData: {
    link: '',
    state: BOOKS_MISSING_ANSWERS_REPORT_LOADING,
  },
  booksMissingAnswersReportState: BOOKS_MISSING_ANSWERS_REPORT_LOADING,
});

const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.GET_LATEST_TOPICS_OR_EXAM_SUBTOPICS_REPORT:
      return state;
    case type.GENERATE_TOPICS_OR_EXAM_SUBTOPICS_REPORT: {
      const uploadedReport = {
        ...state.get('topicsOrExamSubtopicsReport'),
        state: 'LOADING',
      };
      return state.set('topicsOrExamSubtopicsReport', uploadedReport);
    }
    case type.SET_TOPICS_OR_EXAM_SUBTOPICS_REPORT:
      return state.set('topicsOrExamSubtopicsReport', action.payload.report);
    case type.GET_BIBLIOGRAPHY_REPORT_FOR_RATES:
      return state
        .set('isLoading', true)
        .set('errorMessage', '')
        .set('bibliographyCSVJsonReport', []);
    case type.GET_PROFILE_SHEET_REPORT:
      return state.set('isLoading', true);
    case type.SET_GRAPH:
      return state.set('setGraph', action.graph);
    case type.CALL_USERS_COUNT:
      return state.set('isLoading', true);
    case type.GET_BIBLIOGRAPHY_REPORT_FOR_RATES_SUCCESS:
      return state
        .set('isLoading', false)
        .set('bibliographyCSVJsonReport', action.bibliographyReportCSVJsonData);
    case type.GET_PROFILE_SHEET_CSV:
      return state
        .set('isLoading', false)
        .set('profileSheetCsv', action.profileSheetCsv);
    case type.GET_BIBLIOGRAPHY_REPORT_FOR_RATES_ERROR:
      return state
        .set('isLoading', false)
        .set('errorMessage', action.errorMessage);
    case type.GET_USERS_COUNT:
      return state
        .set('isLoding', true)
        .set('errorMessage', '')
        .set('usersCount', []);
    case type.GET_USERS_COUNT_SUCCESS:
      return state
        .set('isLoading', false)
        .set('usersCount', action.usersCountData);
    case type.GET_USERS_COUNT_ERROR:
      return state
        .set('isLoading', false)
        .set('usersCount', [])
        .set('errorMessage', action.errorMessage);
    case type.SET_HISTORICAL_USERS_REPORT: {
      const valuesState = state.get('values');
      const valuesConcat = valuesState.concat(action.data.values);
      let allRates = state.get('allRates');
      if (action.data.allRates.length > 0) {
        allRates = action.data.allRates;
      }
      return state
        .set('values', valuesConcat)
        .set('finish', action.data.finish)
        .set('lastCustomer', action.data.lastCustomer)
        .set('allRates', allRates);
    }
    case type.GET_HISTORICAL_USERS_REPORT_ERROR:
      return state.set('historicalReportError', true);
    case type.GET_COMPLETION_STATUS:
      return state.set('completionStatus', action.data);
    case type.CLEAR_API_ERROR:
      return state.set('historicalReportError', false);
    case type.CLEAR_PROPS:
      return state
        .set('values', [])
        .set('finish', false)
        .set('lastCustomer', '')
        .set('count', 0)
        .set('historicalReportError', false)
        .set('allRates', []);
    case type.SET_INTERCOM_ACTIVE_USERS: {
      const intercomActiveUsersCurrentData = state.get(
        'intercomActiveUsersData',
      );
      let intercomActiveUsersConcatData = intercomActiveUsersCurrentData.concat(
        action.data,
      );
      if (!action.hasMore) {
        const intercomActiveUsersFinalCSV = [
          [
            'User Stripe ID',
            'User Email',
            'Last Device Platform',
            'Last Device Model',
            'Last Device Version',
          ],
        ];
        intercomActiveUsersConcatData = intercomActiveUsersFinalCSV.concat(
          intercomActiveUsersConcatData,
        );
      }
      return state
        .set('intercomActiveUsersData', intercomActiveUsersConcatData)
        .set(
          'lastCustomerSubscriptionIntercom',
          action.lastCustomerSubscription,
        )
        .set('intercomActiveUsersHasMore', action.hasMore);
    }
    case type.CLEAR_INTERCOM_ACTIVE_USERS_REPORT_PROPS:
      return state
        .set('intercomActiveUsersData', [])
        .set('lastCustomerSubscriptionIntercom', '')
        .set('intercomActiveUsersHasMore', true)
        .set('intercomActiveUsersApiError', false);
    case type.SET_INTERCOM_ACTIVE_SUBSCRIPTIONS_ERROR:
      return state.set('intercomActiveUsersApiError', true);
    case type.CLEAR_INTERCOM_ACTIVE_USERS_API_ERROR:
      return state.set('intercomActiveUsersApiError', false);
    case type.SET_NUMBER_OF_QUESTIONS_ENABLED_PER_RATING_EXAM_REPORT: {
      const previousOverallUnique = state.get(
        'numberOfQuestionsPerRatingExamReportOverallUnique',
      );
      const newOverallUnique =
        previousOverallUnique + action.response.overallUnique;
      const numberOfQuestionsCurrentData = state.get(
        'numberOfQuestionsPerRatingExamReportData',
      );
      let numberOfQuestionsConcatData = numberOfQuestionsCurrentData.concat(
        action.response.data,
      );
      if (!action.response.hasMore) {
        const numberOfQuestionsFinalCSV = [
          ['OVERALL UNIQUE', newOverallUnique],
        ];
        numberOfQuestionsConcatData = numberOfQuestionsFinalCSV.concat(
          numberOfQuestionsConcatData,
        );
      }
      return state
        .set(
          'numberOfQuestionsPerRatingExamReportData',
          numberOfQuestionsConcatData,
        )
        .set(
          'numberOfQuestionsPerRatingExamReportHasMore',
          action.response.hasMore,
        )
        .set('nextRateToUse', action.response.nextRateToUse)
        .set(
          'numberOfQuestionsPerRatingExamReportOverallUnique',
          newOverallUnique,
        );
    }
    case type.CLEAR_NUMBER_OF_QUESTIONS_ENABLED_PER_RATING_EXAM_REPORT_PROPS:
      return state
        .set('numberOfQuestionsPerRatingExamReportData', [])
        .set('numberOfQuestionsPerRatingExamReportHasMore', true)
        .set('nextRateToUse', '')
        .set('numberOfQuestionsPerRatingExamReportOverallUnique', 0)
        .set('numberOfQuestionsPerRatingExamReportApiError', false);

    case type.SET_NUMBER_OF_QUESTIONS_ENABLED_PER_RATING_EXAM_REPORT_ERROR:
      return state.set('numberOfQuestionsPerRatingExamReportApiError', true);

    case type.CLEAR_NUMBER_OF_QUESTIONS_ENABLED_PER_RATING_EXAM_API_ERROR:
      return state.set('numberOfQuestionsPerRatingExamReportApiError', false);

    case type.SET_ALL_MASTER_USER_REPORT: {
      const automaticmasterReportUrl = action.response.result;
      return state
        .set('csvDataAvailably', true)
        .set('automaticMasterReportData', automaticmasterReportUrl);
    }
    case type.SET_ALL_MASTER_USER_REPORT_LOADING: {
      return state.set('allMasterUserReportLoading', action.value);
    }
    case type.SET_ALL_ACTIVE_SUBSCRIPTIONS_REPORT:
      return state
        .set('csvDataSubsActive', true)
        .set('automaticSubscriptionsReport', action.response.result);
    case type.SET_ALL_ACTIVE_SUBSCRIPTIONS_REPORT_LOADING: {
      return state.set('allActiveSubscriptionsReportLoading', action.value);
    }
    case type.CLEAR_AUTOMATIC_MASTER_REPORT_PROPS:
      return state
        .set('automaticMasterReportData', [])
        .set('csvDataAvailably', false);
    case type.CLEAR_AUTOMATIC_ACTIVE_SUBSCRIPTIONS:
      return state
        .set('automaticSubscriptionsReport', [])
        .set('csvDataSubsActive', false);
    case type.SET_IS_USER_SUBMITTED_QUESTIONS_REPORT_FOR_RATES_LOADING:
      return state.set('isUserSubmittedQuestionsReportLoading', action.value);
    case type.SET_USER_SUBMITTED_QUESTIONS_REPORT_FOR_RATES:
      return state.set('userSubmittedQuestionsReportCSVJson', action.response);
    case type.SET_USER_SUBMITTED_QUESTIONS_REPORT_FOR_RATES_ERROR:
      return state.set('userSubmittedQuestionsReportError', action.error);

    /* Favorite questions report */
    case type.SET_LAST_FAVORITE_QUESTIONS_REPORTS:
      return state.set('lastFavoriteQuestionsReports', action.reports);
    case type.LOADING_FAVORITE_QUESTIONS_REPORT: {
      const existingReports = state.get('lastFavoriteQuestionsReports');
      // create new reports if they don't exist (this works only the first time for each rate)
      const updatedReports = action.ratesIds.reduce(
        (reports, rateId) => {
          const existingReport = reports.find(
            (report) => report.rate === rateId,
          );
          if (existingReport) {
            existingReport.isLoading = true;
          } else {
            reports.push({ rate: rateId, isLoading: true });
          }
          return reports;
        },
        [...existingReports],
      );

      return state.set('lastFavoriteQuestionsReports', updatedReports);
    }

    /* Rate questions report */
    case type.SET_LAST_RATE_QUESTIONS_REPORTS:
      return state.set('lastRateQuestionsReports', action.reports);
    case type.LOADING_RATE_QUESTIONS_REPORT: {
      const existingReports = state.get('lastRateQuestionsReports');
      // create new reports if they don't exist (this works only the first time for each rate)
      const updatedReports = action.ratesIds.reduce(
        (reports, rateId) => {
          const existingReport = reports.find(
            (report) => report.rate === rateId,
          );
          if (existingReport) {
            existingReport.isLoading = true;
          } else {
            reports.push({ rate: rateId, isLoading: true });
          }
          return reports;
        },
        [...existingReports],
      );

      return state.set('lastRateQuestionsReports', updatedReports);
    }
    /* Usage statistics report */
    case type.SET_USAGE_REPORTS:
      return state.set('usageStatisticsReports', action.reports);
    case type.UPDATE_USAGE_REPORTS:
      return state.set('usageStatisticsReports', [
        ...state.get('usageStatisticsReports'),
        action.report,
      ]);
    case type.SET_USAGE_REPORTS_IS_LOADING:
      return state.set('usageStatisticsReportsIsLoading', action.value);
    /* Books missing answers report */
    case type.GET_LATEST_BOOKS_MISSING_ANSWERS_REPORT:
    case type.GENERATE_BOOKS_MISSING_ANSWERS_REPORT:
      return state;
    case type.SET_BOOKS_MISSING_ANSWERS_REPORT_DATA:
      if (action.payload.state === BOOKS_MISSING_ANSWERS_REPORT_SUCCESS) {
        return state.set('booksMissingAnswersReportData', {
          state: action.payload.state,
          link: action.payload.link,
        });
      }
      return state;
    case type.SET_BOOKS_MISSING_ANSWERS_REPORT_STATE:
      return state.set('booksMissingAnswersReportState', action.payload.state);

    default:
      return state;
  }
};

export default reportReducer;
