import Notifications from 'react-notification-system-redux';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { getRequestErrorMessage } from '../../utilities/SagasHelper';
import {
  generateBooksMissingAnswersReport,
  generateFavoriteQuestionsReport as generateFavoriteQuestionsReportServiceCaller,
  generateRateQuestionsReportService,
  generateTopicsOrExamSubtopicsReport,
  generateUsageReportService,
  getAllActiveSubscriptionsReport,
  getAllMasterUserReport,
  getBibliographyReportForGivenRates as getBibliographyReportForGivenRatesServiceCaller,
  getCompletionStatus,
  getFavoriteQuestionsReports as getFavoriteQuestionsReportServiceCaller,
  getHistoricalUsersReport,
  getIntercomActiveUsersReportService,
  getLatestBooksMissingAnswersReport,
  getLatestTopicsOrExamSubtopicsReport,
  getNumberOfQuestionsEnabledPerRatingExamReport,
  getProfileSheetReport,
  getRateQuestionsReportsService,
  getUsageReportsService,
  getUsersCount,
  getUserSubmittedQuestionsReportService,
} from '../../utilities/ServiceManager';
import {
  callUsersCount,
  getBibliographyReportForGivenRatesError,
  getBibliographyReportForGivenRatesSuccess,
  getHistoricalUsersReportError,
  getUsersCountError,
  getUsersCountSuccess,
  profileSheetCsvReport,
  setAllActiveSubscriptionsReport,
  setAllActiveSubscriptionsReportLoading,
  setAllMasterUserReport,
  setAllMasterUserReportError,
  setAllMasterUserReportLoading,
  setBooksMissingAnswersReportData,
  setBooksMissingAnswersReportStateError,
  setBooksMissingAnswersReportStateLoading,
  setBooksMissingAnswersReportStateSuccess,
  setHistoricalUsersReport,
  setIntercomActiveSubscriptionsError,
  setIntercomActiveUsers,
  setIsLoadingFavoriteQuestionsReport,
  setIsLoadingRateQuestionsReport,
  setIsUserSubmittedQuestionsReportLoading,
  setLastFavoriteQuestionsReport,
  setLastRateQuestionsReport,
  setNumberOfQuestionsEnabledPerRatingExamReport,
  setNumberOfQuestionsEnabledPerRatingExamReportError,
  setTopicsOrExamSubtopicsReport,
  setUsageReports,
  setUsageReportsIsLoading,
  setUserSubmittedQuestionsReport,
  setUserSubmittedQuestionsReportError,
  updateUsageReports,
} from './actions';
import {
  GENERATE_BOOKS_MISSING_ANSWERS_REPORT,
  GENERATE_FAVORITE_QUESTIONS_REPORT_FOR_RATES,
  GENERATE_RATE_QUESTIONS_REPORT,
  GENERATE_TOPICS_OR_EXAM_SUBTOPICS_REPORT,
  GENERATE_USAGE_REPORT,
  GET_ALL_ACTIVE_SUBSCRIPTIONS_REPORT,
  GET_ALL_MASTER_USER_REPORT,
  GET_BIBLIOGRAPHY_REPORT_FOR_RATES,
  GET_COMPLETION_STATUS,
  GET_HISTORICAL_USERS_REPORT,
  GET_INTERCOM_ACTIVE_USERS_REPORT,
  GET_LAST_FAVORITE_QUESTIONS_REPORTS,
  GET_LAST_RATE_QUESTIONS_REPORTS,
  GET_LATEST_BOOKS_MISSING_ANSWERS_REPORT,
  GET_LATEST_TOPICS_OR_EXAM_SUBTOPICS_REPORT,
  GET_NUMBER_OF_QUESTIONS_ENABLED_PER_RATING_EXAM_REPORT,
  GET_PROFILE_SHEET_REPORT,
  GET_USAGE_REPORTS,
  GET_USER_SUBMITTED_QUESTIONS_REPORT_FOR_RATES,
  GET_USERS_COUNT,
} from './actionTypes';

function* getBibliographyReportForGivenRates(action) {
  try {
    const csvReport = yield call(
      getBibliographyReportForGivenRatesServiceCaller,
      action.rates,
      action.selectedRatingExams,
      action.multiple,
    );
    yield put(getBibliographyReportForGivenRatesSuccess(csvReport));
  } catch (exception) {
    yield put(getBibliographyReportForGivenRatesError(exception.message));
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not generate report. Please try again later.',
      ),
    );
  }
}

function* getLatestTopicsOrExamSubtopicsReportSaga(action) {
  try {
    const report = yield call(
      getLatestTopicsOrExamSubtopicsReport,
      action.payload.isExamReport,
    );
    yield put(setTopicsOrExamSubtopicsReport(report));
  } catch (error) {
    yield put(
      showNotificationErrorFromException(
        error,
        `Could not get the latest ${action.payload.isExamReport ? 'exam subtopics' : 'topics'} report. Please try again later.`,
      ),
    );
  }
}

function* generateTopicsOrExamSubtopicsReportSaga(action) {
  try {
    yield put(
      Notifications.show(
        {
          title: 'Work in progress',
          message: `${action.isExamReport ? 'Exam subtopics' : 'Topics'} report generation started in the background.`,
        },
        'success',
      ),
    );
    const report = yield call(
      generateTopicsOrExamSubtopicsReport,
      action.payload.rates,
      action.payload.multiple,
      action.payload.isExamReport,
    );
    yield put(setTopicsOrExamSubtopicsReport(report));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: `${action.isExamReport ? 'Exam subtopics' : 'Topics'} report generated successfully. You can download it now.`,
        },
        'success',
      ),
    );
  } catch (error) {
    yield put(
      showNotificationErrorFromException(
        error,
        'Could not generate report. Please try again later.',
      ),
    );
  }
}

function* getCompletionStatusSagas() {
  try {
    const completionStatusData = yield call(getCompletionStatus);
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not get completion status. Please try again later.',
      ),
    );
  }
}

function* fetchUsersCount(action) {
  try {
    yield put(callUsersCount());
    const usersCountResponse = yield call(getUsersCount, action.query);
    yield put(getUsersCountSuccess(usersCountResponse));
  } catch (exception) {
    yield put(getUsersCountError(exception.message));
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not get users count from server. Please try again later.',
      ),
    );
  }
}

function* getHistoricalUsersReportSagas(action) {
  try {
    const historicalUsersReportResponse = yield call(
      getHistoricalUsersReport,
      action.lastCustomer,
      action.startingDate,
      action.endingDate,
      action.lessCustomers,
    );
    yield put(setHistoricalUsersReport(historicalUsersReportResponse));
  } catch (error) {
    yield put(getHistoricalUsersReportError());
  }
}

function* getProfileSheetSagas(action) {
  try {
    const profileSheetReport = yield call(getProfileSheetReport, action.cycles);
    yield put(profileSheetCsvReport(profileSheetReport));
  } catch (exception) {
    yield put(
      Notifications.show(
        {
          title: 'Error',
          message:
            'There was an error getting the report data. Please try again or contact the support',
        },
        'error',
      ),
    );
  }
}

function* getNumberOfQuestionsEnabledPerRatingExamReportSagas(action) {
  try {
    const numberOfQuestionsPerRatingExamResponse = yield call(
      getNumberOfQuestionsEnabledPerRatingExamReport,
      action.lastRate,
    );
    yield put(
      setNumberOfQuestionsEnabledPerRatingExamReport(
        numberOfQuestionsPerRatingExamResponse,
      ),
    );
  } catch (error) {
    yield put(setNumberOfQuestionsEnabledPerRatingExamReportError());
    yield put(
      Notifications.show(
        {
          title: 'Error',
          message:
            'There was an error getting the report data. Please try again',
        },
        'error',
      ),
    );
  }
}

function* getAllMasterUserReportSagas() {
  try {
    yield put(setAllMasterUserReportLoading(true));
    const getAllMasterUserReportResponse = yield call(getAllMasterUserReport);
    yield put(setAllMasterUserReport(getAllMasterUserReportResponse));
  } catch (error) {
    yield put(setAllMasterUserReportError());
    yield put(
      Notifications.show(
        {
          title: 'Error',
          message:
            'There was an error trying download the Master User Report. Please try again or contact the support',
        },
        'error',
      ),
    );
  } finally {
    yield put(setAllMasterUserReportLoading(false));
  }
}

function* getAllActiveSubscriptionsReportSaga() {
  try {
    yield put(setAllActiveSubscriptionsReportLoading(true));
    const activeSubscriptionsReportResponse = yield call(
      getAllActiveSubscriptionsReport,
    );
    yield put(
      setAllActiveSubscriptionsReport(activeSubscriptionsReportResponse),
    );
  } catch (error) {
    yield put(
      Notifications.show(
        {
          title: 'Error',
          message:
            'There was an error trying download the Master User Report. Please try again or contact the support',
        },
        'error',
      ),
    );
  } finally {
    yield put(setAllActiveSubscriptionsReportLoading(false));
  }
}

function* getUserSubmittedQuestionsReportSagas(action) {
  try {
    yield put(setIsUserSubmittedQuestionsReportLoading(true));
    const response = yield call(
      getUserSubmittedQuestionsReportService,
      action.rates,
    );
    yield put(setUserSubmittedQuestionsReport(response));
  } catch (error) {
    yield put(setUserSubmittedQuestionsReportError(error.message));
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not generate user submitted questions report. Please try again later.',
      ),
    );
  } finally {
    yield put(setIsUserSubmittedQuestionsReportLoading(false));
  }
}

function* getIntercomActiveUsersReportSagas(action) {
  try {
    const response = yield call(
      getIntercomActiveUsersReportService,
      action.lastCustomerSubscription,
    );
    yield put(
      setIntercomActiveUsers(
        response.csvData,
        response.hasMore,
        response.lastCustomerSubscription,
      ),
    );
  } catch (err) {
    yield put(setIntercomActiveSubscriptionsError());
  }
}

/* Favorite Questions Report */
function* generateFavoriteQuestionsReport(action) {
  try {
    const response = yield call(
      generateFavoriteQuestionsReportServiceCaller,
      action.ratesIds,
      action.selectedRatingExams,
    );
    // set all rates selected how loading
    yield put(setIsLoadingFavoriteQuestionsReport(action.ratesIds));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: response.message,
          autoDismiss: 5,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not generate favorite questions report. Please try again later.',
      ),
    );
  }
}
function* getFavoriteQuestionsReports() {
  try {
    const response = yield call(getFavoriteQuestionsReportServiceCaller);
    yield put(setLastFavoriteQuestionsReport(response));
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not get favorite questions reports. Please try again later.',
      ),
    );
  }
}

/* Rate Questions Report */
function* generateRateQuestionsReport(action) {
  try {
    const response = yield call(
      generateRateQuestionsReportService,
      action.ratesIds,
    );
    // set all rates selected how loading
    yield put(setIsLoadingRateQuestionsReport(action.ratesIds));

    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: response.message,
          autoDismiss: 5,
        },
        'success',
      ),
    );
  } catch (err) {
    yield put(
      showNotificationErrorFromException(
        err,
        'Error generating rate questoins report',
      ),
    );
  }
}
function* getRateQuestionsReports() {
  try {
    const response = yield call(getRateQuestionsReportsService);
    yield put(setLastRateQuestionsReport(response));
  } catch (err) {
    yield put(showNotificationErrorFromException(err, 'Error'));
  }
}

/* Usage Report */
function* generateUsageReportSaga(action) {
  try {
    const response = yield call(
      generateUsageReportService,
      action.fromDate,
      action.untilDate,
      action.fromDateComparison,
      action.untilDateComparison,
    );

    yield put(updateUsageReports(response));

    yield put(
      Notifications.show(
        {
          title: 'Work in progress',
          message: `Usage report generation started in the background.`,
        },
        'success',
      ),
    );
  } catch (err) {
    yield put(showNotificationErrorFromException(err, 'Error'));
  }
}
function* getUsageReports() {
  try {
    yield put(setUsageReportsIsLoading(true));
    const response = yield call(getUsageReportsService);
    yield put(setUsageReports(response));
  } catch (err) {
    yield put(showNotificationErrorFromException(err, 'Error'));
  } finally {
    yield put(setUsageReportsIsLoading(false));
  }
}

/* Books missing answers report */
function* handleBooksMissingAnswersReportSaga(action) {
  let response = {};
  try {
    yield put(setBooksMissingAnswersReportStateLoading());
    if (action.type === GENERATE_BOOKS_MISSING_ANSWERS_REPORT) {
      response = yield call(generateBooksMissingAnswersReport);
    } else if (action.type === GET_LATEST_BOOKS_MISSING_ANSWERS_REPORT) {
      response = yield call(getLatestBooksMissingAnswersReport);
    }
    yield put(setBooksMissingAnswersReportData(response));
    yield put(setBooksMissingAnswersReportStateSuccess());
  } catch (error) {
    yield put(setBooksMissingAnswersReportStateError());
  }
}

export default function* sagas() {
  all([
    yield takeLatest(
      GET_BIBLIOGRAPHY_REPORT_FOR_RATES,
      getBibliographyReportForGivenRates,
    ),
    yield takeLatest(
      GENERATE_FAVORITE_QUESTIONS_REPORT_FOR_RATES,
      generateFavoriteQuestionsReport,
    ),
    yield takeLatest(
      GET_LAST_FAVORITE_QUESTIONS_REPORTS,
      getFavoriteQuestionsReports,
    ),
    yield takeLatest(GET_USERS_COUNT, fetchUsersCount),
    yield takeLatest(
      GET_HISTORICAL_USERS_REPORT,
      getHistoricalUsersReportSagas,
    ),
    yield takeLatest(GET_PROFILE_SHEET_REPORT, getProfileSheetSagas),
    yield takeLatest(GET_ALL_MASTER_USER_REPORT, getAllMasterUserReportSagas),
    yield takeLatest(
      GET_ALL_ACTIVE_SUBSCRIPTIONS_REPORT,
      getAllActiveSubscriptionsReportSaga,
    ),

    yield takeLatest(GET_COMPLETION_STATUS, getCompletionStatusSagas),
    yield takeLatest(
      GET_NUMBER_OF_QUESTIONS_ENABLED_PER_RATING_EXAM_REPORT,
      getNumberOfQuestionsEnabledPerRatingExamReportSagas,
    ),
    yield takeLatest(
      GET_USER_SUBMITTED_QUESTIONS_REPORT_FOR_RATES,
      getUserSubmittedQuestionsReportSagas,
    ),
    yield takeLatest(
      GET_INTERCOM_ACTIVE_USERS_REPORT,
      getIntercomActiveUsersReportSagas,
    ),
    yield takeLatest(
      GET_LATEST_TOPICS_OR_EXAM_SUBTOPICS_REPORT,
      getLatestTopicsOrExamSubtopicsReportSaga,
    ),
    yield takeLatest(
      GENERATE_TOPICS_OR_EXAM_SUBTOPICS_REPORT,
      generateTopicsOrExamSubtopicsReportSaga,
    ),
    yield takeLatest(
      GENERATE_RATE_QUESTIONS_REPORT,
      generateRateQuestionsReport,
    ),
    yield takeLatest(GET_LAST_RATE_QUESTIONS_REPORTS, getRateQuestionsReports),
    yield takeLatest(GENERATE_USAGE_REPORT, generateUsageReportSaga),
    yield takeLatest(GET_USAGE_REPORTS, getUsageReports),
    yield takeLatest(
      [
        GENERATE_BOOKS_MISSING_ANSWERS_REPORT,
        GET_LATEST_BOOKS_MISSING_ANSWERS_REPORT,
      ],
      handleBooksMissingAnswersReportSaga,
    ),
  ]);
}

function showNotificationErrorFromException(exception, optionalMessage) {
  const message = optionalMessage || getRequestErrorMessage(exception);
  return Notifications.show({ title: 'Ops!', message }, 'error');
}
