import { NAME } from './constants';

export const SEARCHUSERSRECEIVED = `${NAME}/SEARCHUSERSRECEIVED`;
export const ERROR_FETCHING_FROM_SERVICE_USERS = `${NAME}/ERROR_FETCHING_FROM_SERVICE_USERS`;
export const REMOVE_USER = `${NAME}/REMOVE_USER`;
export const UPDATE_USER = `${NAME}/UPDATE_USER`;
export const UPDATEISWAITING = `${NAME}/UPDATE_ISW_AITING`;
export const UPDATEFORMERRORS = `${NAME}/UPDATE_FORM_ERRORS`;
export const CLEAR_ERROR_FROM_SERVICE = `${NAME}/CLEAR_ERROR_FROM_SERVICE`;
export const SET_NEW_STUDENT_ERROR = `${NAME}/SET_NEW_USER_ERROR`;

// generic actionTypes
export const ERROR_FETCHING_FROM_SERVICE = `${NAME}/ERROR_FETCHING_FROM_SERVICE`;
export const SET_LOADING = `${NAME}/SET_LOADING`;
export const SET_PLANS = `${NAME}/SET_PLANS`;
export const SET_SELECTED_USER = `${NAME}/SET_SELECTED_USER`;
export const SET_STATISTICS = `${NAME}/SET_STATISTICS`;
export const SET_LIST_CYCLES = `${NAME}/SET_LIST_CYCLES`;
export const SET_RESPONSE = `${NAME}/SET_RESPONSE`;
export const SET_SUBSCTIPTION_TYPE = `${NAME}/SET_SUBSCTIPTION_TYPE`;
export const SET_IS_NEW_STUDENT = `${NAME}/SET_IS_NEW_STUDENT`;
export const SET_MESSAGE = `${NAME}/SET_MESSAGE`;
export const SET_LOADING_INFO = `${NAME}/SET_LOADING_INFO`;
export const SET_PRACTICE_EXAMS_WAITING = `${NAME}/SET_PRACTICE_EXAMS_WAITING`;
export const SET_PRACTICE_EXAMS = `${NAME}/SET_PRACTICE_EXAMS`;
export const SET_RESTART_PRACTICE_EXAM_WAITING = `${NAME}/SET_RESTART_PRACTICE_EXAM_WAITING`;
export const SET_RESTART_PRACTICE_EXAM_SUCCESS = `${NAME}/SET_RESTART_PRACTICE_EXAM_SUCCESS`;
export const GET_NOTE_DO_NOT_CONTACT = `${NAME}/GET_NOTE_DO_NOT_CONTACT`;
export const GET_COUPON_USER = `${NAME}/GET_COUPON_USER`;
export const SET_NOTE_DO_NOT_CONTACT = `${NAME}/SET_NOTE_DO_NOT_CONTACT`;
export const GET_DO_NOT_CONTACT = `${NAME}/GET_DO_NOT_CONTACT`;
export const DISABLE_CYCLE_INFORMATION = `${NAME}/DISABLE_CYCLE_INFORMATION`;
export const SET_DISABLE_CYCLES = `${NAME}/SET_DISABLE_CYCLES`;
export const GET_INTERCOM_CANCELLATION = `${NAME}/GET_INTERCOM_CANCELLATION`;
export const SET_INTERCOM_CANCELLATION = `${NAME}/SET_INTERCOM_CANCELLATION`;
export const GET_INTERCOM_CANCELLATION_REASON = `${NAME}/GET_INTERCOM_CANCELLATION_REASON`;
export const SET_USER_VERIFICATION = `${NAME}/SET_USER_VERIFICATION`;
export const GET_NOTE_CANCELLATION_REASON = `${NAME}/GET_NOTE_CANCELLATION_REASON`;
export const SET_CANCELLATION_REASON = `${NAME}/SET_CANCELLATION_REASON`;
export const UPDATE_CANCELLATION_REASON = `${NAME}/UPDATE_CANCELLATION_REASON`;
export const LOAD_APPLICABLE_COUPONS = `${NAME}/LOAD_APPLICABLE_COUPONS`;
export const SET_APPLICABLE_COUPONS = `${NAME}/SET_APPLICABLE_COUPONS`;
export const UPDATE_EDUCATIONAL_INSTITUTION_DATA = `${NAME}/UPDATE_EDUCATIONAL_INSTITUTION_DATA`;
export const SET_EDUCATIONAL_INSTITUTION_DATA = `${NAME}/SET_EDUCATIONAL_INSTITUTION_DATA`;
export const GET_EDUCATIONAL_INSTITUTION_DATA = `${NAME}/GET_EDUCATIONAL_INSTITUTION_DATA`;
export const SET_COUPON_USER = `${NAME}/SET_COUPON_USER`;
export const SET_USER_SUBMISSION_BLOCK_STATUS =
  'SET_USER_SUBMISSION_BLOCK_STATUS';
export const SET_CHANGE_USER_SUBMISSION_BLOCK_STATUS =
  'SET_CHANGE_USER_SUBMISSION_BLOCK_STATUS';
export const SET_IS_LOADING_BLOCK_STATUS = 'SET_IS_LOADING_BLOCK_STATUS';
export const SET_USER_FAVORITE_BLOCK_STATUS = 'SET_USER_FAVORITE_BLOCK_STATUS';
export const SET_IS_LOADING_FAVORITE_BLOCK_STATUS =
  'SET_IS_LOADING_FAVORITE_BLOCK_STATUS';
export const SET_CHANGE_USER_FAVORITE_BLOCK_STATUS =
  'SET_CHANGE_USER_FAVORITE_BLOCK_STATUS';
export const SET_CHECK_BETA_USER = 'SET_CHECK_BETA_USER';
export const GET_BETA_USER = 'GET_BETA_USER';
export const SET_IS_BETA_USER = 'SET_IS_BETA_USER';
export const SET_SHOW_CHANGE_EXAM_TYPE_MODAL =
  'SET_SHOW_CHANGE_EXAM_TYPE_MODAL';
export const GET_USER_SUBSCRIPTION_HISTORY = 'GET_USER_SUBSCRIPTION_HISTORY';
export const SET_HISTORY_SUBSCRIPTION_USER = 'SET_HISTORY_SUBSCRIPTION_USER';
export const SET_LOADING_SUBSCRIPTION_HISTORY =
  'SET_LOADING_SUBSCRIPTION_HISTORY';
