import { Map } from 'immutable';

import * as t from './actionTypes';

const initialState = Map({
  rates: [],
  exams: [],
  waiting: false,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case t.SET_RATES:
      return state.set('rates', action.payload);
    case t.SET_EXAMS:
      return state.set('exams', action.payload);
    case t.SET_WAITING:
      return state.set('waiting', action.value);
    default:
      return state;
  }
}
