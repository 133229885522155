import { List, Map } from 'immutable';
import _ from 'underscore';

import * as t from './actionTypes';

const initialState = Map({
  admins: [],
  pagination: { total: 1, page: 1 },
  roles: List([]),
  isWaiting: false,
  profile: null,
  history: [],
  historyPagination: { total: 0, page: 1 },
  adminRoles: [],
  adminUserLogs: [],
  adminUserLogsPagination: { total: 0, page: 1 },
  activity: {},
});

function changeStateUser(user, state) {
  const adminsConst = state.get('admins').map((admin) => {
    if (admin.id === user.id) {
      return user;
    }
    return admin;
  });

  return state.set('admins', adminsConst).set('profile', user);
}

export default function (state = initialState, action) {
  switch (action.type) {
    case t.SEARCHADMINSRECEIVED: {
      const { pagination, data, userId } = action.payload;
      const oldAdmins = state.get('admins');
      const newAdmin = pagination.page === 1 ? data : _.union(oldAdmins, data);
      return state
        .set('admins', newAdmin)
        .set('pagination', pagination)
        .set('userId', userId);
    }
    case t.ROLESRECEIVED:
      return state.set(
        'roles',
        List(action.payload.data.filter((role) => !role.showGroup)),
      );

    case t.TOGGLE_ADMIN_ROLE:
      const adminUpdated = state
        .get('admins')
        .map((admin) => (admin.id === action.admin.id ? action.admin : admin));
      return state.set('admins', adminUpdated).set('profile', action.admin);

    case t.UPDATE_IS_WAITING:
      return state.set('isWaiting', action.isWaiting);

    case t.UPDATEPROFILEACTION:
      return state.set('profile', action.payload);

    case t.SET_SELECTED_ADMIN_ROLES:
      return state.set('adminRoles', action.roles);

    case t.ADD_NEW_ADMIN:
      return state.set('admins', _.union([action.admin], state.get('admins')));

    case t.DELETE_USER_SUCCESS:
      const adminDeleted = state
        .get('admins')
        .filter((admin) => admin.id !== action.response.id);

      return state.set('admins', adminDeleted).set('profile', adminDeleted[0]);

    case t.DISABLE_USER_SUCCESS:
      return changeStateUser(action.response, state);

    case t.RESET_PASSWORD_SUCCESS:
      return changeStateUser(action.response.data, state);

    case t.REMOVE_ADMIN:
      return state.set(
        'admins',
        state.get('admins').filter((admin) => admin.id !== action.payload.id),
      );

    case t.SET_SELECTED_ADMIN:
      return state.set('profile', action.admin);

    case t.SET_ADMIN_HISTORY:
      return state
        .set('history', action.history)
        .set('historyPagination', action.pagination);

    case t.SET_ACTIVITY:
      return state.set('activity', action.activity);

    case t.ERROR_SUBMITTING_FROM_SERVICE:
      return state.set('errorFromService', action.error);

    case t.UPDATE_ADMIN_LOGS:
      return state
        .set('adminUserLogs', action.logs)
        .set('adminUserLogsPagination', action.pagination);

    case t.CLEAR_ERROR_FROM_SERVICE:
      return state.set('errorFromService', null);

    case t.UPDATE_ADMIN:
      return state
        .set(
          'admins',
          state
            .get('admins')
            .map((admin) =>
              admin.id === action.payload.id ? action.payload : admin,
            ),
        )
        .set('profile', action.payload);

    case t.SET_LOADING_INFO:
      return state.set('loadingInfo', action.loadingInfo);

    case t.SET_MESSAGE:
      return state.set('message', action.message);

    default:
      return state;
  }
}
