import Notifications from 'react-notification-system-redux';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { getRequestErrorMessage } from '../../utilities/SagasHelper';
import * as api from '../../utilities/ServiceManager';
import { setExams, setRates, setWaiting } from './actions';
import { LOAD_EXAMS, LOAD_RATES } from './actionTypesSagas';

/**
 * LOAD Rates
 */
function* loadRatesSagas() {
  yield takeLatest(LOAD_RATES, loadRates);
}

function* loadExamsSagas() {
  yield takeLatest(LOAD_EXAMS, loadExams);
}

function* loadRates(action) {
  try {
    yield put(setWaiting(true));
    const rates = yield call(api.getRatesForStudents);
    yield put(setWaiting(false));
    rates.sort((a, b) => {
      if (a.shortname < b.shortname) return -1;
      if (a.shortname > b.shortname) return 1;
      return 0;
    });
    yield put(setRates(rates));
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not load rates. Please try again later.',
      ),
    );
  } finally {
  }
}

function* loadExams(action) {
  try {
    yield put(setWaiting(true));
    const exams = yield call(api.getExamsForStudents);
    yield put(setWaiting(false));
    yield put(setExams(exams));
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not load exams. Please try again later.',
      ),
    );
  } finally {
  }
}

/**
 * DEFAUL EXPORT
 */
export default function* sagas() {
  yield all([loadRatesSagas(), loadExamsSagas()]);
}

function showNotificationErrorFromException(exception) {
  const message = getRequestErrorMessage(exception);

  return Notifications.show({ title: 'Ops!', message }, 'error');
}
