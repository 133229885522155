import './QuestionListView.css';

import PropTypes from 'prop-types';
import React from 'react';

import QuestionDetailsContainer from '../../commonComponents/QuestionDetails/QuestionDetailsContainer';
import QuestionInfiniteListContainer from '../../commonComponents/QuestionInfiniteList/QuestionInfiniteListContainer';

export default class QuestionListView extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="ContentRow">
        <QuestionInfiniteListContainer
          loadAllQuestions={this.props.loadAllQuestions}
        />
        <QuestionDetailsContainer />
      </div>
    );
  }
}

QuestionListView.propTypes = {
  selectedQuestion: PropTypes.object,
};
