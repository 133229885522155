import PropTypes from 'prop-types';
import React from 'react';

import addAbilityToOpenModal from '../../utilities/modalHOC';
import NewAdmin from '../Modals/NewAdmin/NewAdmin';
import UsersListStateless from './UsersListStateless';

class UsersListStateful extends React.Component {
  static propTypes = {
    users: PropTypes.array,
    getUsers: PropTypes.func,
    updateProfile: PropTypes.func,
    reloadUsers: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    pagination: PropTypes.object,
    profile: PropTypes.object,
  };

  static defaultProps = {
    pagination: { total: 0 },
  };

  constructor(props) {
    super(props);
    this.timeout = null;
  }

  render() {
    return (
      <UsersListStateless
        users={this.props.users}
        forAdmin={this.props.forAdmin}
        profile={this.props.profile}
        rates={this.props.rates}
        updateProfile={this.props.updateProfile}
        search={this.props.search}
        filterBySubscription={this.props.filterBySubscription}
        filterByRate={this.props.filterByRate}
        setSearch={this.props.setSearch}
        loading={this.props.loading}
        page={this.props.page}
        totalPagination={this.props.pagination.total}
        changePage={this.props.changePage}
        toggleNew={this.props.toggleNew}
        newStudent={this.props.newStudent}
        setSelectedUser={this.props.setSelectedUser}
        loadUserStatistics={this.props.loadUserStatistics}
        generalPermissions={this.props.generalPermissions}
        loggedUser={this.props.loggedUser}
      />
    );
  }
}

export default addAbilityToOpenModal(UsersListStateful);
