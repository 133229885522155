import { connect } from 'react-redux';

import { loadExams } from '../../../ChangeExam/actionsSagas';
import {
  getExams,
  getWaiting as getWaitingExams,
} from '../../../ChangeExam/selectors';
import { loadRates } from '../../../Rates/actionsSagas';
import { getRates, getWaiting } from '../../../Rates/selectors';
import {
  generateTopicsOrExamSubtopicsReport,
  getLatestTopicsOrExamSubtopicsReport,
  setCompletionStatus,
} from '../../actions';
import { getCompletionStatusSagas } from '../../actionsSagas';
import {
  getCompletionStatus,
  getTopicsOrExamSubtopicsReport,
} from '../../selectors';
import TopicsAndExamSubtopicsReport from './TopicsAndExamSubtopicsReport';

const mapStateToProps = (state) => ({
  ratesIsLoading: getWaiting(state),
  examsIsLoading: getWaitingExams(state),
  rates: getRates(state),
  exams: getExams(state),
  report: getTopicsOrExamSubtopicsReport(state),
  completionStatus: getCompletionStatus(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadRates: () => {
    dispatch(loadRates());
  },
  loadExams: () => {
    dispatch(loadExams());
  },
  generateReportForSelectedRates: (
    rates,
    ratingExams,
    multiple,
    isExamReport,
  ) => {
    dispatch(
      generateTopicsOrExamSubtopicsReport(
        rates,
        ratingExams,
        multiple,
        isExamReport,
      ),
    );
  },
  setCompletionStatus: (data) => {
    dispatch(setCompletionStatus(data));
  },
  getCompletionStatusSagas: () => {
    dispatch(getCompletionStatusSagas());
  },
  getLatest: (isExamReport) => {
    dispatch(getLatestTopicsOrExamSubtopicsReport(isExamReport));
  },
});

const TopicsAndExamSubtopicsReportContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TopicsAndExamSubtopicsReport);

export default TopicsAndExamSubtopicsReportContainer;
