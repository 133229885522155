import React from 'react';

import { HistoricalReportTable } from '../HistoricalReportTable';

export default class ActiveSubscriptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reports: [],
    };
  }

  componentDidUpdate() {
    if (this.props.csvDataSubsActive) {
      this.setState({ reports: this.props.automaticSubscriptionsReport });
      this.props.clearCsv();
    }
  }

  componentDidMount() {
    this.props.getAllActiveSubscriptionsReport();
  }

  render() {
    return (
      <div className="p-3">
        <hr />
        <h2>Active Subscriptions Report</h2>
        <HistoricalReportTable
          reports={this.state.reports}
          loading={this.props.allActiveSubscriptionsReportLoading}
          columns={['id', 'createdAtParsed', 'link']}
        />
      </div>
    );
  }
}
