// insights/actionTypes.js

export const SEARCHADMINSRECEIVED = 'admins/SEARCHADMINSRECEIVED';
export const ROLESRECEIVED = 'admins/ROLESRECEIVED';
export const CHANGEMAINROLERECEIVED = 'admins/CHANGEMAINROLERECEIVED';
export const UPDATEPROFILEACTION = 'admins/UPDATEPROFILEACTION';
export const REMOVE_ADMIN = 'admins/REMOVE_ADMIN';
export const UPDATE_ADMIN = 'admins/UPDATE_ADMIN';
export const UPDATE_IS_WAITING = 'admins/UPDATE_IS_WAITING';
export const REQUEST_PASSWORD = 'admins/REQUEST_PASSWORD';

export const ERROR_FETCHING_FROM_SERVICE_ADMINS =
  'admins/ERROR_FETCHING_FROM_SERVICE_ADMINS';

// generic actionTypes
export const ERROR_FETCHING_FROM_SERVICE = 'admins/ERROR_FETCHING_FROM_SERVICE';
export const DELETE_USER_SUCCESS = 'admins/DELETEUSERSUCCESS';
export const DISABLE_USER_SUCCESS = 'admins/DISABLEUSERSUCCESS';
export const RESET_PASSWORD_SUCCESS = 'admins/RESETPASSWORDSUCCESS';
export const ERROR_SUBMITTING_FROM_SERVICE =
  'admins/ERRORSUBMITTINGFROMSERVICE';
export const CLEAR_ERROR_FROM_SERVICE = 'admins/CLEARERRORFROMSERVICE';
export const TOGGLE_ADMIN_ROLE = 'admins/TOGGLE_ADMIN_ROLE';
export const SET_SELECTED_ADMIN = 'admins/SET_SELECTED_ADMIN';
export const SET_RESPONSE = 'admins/SET_RESPONSE';
export const SET_MESSAGE = 'admins/SET_MESSAGE';

export const SET_ADMIN_HISTORY = 'admins/SET_ADMIN_HISTORY';
export const SET_SELECTED_ADMIN_ROLES = 'admins/SET_SELECTED_ADMIN_ROLES';
export const ADD_NEW_ADMIN = 'admins/ADD_NEW_ADMIN';
export const UPDATE_ADMIN_LOGS = 'admins/UPDATE_ADMIN_LOGS';
export const SET_LOADING_INFO = `admins/SET_LOADING_INFO`;
export const SET_ACTIVITY = `admins/SET_ACTIVITY`;
