import { connect } from 'react-redux';

import {
  clearAutomaticActiveSubscriptions,
  getAllActiveSubscriptionsReport,
} from '../../actions';
import {
  getAllActiveSubscriptionsReportLoading,
  getAutomaticSubscriptionsReport,
  getCsvDataSubsActive,
} from '../../selectors';
import ActiveSubscriptions from './getActiveSubscriptions';

const mapStateToProps = (state) => ({
  automaticSubscriptionsReport: getAutomaticSubscriptionsReport(state),
  csvDataSubsActive: getCsvDataSubsActive(state),
  allActiveSubscriptionsReportLoading:
    getAllActiveSubscriptionsReportLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  getAllActiveSubscriptionsReport: () => {
    dispatch(getAllActiveSubscriptionsReport());
  },
  clearCsv: () => {
    dispatch(clearAutomaticActiveSubscriptions());
  },
});

const ActiveSubscriptionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ActiveSubscriptions);

export default ActiveSubscriptionsContainer;
